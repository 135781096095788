<template>
    <button 
        :type="type" 
        :disabled="disable" 
        :ref="refButton"
        :class="disable===true ? 'cursor-not-allowed' : 'cursor-pointer'" 
        class="inline-flex items-center px-4 py-2 border sm:rounded-md font-semibold text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'button',
            },
            disabled: {
                type: Boolean,
                default: false
            },
            id: {
                type: String,
                default: ''
            },
            refButton: {
                type: String,
                default: 'btn'
            },
            style: {
                type: String,
                default: 'blue'
            },
            isFull: {
                type: Boolean,
                default: false
            },
            mode: {
                type: String,
                default: 'color'
            }
        },
        data() {
            return {
                disable: false
            }
        },
        methods: {
            disabledBtn() {
                this.disable = true
            },
            enabledBtn() {
                this.disable = false
            },
            styling() {
                let classes = ''
                if (this.mode == 'color') {
                    classes = [`bg-${this.style}-500`, `border-${this.style}-500`, `hover:bg-${this.style}-600`, `active:bg-${this.style}-600`, `focus:border-${this.style}-600`, `text-white`]
                } else if ('light') {
                    classes = [`bg-${this.style}-200`, `border-${this.style}-200`, `hover:bg-${this.style}-300`, `active:bg-${this.style}-300`, `focus:border-${this.style}-300`, `text-gray-800`]
                }
                
                classes.forEach(e => {
                    this.$refs[this.refButton].classList.add(e)
                })
                if (this.isFull) this.$refs[this.refButton].classList.add('w-full')
            }
        },
        mounted() {
            this.styling()
        },
        updated() {
            this.styling()
        },
        watch: {
            $props: {
                deep: true,
                immediate: true,
                handler(data) {
                    this.disable = data.disabled
                }
            }
        }
    }
</script>