<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.manifests.open.index')">
                        Manifiestos abiertos
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.manifests.open.show', manifest.id)">
                        {{manifest.folio}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Editando
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <form @submit.prevent="submit" enctype="multipart/form-data">

                <div v-show="loading" class="shadow sm:rounded-md bg-white">
                    <div class="h-100 flex justify-content-center items-center">
                        <Loader width="100" height="100"></Loader>
                    </div>
                </div>

                <div v-show="!loading" class="shadow sm:rounded-md bg-white">

                    <div class="px-6 pt-6 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="subsidiary"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Sede</label>
                                <v-select
                                    :disabled="form.processing"
                                    :selectOnTab="true"
                                    :tabindex="1"
                                    placeholder="Seleccionar una sucursal"
                                    label="name"
                                    v-model="form.subsidiary"
                                    :reduce="(option) => option.id"
                                    :options="subsidiaries">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div 
                                    v-if="form.errors.subsidiary" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.subsidiary }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 py-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

                            <div class="col-span-1">
                                <label
                                    for="folio"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Folio</label>
                                <input 
                                    id="folio"
                                    type="text"
                                    :tabindex="2"
                                    :disabled="form.processing"
                                    v-model="form.folio"
                                    @keyup="existence"
                                    @paste="existence"
                                    @cut="existence"
                                    autocomplete="givenfolioparaquenoseautocompletexd"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div 
                                    v-if="form.errors.folio" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.folio }}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="typeWaste"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Tipo de residuos</label>
                                <v-select
                                    :disabled="form.processing"
                                    :selectOnTab="true"
                                    :tabindex="3"
                                    placeholder="Seleccionar un tipo de residuo"
                                    label="name"
                                    @option:selected="typeWasteSelected"
                                    @option:deselected="typeWasteSelected"
                                    v-model="form.typeWaste"
                                    :reduce="(option) => option.name"
                                    itemid="id"
                                    :options="typeWastes">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div 
                                    v-if="form.errors.typeWaste" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.typeWaste }}</div>
                            </div>

                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa generadora</label>
                                <v-select
                                    :disabled="form.processing || !form.typeWaste"
                                    :selectOnTab="true"
                                    :tabindex="4"
                                    placeholder="Seleccionar una empresa generadora"
                                    label="name"
                                    @search="onSearchGenerator"
                                    @option:selected="generatorSelected"
                                    v-model="form.generator"
                                    :reduce="(option) => option.id"
                                    :options="generators">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.generator" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.generator }}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="carrier"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa transportista</label>
                                <v-select
                                    :disabled="form.processing || !form.typeWaste"
                                    :selectOnTab="true"
                                    :tabindex="5"
                                    placeholder="Seleccionar una empresa transportista"
                                    label="name"
                                    @search="onSearchCarrier"
                                    v-model="form.carrier"
                                    :reduce="(option) => option.id"
                                    :options="carriers">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                                <div 
                                    v-if="form.errors.carrier" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.carrier }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="recipient"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa destinataria</label>
                                <v-select
                                    :disabled="form.processing || !form.typeWaste"
                                    :selectOnTab="true"
                                    :tabindex="6"
                                    placeholder="Seleccionar una empresa destinataria"
                                    label="name"
                                    @search="onSearchRecipient"
                                    v-model="form.recipient"
                                    :reduce="(option) => option.id"
                                    :options="recipients">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.recipient" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.recipient }}</div>
                            </div>
                            
                            <div class="col-span-1">
                                <label
                                    for="admissionAt"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Fecha de ingreso</label>
                                <input 
                                    id="admissionAt"
                                    type="date"
                                    :tabindex="7"
                                    :disabled="form.processing"
                                    v-model="form.admissionAt"
                                    autocomplete="givenadmissionAt"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div 
                                    v-if="form.errors.admissionAt" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.admissionAt }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 xl:col-span-6">
                                <div v-if="manifest.file" class="mb-2">
                                    <div class="mb-1 block text-sm font-semibold text-gray-700">Manifiesto adjuntado</div>
                                    
                                    <a target="_blank" :href="`${filesUrl}${manifest.file}`"  class="flex items-center px-3 p-1.5 shadow-sm sm:rounded-md bg-white border border-gray-300 text-orange-400 hover:text-orange-600 transition duration-500 ease">
                                        {{currentFileName}}
                                    </a>
                                </div>

                                <label
                                    for="file"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Adjuntar manifiesto</label>
                                    
                                <date :open="showFileModal" @click="openFileModal" @closeFileModal="closeFileModal" @returnFile="getFile"></date>

                                <div
                                    v-if="form.errors.file" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.file }}</div>
                            </div>
                            
                            <div class="col-span-12 sm:col-span-4 xl:col-span-2">
                                <label 
                                    for="statusManifest"
                                    class="block text-sm font-semibold text-gray-700 mb-1">¿Manifiesto válido?</label>
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input 
                                            id="statusManifest"
                                            type="checkbox"
                                            :value="form.statusManifest"
                                            :disabled="form.processing"
                                            v-model="form.statusManifest"
                                            @click="statusManifestChange"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="statusManifest" class="font-medium text-gray-700" v-text="statusManifestLabel"></label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-span-12 sm:col-span-4 xl:col-span-2">
                                <label 
                                    for="isConsolidated"
                                    class="block text-sm font-semibold text-gray-700 mb-1">¿Es un consolidado?</label>
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input 
                                            id="isConsolidated"
                                            type="checkbox"
                                            :disabled="form.processing || disabled"
                                            :value="form.isConsolidated"
                                            v-model="form.isConsolidated"
                                            @click="isConsolidatedChange"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                            :class="form.processing || disabled ? 'cursor-not-allowed' : 'cursor-default'" />
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="isConsolidated" class="font-medium text-gray-700" v-text="isConsolidatedLabel"></label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-span-12 sm:col-span-4 xl:col-span-2">
                                <label 
                                    for="isVoid"
                                    class="block text-sm font-semibold text-gray-700 mb-1">¿Genero?</label>
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input 
                                            id="isVoid"
                                            type="checkbox"
                                            :disabled="form.processing"
                                            @click="isVoidChange"
                                            :checked="form.isVoid"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="isVoid" class="font-medium text-gray-700" v-text="isVoidLabel"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                </div>

                <div v-show="loading" class="shadow sm:rounded-md bg-white mt-4">
                    <div class="h-56 flex justify-content-center items-center">
                        <Loader width="100" height="100"></Loader>
                    </div>
                </div>

                <div v-show="!loading" class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 pt-6 pb-3">
                        <h4 class="font-semibold mb-2">Residuo #1</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteOne"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.typeWaste || disabled"
                                    :selectOnTab="true"
                                    :tabindex="8"
                                    placeholder="Seleccionar un nombre de residuo"
                                    label="name"
                                    @search="onSearchWasteOne"
                                    @option:selected="wasteSelected(1)"
                                    v-model="form.wasteOneId"
                                    :reduce="(option) => option.id"
                                    :options="wastesOne">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 sm:grid-cols-4 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="quantityOne"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Cantidad</label>
                                <input 
                                    id="quantityOne"
                                    type="number"
                                    :tabindex="9"
                                    :disabled="form.processing || !form.wasteOneId || disabled"
                                    v-model="form.wasteOneQuantity"
                                    autocomplete="givenquantityOne"
                                    class="appearance-none mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing || !form.wasteOneId || disabled ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.wasteOneQuantity"
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteOneQuantity}}</div>
                            </div>
                            
                            <div class="col-span-1">
                                <label
                                    for="containerOne"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Contenedor</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.wasteOneId || disabled"
                                    :selectOnTab="true"
                                    :tabindex="10"
                                    placeholder="Seleccione un contenedor"
                                    label="name"
                                    v-model="form.wasteOneContainerId"
                                    :reduce="(option) => option.id"
                                    :options="containers">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div 
                                    v-if="form.errors.wasteOneContainerId"
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteOneContainerId}}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="totalQuantityOne"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Cantidad total</label>
                                <input 
                                    id="totalQuantityOne"
                                    type="text"
                                    :tabindex="11"
                                    :disabled="form.processing || !form.wasteOneId || disabled"
                                    v-model="form.wasteOneTotalQuantity"
                                    autocomplete="giventotalQuantityOne"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing || !form.wasteOneId || disabled ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div 
                                    v-if="form.errors.wasteOneTotalQuantity" 
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteOneTotalQuantity}}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="measurementOne"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Medida</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.wasteOneId || disabled"
                                    :selectOnTab="true"
                                    :tabindex="12"
                                    placeholder="Seleccione una medida"
                                    label="name"
                                    v-model="form.wasteOneMeasurementId"
                                    :reduce="(option) => option.id"
                                    :options="measurements">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.wasteOneMeasurementId"
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteOneMeasurementId}}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-show="loading" class="shadow sm:rounded-md bg-white mt-4">
                    <div class="h-56 flex justify-content-center items-center">
                        <Loader width="100" height="100"></Loader>
                    </div>
                </div>

                <div v-show="!loading" class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 pt-6 pb-3">
                        <h4 class="font-semibold mb-2">Residuo #2</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteTwo"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.typeWaste || disabled"
                                    :selectOnTab="true"
                                    :tabindex="13"
                                    placeholder="Seleccionar un nombre de residuo"
                                    label="name"
                                    @search="onSearchWasteTwo"
                                    @option:selected="wasteSelected(2)"
                                    v-model="form.wasteTwoId"
                                    :reduce="(option) => option.id"
                                    :options="wastesTwo">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 sm:grid-cols-4 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="quantityTwo"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Cantidad</label>
                                <input 
                                    id="quantityTwo"
                                    type="number"
                                    :tabindex="14"
                                    :disabled="form.processing || !form.wasteTwoId || disabled"
                                    v-model="form.wasteTwoQuantity"
                                    autocomplete="givenquantityTwo"
                                    class="appearance-none mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing || !form.wasteTwoId || disabled ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.wasteTwoQuantity"
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteTwoQuantity}}</div>
                            </div>
                            
                            <div class="col-span-1">
                                <label
                                    for="containerTwo"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Contenedor</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.wasteTwoId || disabled"
                                    :selectOnTab="true"
                                    :tabindex="15"
                                    placeholder="Seleccione un contenedor"
                                    label="name"
                                    v-model="form.wasteTwoContainerId"
                                    :reduce="(option) => option.id"
                                    :options="containers">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div 
                                    v-if="form.errors.wasteTwoContainerId"
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteTwoContainerId}}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="totalQuantityTwo"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Cantidad total</label>
                                <input 
                                    id="totalQuantityTwo"
                                    type="text"
                                    :tabindex="16"
                                    :disabled="form.processing || !form.wasteTwoId || disabled"
                                    v-model="form.wasteTwoTotalQuantity"
                                    autocomplete="giventotalQuantityTwo"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing || !form.wasteTwoId || disabled ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div 
                                    v-if="form.errors.wasteTwoTotalQuantity" 
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteTwoTotalQuantity}}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="measurementTwo"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Medida</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.wasteTwoId || disabled"
                                    :selectOnTab="true"
                                    :tabindex="17"
                                    placeholder="Seleccione una medida"
                                    label="name"
                                    v-model="form.wasteTwoMeasurementId"
                                    :reduce="(option) => option.id"
                                    :options="measurements">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.wasteTwoMeasurementId"
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteTwoMeasurementId}}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-show="loading" class="shadow sm:rounded-md bg-white mt-4">
                    <div class="h-56 flex justify-content-center items-center">
                        <Loader width="100" height="100"></Loader>
                    </div>
                </div>

                <div v-show="!loading" class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 pt-6 pb-3">
                        <h4 class="font-semibold mb-2">Residuo #3</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteThree"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.typeWaste || disabled"
                                    :selectOnTab="true"
                                    :tabindex="18"
                                    placeholder="Seleccionar un nombre de residuo"
                                    label="name"
                                    @search="onSearchWasteThree"
                                    @option:selected="wasteSelected(3)"
                                    v-model="form.wasteThreeId"
                                    :reduce="(option) => option.id"
                                    :options="wastesThree">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 sm:grid-cols-4 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="quantityThree"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Cantidad</label>
                                <input 
                                    id="quantityThree"
                                    type="number"
                                    :tabindex="19"
                                    :disabled="form.processing || !form.wasteThreeId || disabled"
                                    v-model="form.wasteThreeQuantity"
                                    autocomplete="givenquantityThree"
                                    class="appearance-none mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing || !form.wasteThreeId ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.wasteThreeQuantity"
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteThreeQuantity}}</div>
                            </div>
                            
                            <div class="col-span-1">
                                <label
                                    for="containerThree"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Contenedor</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.wasteThreeId || disabled"
                                    :selectOnTab="true"
                                    :tabindex="20"
                                    placeholder="Seleccione un contenedor"
                                    label="name"
                                    v-model="form.wasteThreeContainerId"
                                    :reduce="(option) => option.id"
                                    :options="containers">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div 
                                    v-if="form.errors.wasteThreeContainerId"
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteThreeContainerId}}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="totalQuantityThree"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Cantidad total</label>
                                <input 
                                    id="totalQuantityThree"
                                    type="text"
                                    :tabindex="21"
                                    :disabled="form.processing || !form.wasteThreeId || disabled"
                                    v-model="form.wasteThreeTotalQuantity"
                                    autocomplete="giventotalQuantityThree"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing || !form.wasteThreeId ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div 
                                    v-if="form.errors.wasteThreeTotalQuantity" 
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteThreeTotalQuantity}}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="measurementThree"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Medida</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.wasteThreeId || disabled"
                                    :selectOnTab="true"
                                    :tabindex="22"
                                    placeholder="Seleccione una medida"
                                    label="name"
                                    v-model="form.wasteThreeMeasurementId"
                                    :reduce="(option) => option.id"
                                    :options="measurements">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.wasteThreeMeasurementId"
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteThreeMeasurementId}}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-show="loading" class="shadow sm:rounded-md bg-white mt-4">
                    <div class="h-56 flex justify-content-center items-center">
                        <Loader width="100" height="100"></Loader>
                    </div>
                </div>

                <div v-show="!loading" class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 pt-6 pb-3">
                        <h4 class="font-semibold mb-2">Residuo #4</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteFour"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.typeWaste || disabled"
                                    :selectOnTab="true"
                                    :tabindex="23"
                                    placeholder="Seleccionar un nombre de residuo"
                                    label="name"
                                    @search="onSearchWasteFour"
                                    @option:selected="wasteSelected(4)"
                                    v-model="form.wasteFourId"
                                    :reduce="(option) => option.id"
                                    :options="wastesFour">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 sm:grid-cols-4 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="quantityFour"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Cantidad</label>
                                <input 
                                    id="quantityFour"
                                    type="number"
                                    :tabindex="24"
                                    :disabled="form.processing || !form.wasteFourId || disabled"
                                    v-model="form.wasteFourQuantity"
                                    autocomplete="givenquantityFour"
                                    class="appearance-none mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing || !form.wasteFourId || disabled ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.wasteFourQuantity"
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteFourQuantity}}</div>
                            </div>
                            
                            <div class="col-span-1">
                                <label
                                    for="containerFour"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Contenedor</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.wasteFourId || disabled"
                                    :selectOnTab="true"
                                    :tabindex="25"
                                    placeholder="Seleccione un contenedor"
                                    label="name"
                                    v-model="form.wasteFourContainerId"
                                    :reduce="(option) => option.id"
                                    :options="containers">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div 
                                    v-if="form.errors.wasteFourContainerId"
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteFourContainerId}}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="totalQuantityFour"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Cantidad total</label>
                                <input 
                                    id="totalQuantityFour"
                                    type="text"
                                    :tabindex="26"
                                    :disabled="form.processing || !form.wasteFourId || disabled"
                                    v-model="form.wasteFourTotalQuantity"
                                    autocomplete="giventotalQuantityFour"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing || !form.wasteFourId || disabled ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div 
                                    v-if="form.errors.wasteFourTotalQuantity" 
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteFourTotalQuantity}}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="measurementFour"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Medida</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.wasteFourId || disabled"
                                    :selectOnTab="true"
                                    :tabindex="27"
                                    placeholder="Seleccione una medida"
                                    label="name"
                                    v-model="form.wasteFourMeasurementId"
                                    :reduce="(option) => option.id"
                                    :options="measurements">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.wasteFourMeasurementId"
                                    class="text-red-500 text-xs mt-1">{{form.errors.wasteFourMeasurementId}}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="pt-6 text-right space-x-4">
                    <link-light :href="route('dashboard.manifests.open.index')" :disabled="form.processing">Cancelar</link-light>
                    <btn-warning type="submit" @click="form.onlyEdit = false" :disabled="form.processing">
                        <spin class="text-white" v-show="form.processing && form.onlyEdit == false"></spin>
                        Actualizar
                    </btn-warning>

                    <btn-primary type="submit" @click="form.onlyEdit = true" :disabled="form.processing">
                        <spin class="text-white" v-show="form.processing && form.onlyEdit == true"></spin>
                        Solo editar
                    </btn-primary>
                </div>
            </form>
        </div>
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnWarning       from '@/Components/Buttons/Warning'
    import BtnPrimary       from '@/Components/Buttons/Primary'
    import Date             from '@/Components/Inputs/Date'
    import moment           from 'moment';
    import Spin             from '@/Components/Spin'
    import Loader           from '@/Components/Loader'
    import vSelect          from 'vue-select'
    import 'vue-select/dist/vue-select.css';
    
    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnWarning,
            BtnPrimary,
            Date,
            Spin,
            Loader,
            vSelect
        },
        props: ['manifest', 'containers', 'measurements', 'subsidiaries'],
        data() {
            return {
                form: this.$inertia.form({
                    _method:                    'PUT',
                    onlyEdit:                   false,
                    subsidiary:                 null,
                    folio:                      null,
                    typeWaste:                  null,
                    generator:                  null,
                    carrier:                    null,
                    recipient:                  null,
                    admissionAt:                null,
                    file:                       null,
                    hasFile:                    false,
                    statusManifest:             false,
                    isConsolidated:             false,
                    isVoid:                     false,
                    wasteOneManifestWastesId:   null,
                    wasteOneId:                 null,
                    wasteOneQuantity:           null,
                    wasteOneContainerId:        null,
                    wasteOneTotalQuantity:      null,
                    wasteOneMeasurementId:      null,
                    wasteTwoManifestWastesId:   null,
                    wasteTwoId:                 null,
                    wasteTwoQuantity:           null,
                    wasteTwoContainerId:        null,
                    wasteTwoTotalQuantity:      null,
                    wasteTwoMeasurementId:      null,
                    wasteThreeManifestWastesId: null,
                    wasteThreeId:               null,
                    wasteThreeQuantity:         null,
                    wasteThreeContainerId:      null,
                    wasteThreeTotalQuantity:    null,
                    wasteThreeMeasurementId:    null,
                    wasteFourManifestWastesId:  null,
                    wasteFourId:                null,
                    wasteFourQuantity:          null,
                    wasteFourContainerId:       null,
                    wasteFourTotalQuantity:     null,
                    wasteFourMeasurementId:     null
                }),
                subsidiaryName:             null,
                statusManifestLabel:        'No',
                isConsolidatedLabel:        'No',
                isVoidLabel:                'No',
                typeWastes:                 [{id: 1, name: 'Peligrosos'}, {id: 2, name: 'No peligrosos'}],
                carriers:                   [],
                recipients:                 [],
                generators:                 [],
                filesUrl:                   (process.env.APP_ENV === 'local' ? process.env.APP_URL : process.env.ASSETS_URL) + '/files/',
                currentFileName:            null,
                showFileModal:              false,
                wastesOne:                  [],
                wastesTwo:                  [],
                wastesThree:                [],
                wastesFour:                 [],
                loading:                    true,
                disabled:                   false
            } 
        },
        methods: {
            submit() {
                this.form.post(route('dashboard.manifests.open.update', this.manifest.id), this.form, {preserveState: false, preserveScroll: false})
            },
            existence: _.debounce(async function () {
                if (this.form.folio != this.manifest.folio) {
                    const response = await axios.post(route('api.manifests.open.existence'), {folio: this.form.folio})
                    if (response.data.message) {
                        this.form.errors.folio = response.data.message
                    } else {
                        this.form.errors.folio = ''
                    }
                }
            }, 300),
            initSearch(url, search, typeWaste) {
                return axios.post(route(url), {'search': search, 'typeWaste': typeWaste})
            },
            init() {
                this.form.subsidiary        = this.manifest.subsidiary.id
                this.form.folio             = this.manifest.folio
                this.form.typeWaste         = this.manifest.type_waste

                const generator     = this.manifest?.client     ? this.manifest?.client?.data.name      : ''
                const carrier       = this.manifest?.carrier    ? this.manifest?.carrier?.data.name     : ''
                const recipient     = this.manifest?.recipient  ? this.manifest?.recipient?.data.name   : ''

                Promise.all([
                    this.initSearch('api.clients.search', generator, this.form.typeWaste),
                    this.initSearch('api.carriers.search', carrier, this.form.typeWaste),
                    this.initSearch('api.recipients.search', recipient, this.form.typeWaste)])
                    .then(res => {
                        this.generators = res[0].data.length ? res[0].data[0] : []
                        this.carriers   = res[1].data.length ? res[1].data[0] : []
                        this.recipients = res[2].data.length ? res[2].data[0] : []

                        this.form.generator         = this.manifest?.client.id
                        this.form.carrier           = this.manifest?.carrier.id
                        this.form.recipient         = this.manifest?.recipient.id

                        axios.post(route('api.wastes.search'), {'search': '','generator': this.form.generator, 'typeWaste': this.form.typeWaste}).then(res => {
                            this.wastesOne      = res.data.length ? res.data[0] : []
                            this.wastesTwo      = res.data.length ? res.data[0] : []
                            this.wastesThree    = res.data.length ? res.data[0] : []
                            this.wastesFour     = res.data.length ? res.data[0] : []

                            if (this.manifest?.manifest_wastes.length > 0) {
                                this.form.wasteOneManifestWastesId   = this.manifest?.manifest_wastes[0]?.id                ? this.manifest?.manifest_wastes[0]?.id             : null
                                this.form.wasteOneId                 = this.manifest?.manifest_wastes[0]?.waste             ? this.manifest?.manifest_wastes[0]?.waste?.id      : null
                                this.form.wasteOneQuantity           = this.manifest?.manifest_wastes[0]?.quantity          ? this.manifest?.manifest_wastes[0]?.quantity       : null
                                this.form.wasteOneContainerId        = this.manifest?.manifest_wastes[0]?.container         ? this.manifest?.manifest_wastes[0]?.container.id   : null
                                this.form.wasteOneTotalQuantity      = this.manifest?.manifest_wastes[0]?.total_quantity    ? this.manifest?.manifest_wastes[0]?.total_quantity : null
                                this.form.wasteOneMeasurementId      = this.manifest?.manifest_wastes[0]?.measurement       ? this.manifest?.manifest_wastes[0]?.measurement.id : null
                            }

                            if (this.manifest?.manifest_wastes.length > 1) {
                                this.form.wasteTwoManifestWastesId   = this.manifest?.manifest_wastes[1]?.id                ? this.manifest?.manifest_wastes[1]?.id             : null
                                this.form.wasteTwoId                 = this.manifest?.manifest_wastes[1]?.waste             ? this.manifest?.manifest_wastes[1]?.waste?.id      : null
                                this.form.wasteTwoQuantity           = this.manifest?.manifest_wastes[1]?.quantity          ? this.manifest?.manifest_wastes[1]?.quantity       : null
                                this.form.wasteTwoContainerId        = this.manifest?.manifest_wastes[1]?.container         ? this.manifest?.manifest_wastes[1]?.container.id   : null
                                this.form.wasteTwoTotalQuantity      = this.manifest?.manifest_wastes[1]?.total_quantity    ? this.manifest?.manifest_wastes[1]?.total_quantity : null
                                this.form.wasteTwoMeasurementId      = this.manifest?.manifest_wastes[1]?.measurement       ? this.manifest?.manifest_wastes[1]?.measurement.id : null
                            }

                            if (this.manifest?.manifest_wastes.length > 2) {
                                this.form.wasteThreeManifestWastesId   = this.manifest?.manifest_wastes[2]?.id                ? this.manifest?.manifest_wastes[2]?.id             : null
                                this.form.wasteThreeId                 = this.manifest?.manifest_wastes[2]?.waste             ? this.manifest?.manifest_wastes[2]?.waste?.id      : null
                                this.form.wasteThreeQuantity           = this.manifest?.manifest_wastes[2]?.quantity          ? this.manifest?.manifest_wastes[2]?.quantity       : null
                                this.form.wasteThreeContainerId        = this.manifest?.manifest_wastes[2]?.container         ? this.manifest?.manifest_wastes[2]?.container.id   : null
                                this.form.wasteThreeTotalQuantity      = this.manifest?.manifest_wastes[2]?.total_quantity    ? this.manifest?.manifest_wastes[2]?.total_quantity : null
                                this.form.wasteThreeMeasurementId      = this.manifest?.manifest_wastes[2]?.measurement       ? this.manifest?.manifest_wastes[2]?.measurement.id : null
                            }

                            if (this.manifest?.manifest_wastes.length > 3) {
                                this.form.wasteFourManifestWastesId   = this.manifest?.manifest_wastes[3]?.id                ? this.manifest?.manifest_wastes[3]?.id             : null
                                this.form.wasteFourId                 = this.manifest?.manifest_wastes[3]?.waste             ? this.manifest?.manifest_wastes[3]?.waste?.id      : null
                                this.form.wasteFourQuantity           = this.manifest?.manifest_wastes[3]?.quantity          ? this.manifest?.manifest_wastes[3]?.quantity       : null
                                this.form.wasteFourContainerId        = this.manifest?.manifest_wastes[3]?.container         ? this.manifest?.manifest_wastes[3]?.container.id   : null
                                this.form.wasteFourTotalQuantity      = this.manifest?.manifest_wastes[3]?.total_quantity    ? this.manifest?.manifest_wastes[3]?.total_quantity : null
                                this.form.wasteFourMeasurementId      = this.manifest?.manifest_wastes[3]?.measurement       ? this.manifest?.manifest_wastes[3]?.measurement.id : null
                            }
                            this.loading = false
                        })
                    })

                this.form.admissionAt       = this.manifest.admission_at                ? moment(this.manifest?.admission_at.substring(0, 10)).format('YYYY-MM-DD') : null
                this.currentFileName        = this.manifest.file != null                ? this.manifest.file.substring(10) : null
                this.form.hasFile           = this.manifest.file != null                ? true : false
                this.form.statusManifest    = this.manifest.status_manifest
                this.statusManifestLabel    = this.form.statusManifest                  ? 'Si' : 'No'
                this.form.isConsolidated    = this.manifest.is_consolidated
                this.isConsolidatedLabel    = this.form.isConsolidated                  ? 'Si' : 'No'
                this.form.isVoid            = this.manifest.is_void
                this.isVoidLabel            = this.form.isVoid                          ? 'Si' : 'No'
                if (!this.form.isVoid) this.disabled = true
            },
            openFileModal() {
                this.showFileModal = true
            },
            closeFileModal(val) {
                this.showFileModal = false
            },
            getFile(file) {
                this.form.file = file
            },
            statusManifestChange() {
                this.form.statusManifest = !this.form.statusManifest
                this.statusManifestLabel = this.form.statusManifest ? 'Si' : 'No'
            },
            isConsolidatedChange() {
                this.form.isConsolidated = !this.form.isConsolidated
                this.isConsolidatedLabel = this.form.isConsolidated ? 'Si' : 'No'
            },
            isVoidChange() {
                this.form.isVoid = !this.form.isVoid
                this.isVoidLabel = this.form.isVoid ? 'Si' : 'No'
                if (!this.form.isVoid) {
                    this.generatorSelected()
                    this.isConsolidatedChange()
                    this.disabled = true
                } else {
                    this.isConsolidatedChange()
                    this.disabled = false
                }
            },
            typeWasteSelected() {
                this.form.generator     = null
                this.generators         = []
                this.form.carrier       = null
                this.carriers           = []
                this.form.recipient     = null
                this.recipients         = []
                this.generatorSelected()
            },
            onSearchGenerator(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchGenerator(loading, search, this.form.typeWaste, this)
                }
            },
            searchGenerator: _.debounce((loading, search, typeWaste, vm) => {
                axios.post(route('api.clients.search'), {'search': search, 'typeWaste': typeWaste}).then(res => {
                    vm.generators = res.data.length ? res.data[0] : []
                    loading(false)
                })
            }, 350),
            generatorSelected() {
                //this.form.wasteOneManifestWastesId   = null
                //this.form.wasteTwoManifestWastesId   = null
                //this.form.wasteThreeManifestWastesId = null
                //this.form.wasteFourManifestWastesId  = null
                this.form.wasteOneId                 = null
                this.form.wasteTwoId                 = null
                this.form.wasteThreeId               = null
                this.form.wasteFourId                = null
                this.wastes                          = []
            },
            wasteSelected(number) {
                if (number == 1) {
                    this.form.wasteOneQuantity           = null
                    this.form.wasteOneContainerId        = null
                    this.form.wasteOneTotalQuantity      = null
                    this.form.wasteOneMeasurementId      = null
                } else if (number == 2) {
                    this.form.wasteTwoQuantity           = null
                    this.form.wasteTwoContainerId        = null
                    this.form.wasteTwoTotalQuantity      = null
                    this.form.wasteTwoMeasurementId      = null
                } else if (number == 3) {
                    this.form.wasteThreeQuantity         = null
                    this.form.wasteThreeContainerId      = null
                    this.form.wasteThreeTotalQuantity    = null
                    this.form.wasteThreeMeasurementId    = null
                }  else if (number == 4) {
                    this.form.wasteFourQuantity          = null
                    this.form.wasteFourContainerId       = null
                    this.form.wasteFourTotalQuantity     = null
                    this.form.wasteFourMeasurementId     = null
                }
            },
            onSearchCarrier(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchCarrier(loading, search, this.form.typeWaste, this)
                }
            },
            searchCarrier: _.debounce((loading, search, typeWaste, vm) => {
                axios.post(route('api.carriers.search'), {'search': search, 'typeWaste': typeWaste}).then(res => {
                    vm.carriers = res.data.length ? res.data[0] : []
                    loading(false)
                })
            }, 350),
            onSearchRecipient(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchRecipient(loading, search, this.form.typeWaste, this)
                }
            },
            searchRecipient: _.debounce((loading, search, typeWaste, vm) => {
                axios.post(route('api.recipients.search'), {'search': search, 'typeWaste': typeWaste}).then(res => {
                    vm.recipients = res.data.length ? res.data[0] : []
                    loading(false)
                })
            }, 350),
            onSearchWasteOne(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchWaste(loading, search, this.form.generator, this.form.typeWaste, this, 1)
                }
            },
            onSearchWasteTwo(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchWaste(loading, search, this.form.generator, this.form.typeWaste, this, 2)
                }
            },
            onSearchWasteThree(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchWaste(loading, search, this.form.generator, this.form.typeWaste, this, 3)
                }
            },
            onSearchWasteFour(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchWaste(loading, search, this.form.generator, this.form.typeWaste, this, 4)
                }
            },
            searchWaste: _.debounce((loading, search, generator, typeWaste, vm, waste) => {
                axios.post(route('api.wastes.search'), {'search': search, 'generator': generator, 'typeWaste': typeWaste}).then(res => {
                    if (waste == 1) {
                        vm.wastesOne = res.data.length ? res.data[0] : []
                    } else if (waste == 2) {
                        vm.wastesTwo = res.data.length ? res.data[0] : []
                    } else if (waste == 3) {
                        vm.wastesThree = res.data.length ? res.data[0] : []
                    } else if (waste == 4) {
                        vm.wastesFour = res.data.length ? res.data[0] : []
                    }
                    loading(false)
                })
            }, 350),
        },
        mounted() {
            this.init()
        },
        watch: {
            form: {
                deep:true,
                handler(data) {
                    if (!data.wasteOneId)   this.wasteSelected(1)
                    if (!data.wasteTwoId)   this.wasteSelected(2)
                    if (!data.wasteThreeId) this.wasteSelected(3)
                    if (!data.wasteFourId)  this.wasteSelected(4)

                    if (data.wasteOneTotalQuantity) {
                        if (data.wasteOneTotalQuantity.toString().includes(',')) {
                            let totalQuantity = data.wasteOneTotalQuantity.toString().replace(',', '')
                            this.form.wasteOneTotalQuantity = totalQuantity
                        }
                    }

                    if (data.wasteTwoTotalQuantity) {
                        if (data.wasteTwoTotalQuantity.toString().includes(',')) {
                            let totalQuantity = data.wasteTwoTotalQuantity.toString().replace(',', '')
                            this.form.wasteTwoTotalQuantity = totalQuantity
                        }
                    }

                    if (data.wasteThreeTotalQuantity) {
                        if (data.wasteThreeTotalQuantity.toString().includes(',')) {
                            let totalQuantity = data.wasteThreeTotalQuantity.toString().replace(',', '')
                            this.form.wasteThreeTotalQuantity = totalQuantity
                        }
                    }

                    if (data.wasteFourTotalQuantity) {
                        if (data.wasteFourTotalQuantity.toString().includes(',')) {
                            let totalQuantity = data.wasteFourTotalQuantity.toString().replace(',', '')
                            this.form.wasteFourTotalQuantity = totalQuantity
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .h-100 {
        height: 28.425rem;
    }
</style>