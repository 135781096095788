<template>
    <nav class="bg-white border-b border-gray-100 flex-shrink-0">
        <div class="flex justify-between items-center">

            <div class="flex items-center">
                <button class="p-4" @click="showSidebar">
                    <menu-outline class="h-5 w-5"></menu-outline>
                </button>
                
                <div class="p-4">
                    <inertia-link :href="route('dash')">
                        <img :src="logotipo" alt="logotipo" width="80" height="40">
                    </inertia-link>
                </div>
            </div>

            <div class="flex items-center">
                <!-- Settings Dropdown -->
                <div class="relative p-4">
                    <dropdown align="right" width="48">
                        <template #trigger>
                            <button class="flex text-sm border-2 border-transparent rounded-full focus:outline-none transition">
                                <img :src="storagePath + $page.props.auth.user.data.photo" :alt="$page.props.auth.user.name" class="w-8 h-8 rounded-full object-cover">
                            </button>
                        </template>

                        <template #content>
                            <!-- Account Management -->
                            <!-- <div class="block px-4 py-2 text-xs text-gray-400">
                                Administrar cuenta
                            </div>

                            <div class="border-t border-gray-100"></div> -->

                            <!-- Authentication -->
                            <form :action="route('auth.employees.logout')" method="POST">
                                <input type="hidden" name="_token" :value="csrf">
                                
                                <button type="submit" 
                                    class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition">
                                    Cerrar sesión
                                </button>
                            </form>
                        </template>
                    </dropdown>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import Dropdown         from '@/Components/Dropdown'
    import DropdownLink     from '@/Components/DropdownLink'
    import MenuOutline      from '@/Components/Icons/HeroIcons/Outline/Menu'

    export default {
        components: {
            InertiaLink,
            Dropdown,
            DropdownLink,
            MenuOutline
        },
        data() {
            return {
                storagePath:    process.env.APP_URL + '/storage/',
                logotipo:       process.env.APP_URL + '/storage/brand/new_logotipo.svg',
                csrf:           document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        },
        emits: ['toggleSidebar'],
        methods: {
            showSidebar() {
                this.setSidebar(!this.getSidebar())
                this.$emit('toggleSidebar')
            },
            getSidebar() {
                if (window.localStorage.getItem('show-sidebar')) return JSON.parse(window.localStorage.getItem('show-sidebar'))
                return false;
            },
            setSidebar(value) {
                window.localStorage.setItem('show-sidebar', value)
            }
        }
    }
</script>