<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Facturas
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="grid grid-cols-1 gap-4 p-4">
            <div class="col-span-1">
                <div class="flex flex-col md:flex-row justify-between">
                    <div class="flex flex-row">
                        <div class="flex flex-row w-32 sm:w-24">
                            <select
                                class="h-full w-full sm:rounded-l appearance-none bg-white text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none ring-0 focus:ring-transparent border-gray-200 focus:border-gray-200"
                                v-model="form.perPage"
                                @change="perPageChanged">
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                        <div class="flex md:w-56 w-full">
                            <input placeholder="Buscar" class="appearance-none sm:rounded-r border-gray-200 border-t border-b border-r px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" v-model="form.search" @keyup="search" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="bg-white overflow-hidden shadow sm:rounded">
                    <table-container :heading="heading">
                        <tbody>
                            <tr v-show="loading">
                                <td :colspan="heading.length" class="h-96">
                                    <loader width="100" height="100"></loader>
                                </td>
                            </tr>

                            <tr v-if="invoices?.data?.length == 0 && !loading" class="text-center">
                                <table-body-item :colspan="heading.length">
                                    No se encontraron resultados.
                                </table-body-item>
                            </tr>

                            <tr v-else-if="invoices?.data?.length > 0 && !loading" v-for="(invoice, index) in invoices.data" :key="index">
                                <table-body-item>
                                    {{invoice.uuid}}
                                </table-body-item>
                                <table-body-item>
                                    {{invoice.StatusLabel}}
                                </table-body-item>
                                <table-body-item>
                                    {{invoice.manifest.folio}}
                                </table-body-item>
                                <table-body-item>
                                    {{invoice.manifest?.client.data.name}}
                                </table-body-item>
                                <table-body-item>
                                    {{invoice.business_name}}
                                </table-body-item>
                                <table-body-item>
                                    {{invoice.TypeAgreementLabel}}
                                </table-body-item>
                                <table-body-item>
                                    <span v-if="invoice.reason">{{invoice.reason}}</span>
                                    <span v-else>N/A</span>
                                </table-body-item>
                                <td class="px-6 py-4">
                                    <table-body-buttons>
                                        <button @click="generatePDF(invoice)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-green-500 hover:text-green-400 transition duration-500 ease">
                                            <save-outline class="h-5 w-5"></save-outline>
                                        </button>

                                        <a v-if="invoice.file" :href="`${filesUrl}${invoice.file}`" target="_blank"  class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-orange-500 hover:text-orange-400 transition duration-500 ease">
                                            <doc-solid class="h-5 w-5"></doc-solid>
                                        </a>

                                        <button v-else class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-orange-500 hover:text-orange-400 transition duration-500 ease">
                                            <close-solid class="h-5 w-5"></close-solid>
                                        </button>

                                        <inertia-link :href="route('dashboard.invoices.all.show', invoice.id)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-cyan-500 hover:text-cyan-400 transition duration-500 ease">
                                            <eye-solid class="h-5 w-5"></eye-solid>
                                        </inertia-link>
                                    </table-body-buttons>
                                </td>
                            </tr>
                        </tbody>
                    </table-container>

                    <div class="py-4 px-6">
                    <pagination :items="invoices" @nextPage="currentPage" @prevPage="currentPage" @link="currentPage" />
                </div>

                </div>
            </div>
        </div>

    </app-layout>
</template>

<script>
    import { InertiaLink }      from '@inertiajs/inertia-vue3'
    import { Inertia }          from '@inertiajs/inertia'
    import moment               from 'moment'
    import AppLayout            from '@/Layouts/AppLayout.vue'
    import BreadcumbNav         from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem        from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid            from '@/Components/Icons/HeroIcons/Solid/Home'
    import SearchSolid          from '@/Components/Icons/HeroIcons/Solid/Search'
    import DocSolid             from '@/Components/Icons/HeroIcons/Solid/Doc'
    import CloseSolid           from '@/Components/Icons/HeroIcons/Solid/Close'
    import PrintSolid           from '@/Components/Icons/HeroIcons/Solid/Print'
    import EyeSolid             from '@/Components/Icons/HeroIcons/Solid/Eye'
    import EditSolid            from '@/Components/Icons/HeroIcons/Solid/Edit'
    import BanSolid             from '@/Components/Icons/HeroIcons/Solid/Ban'
    import SaveOutline          from '@/Components/Icons/HeroIcons/Outline/Save'
    import ImageOutline         from '@/Components/Icons/HeroIcons/Outline/Image'
    import TableContainer       from '@/Components/Tables/TableContainer'
    import TableBodyItem        from '@/Components/Tables/TableBodyItem'
    import TableBodyButtons     from '@/Components/Tables/TableBodyButtons'
    import PrimaryLinkExpanded  from '@/Components/Buttons/Expanded/Primary'
    import Pagination           from '@/Components/Pagination/Pagination'
    import Loader               from '@/Components/Loader'
    import DialogModal          from '@/Components/DialogModal'
    import BtnLight             from '@/Components/Buttons/Light'
    import BtnDanger            from '@/Components/Buttons/Danger'
    import jsPDF                from 'jspdf'

    const heading = ['UUID', 'Estado', 'Folio', 'Generadora', 'Razon social', 'Tipo de contrato', 'Razón de cancelación', 'Opciones']

    export default {
        components: {
            InertiaLink,
            Inertia,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            SearchSolid,
            EyeSolid,
            EditSolid,
            DocSolid,
            PrintSolid,
            CloseSolid,
            BanSolid,
            SaveOutline,
            ImageOutline,
            TableContainer,
            TableBodyItem,
            TableBodyButtons,
            PrimaryLinkExpanded,
            Pagination,
            Loader,
            DialogModal,
            BtnLight,
            BtnDanger,

        },
        data() {
            return {
                heading,
                form: {
                    search:     this.getTableVariables().search,
                    perPage:    this.getTableVariables().perPage,
                    pagination: this.getTableVariables().pagination,
                },
                invoices:           [],
                tableVariables:     null,
                loading:            true,
                filesUrl:           (process.env.APP_ENV === 'local' ? process.env.APP_URL : process.env.ASSETS_URL) + '/files/',
                logo:               process.env.APP_URL + '/storage/brand/imagotipo.png',
            }
        },
        methods: {
            getTableVariables() {
                return JSON.parse(window.localStorage.getItem(`${this.$page.url}-table`))
            },
            perPageChanged() {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination = 1
                    this.form.pagination           = 1
                }
                this.tableVariables.perPage = this.form.perPage
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
                this.index()
            },
            async index() {
                this.loading        = true
                const invoices      = await axios.post(route('api.invoices.all.index'), this.form)
                this.invoices       = invoices.data
                this.loading        = false
            },
            search: _.debounce(function () {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination  = 1
                    this.form.pagination            = 1
                }
                this.tableVariables.search = this.form.search
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
                this.index()
            }, 300),
            async currentPage(page) {
                this.tableVariables.pagination = page.substring(page.indexOf("=") + 1)
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
                this.form.pagination = page.substring(page.indexOf("=") + 1)
                this.index()
            },
            generatePDF(invoice) {
                const doc   = new jsPDF()
                var img     = new Image()
                img.src     = this.logo
                doc.addImage(img, 'png', 15, 10, 50, 28)
                doc.setFontSize(14)
                doc.text(`FECHA PREFACTURA: 24/01/2022`, 80, 16)
                doc.text(`PREFACTURA: ${invoice.uuid}`, 80, 23)
                doc.text(`TIPO DE SERVICIO: ${invoice.TypeAgreementLabel}`, 80, 30)

                doc.line(15, 46, 195, 46)

                doc.setFontSize(12)
                doc.text(`MANIFIESTO`, 15, 60)
                doc.setFontSize(14)
                doc.text(`${invoice.manifest.folio}`, 15, 66)
                doc.setFontSize(12)
                doc.text(`CORREO ELECTRÓNICO`, 80, 60)
                doc.setFontSize(14)
                doc.text(`${invoice.email}`, 80, 66)
                doc.setFontSize(12)
                doc.text(`RAZÓN SOCIAL`, 15, 74)
                doc.setFontSize(14)
                doc.text(`${invoice.business_name}`, 15, 80)

                doc.setFontSize(12)
                doc.text(`SERVICIO DE RECOLECCIÓN:`, 15, 90)
                doc.setFontSize(14)
                doc.text(`$${invoice.base_price}`, 120, 90)

                if (invoice.additional_quantity) {
                    doc.setFontSize(12)
                    doc.text(`COSTO POR KILO EXTRA:`, 15, 96)
                    doc.setFontSize(14)
                    doc.text(`$${invoice.additional_price}`, 120, 96)
                    doc.setFontSize(12)
                    doc.text(`KILOS EXTRAS:`, 15, 102)
                    doc.setFontSize(14)
                    doc.text(`${invoice.additional_quantity}`, 120, 102)

                    const totalAdditionalPrice = invoice.additional_price * invoice.additional_quantity
                    
                    doc.setFontSize(12)
                    doc.text(`TOTAL POR KILOS EXTRAS:`, 15, 108)
                    doc.setFontSize(14)
                    doc.text(`$${totalAdditionalPrice}`, 120, 108)
                    doc.setFontSize(12)
                    doc.text(`SUBTOTAL:`, 15, 114)
                    doc.setFontSize(14)
                    doc.text(`$${invoice.subtotal}`, 120, 114)

                    if (invoice.vat_8) {
                        doc.setFontSize(12)
                        doc.text(`I.V.A. 8%:`, 15, 120)
                        doc.setFontSize(14)
                        doc.text(`$${invoice.vat_8_value}`, 120, 120)

                        if (invoice.vat_16) {
                            doc.setFontSize(12)
                            doc.text(`I.V.A. 16%:`, 15, 126)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.vat_16_value}`, 120, 126)
                            doc.setFontSize(12)
                            doc.text(`TOTAL:`, 15, 132)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.total}`, 120, 132)
                        } else {
                            doc.setFontSize(12)
                            doc.text(`TOTAL:`, 15, 126)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.total}`, 120, 126)
                        }

                    } else {
                        
                        if (invoice.vat_16) {
                            doc.setFontSize(12)
                            doc.text(`I.V.A. 16%:`, 15, 120)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.vat_16_value}`, 120, 120)
                            doc.setFontSize(12)
                            doc.text(`TOTAL:`, 15, 126)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.total}`, 120, 126)
                        }
                    }


                } else {
                    doc.setFontSize(12)
                    doc.text(`SUBTOTAL:`, 15, 96)
                    doc.setFontSize(14)
                    doc.text(`$${invoice.subtotal}`, 120, 96)

                    if (invoice.vat_8) {
                        doc.setFontSize(12)
                        doc.text(`I.V.A. 8%:`, 15, 102)
                        doc.setFontSize(14)
                        doc.text(`$${invoice.vat_8_value}`, 120, 102)

                        if (invoice.vat_16) {
                            doc.setFontSize(12)
                            doc.text(`I.V.A. 16%:`, 15, 108)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.vat_16_value}`, 120, 108)
                            doc.setFontSize(12)
                            doc.text(`TOTAL:`, 15, 114)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.total}`, 120, 114)
                        } else {
                            doc.setFontSize(12)
                            doc.text(`TOTAL:`, 15, 108)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.total}`, 120, 108)
                        }

                    } else {
                        
                        if (invoice.vat_16) {
                            doc.setFontSize(12)
                            doc.text(`I.V.A. 16%:`, 15, 102)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.vat_16_value}`, 120, 102)
                            doc.setFontSize(12)
                            doc.text(`TOTAL:`, 15, 108)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.total}`, 120, 108)
                        }
                    }
                }

                doc.save(`${invoice.uuid}_${invoice.manifest.folio}.pdf`)
            }
        },
        beforeCreate: function () {
            if (!window.localStorage.getItem(`${this.$page.url}-table`)) {
                const table = {
                    perPage: 25,
                    pagination: 1,
                    search: ''
                }
                window.localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(table))
            }
        },
        mounted() {
            this.tableVariables = JSON.parse(localStorage.getItem(`${this.$page.url}-table`))
            this.index()
        }
    }
</script>