<template>
    <inertia-link :href="href" :disabled="disabled" :class="disabled ? 'disabled' : 'cursor-pointer'" class="inline-flex items-center">
        <slot></slot>
    </inertia-link>
</template>

<script>
    import { InertiaLink } from '@inertiajs/inertia-vue3'

    export default {
        components: {InertiaLink},
        props: {
            href: {
                type: String,
                default: '#',
            },
            disabled: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style>
    .disabled {
        pointer-events: none;
        cursor: not-allowed;
    }
</style>