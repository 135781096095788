<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.generics.index')">
                        Genericos
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    {{generic.name}}
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Información del generico
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Datos generales.
                    </p>
                </div>
                <div class="border-t border-gray-200">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Nombre
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{generic.name}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Residuo R.P.B.I.
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span v-if="generic.status_rpbi">Si</span>
                                <span v-else>No</span>
                            </dd>
                        </div>
                        <div v-if="!generic.status_rpbi">
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Nombre de transporte
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{generic?.transportname?.name}}
                                </dd>
                            </div>

                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Número U.N.
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{generic?.numberun?.name}}
                                </dd>
                            </div>

                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Caracteristicas C.R.E.T.I.B.
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <div v-for="(hazard, index) in generic.hazards" :key="index">
                                        {{hazard.name}}
                                    </div>
                                </dd>
                            </div>

                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Clases
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <div v-for="(level, index) in generic.levels" :key="index">
                                        {{level.name}}
                                    </div>
                                </dd>
                            </div>

                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Packing group
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{generic?.packing_group}}
                                </dd>
                            </div>

                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Nombre de transporte integrado
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{generic.integrated_transport_name}}
                                </dd>
                            </div>
                        </div>

                    </dl>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
        },
        props: ['generic'],
    }
</script>