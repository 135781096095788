<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Consolidados por finalizar
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="grid grid-cols-1 gap-4 p-4">
            <div class="col-span-1">
                <div class="flex flex-col xl:flex-row justify-between">

                    <div class="flex flex-row">
                        <div class="flex flex-row w-20">
                            <select
                                class="h-full w-max sm:rounded-l appearance-none bg-white text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none ring-0 focus:ring-transparent border-gray-200 focus:border-gray-200"
                                v-model="form.perPage"
                                @change="perPageChanged">
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                        <div class="flex xl:w-56 w-full">
                            <input
                                @paste="search"
                                @cut="search"
                                placeholder="Buscar"
                                class="appearance-none border-gray-200 border-t border-b px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                                v-model="form.search" @keyup="search" />
                        </div>
                        <div class="flex flex-row w-96 sm:w-56">
                            <select
                                class="h-full w-full sm:rounded-r appearance-none bg-white text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none ring-0 focus:ring-transparent border-gray-200 focus:border-gray-200"
                                v-model="form.perSubsidiary"
                                @change="perSubsidiaryChanged">
                                <option v-for="(subsidiary, index) in subsidiaries" :key="index" :value="subsidiary.id">{{subsidiary.descriptive_name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="pt-4 xl:pt-0 grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <button
                            type="button"
                            @click="openInvoiceCarrierModal"
                            :class="disabledInvoiceCarrier ? 'cursor-not-allowed' : 'cursor-pointer'"
                            :disabled="disabledInvoiceCarrier"
                            class="col-span-1 h-full inline-flex items-center justify-center px-4 py-2 border sm:rounded-md bg-blue-400 hover:bg-blue-500 font-semibold text-white text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                            Factura transportista
                        </button>
                        <button
                            type="button"
                            @click="openInvoiceRecipientModal"
                            :class="disabledInvoiceRecipient ? 'cursor-not-allowed' : 'cursor-pointer'"
                            :disabled="disabledInvoiceRecipient"
                            class="col-span-1 h-full inline-flex items-center justify-center px-4 py-2 border sm:rounded-md bg-blue-400 hover:bg-blue-500 font-semibold text-white text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                            Factura destinatario
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="bg-white overflow-hidden shadow sm:rounded">
                    <table-container :heading="heading">
                        <tbody>
                            <tr v-show="loading">
                                <td :colspan="heading.length" class="h-96">
                                    <loader width="100" height="100"></loader>
                                </td>
                            </tr>

                            <tr v-if="consolidated?.data?.length == 0 && !loading" class="text-center">
                                <table-body-item :colspan="heading.length">
                                    No se encontraron resultados.
                                </table-body-item>
                            </tr>

                            <tr v-else-if="consolidated?.data?.length > 0 && !loading" v-for="(consol, index) in consolidated.data" :key="index">
                                <table-body-item>
                                    <div class="flex items-start">
                                        <div class="flex items-center">
                                            <input
                                                :ref="'checkbox_'+consol.id"
                                                type="checkbox"
                                                :disabled="disabledCheckbox"
                                                class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                                :class="disabledCheckbox ? 'cursor-not-allowed' : 'cursor-default'"
                                                @click="changeInput(consol, 'checkbox_')" />
                                        </div>
                                    </div>
                                </table-body-item>
                                <table-body-item>
                                    <span v-if="consol.departure_at!=null">
                                        {{moment(consol.departure_at)}}
                                    </span>
                                    <span v-else>Sin fecha</span>
                                </table-body-item>
                                <table-body-item>
                                    {{consol.folio}}
                                </table-body-item>
                                <table-body-item>
                                    {{consol.subsidiary}}
                                </table-body-item>
                                <table-body-item>
                                    {{consol.carrier}}
                                </table-body-item>
                                <table-body-item>
                                    {{consol.recipient}}
                                </table-body-item>
                                <table-body-item>
                                    <span v-for="(folio, index) in consol.folios" :key="index">
                                        <a v-if="folio.status == 'to-consolidate'" :href="route('dashboard.manifests.consolidate.show', folio.id)" class="text-orange-500 hover:text-orange-400" target="_blank">{{folio.folio}}</a>
                                        <a v-else-if="folio.status == 'consolidated'" :href="route('dashboard.manifests.consolidated.show', folio.id)" class="text-orange-500 hover:text-orange-400" target="_blank">{{folio.folio}}</a>
                                        <span v-if="index != consol.folios.length-1">, </span>
                                    </span>
                                </table-body-item>
                                <table-body-item>
                                    <span v-if="consol.carrier_invoice">Si</span>
                                    <span v-else>No</span>
                                </table-body-item>
                                <table-body-item>
                                    <span v-if="consol.recipient_invoice">Si</span>
                                    <span v-else>No</span>
                                </table-body-item>
                                <td class="px-6 py-4">
                                    <table-body-buttons>
                                        <a v-if="consol.carrier_invoice" :href="`${filesUrl}${consol.carrier_invoice}`" target="_blank"  class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-emerald-500 hover:text-emerald-400 transition duration-500 ease">
                                            <doc-solid class="h-5 w-5"></doc-solid>
                                        </a>

                                        <button v-else class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-orange-500 hover:text-orange-400 transition duration-500 ease">
                                            <close-solid class="h-5 w-5"></close-solid>
                                        </button>

                                        <a v-if="consol.recipient_invoice" :href="`${filesUrl}${consol.recipient_invoice}`" target="_blank"  class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-teal-500 hover:text-teal-400 transition duration-500 ease">
                                            <doc-solid class="h-5 w-5"></doc-solid>
                                        </a>

                                        <button v-else class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-orange-500 hover:text-orange-400 transition duration-500 ease">
                                            <close-solid class="h-5 w-5"></close-solid>
                                        </button>

                                        <a v-if="consol.file" :href="`${filesUrl}${consol.file}`" target="_blank"  class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-orange-500 hover:text-orange-400 transition duration-500 ease">
                                            <doc-solid class="h-5 w-5"></doc-solid>
                                        </a>

                                        <button v-else class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-orange-500 hover:text-orange-400 transition duration-500 ease">
                                            <close-solid class="h-5 w-5"></close-solid>
                                        </button>

                                        <a v-if="consol.order" :href="`${filesUrl}orders/${consol.order}`" target="_blank"  class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-blue-500 hover:text-blue-400 transition duration-500 ease">
                                            <doc-solid class="h-5 w-5"></doc-solid>
                                        </a>

                                        <inertia-link :href="route('dashboard.consolidated.finalize.show', consol.id)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-cyan-500 hover:text-cyan-400 transition duration-500 ease">
                                            <eye-solid class="h-5 w-5"></eye-solid>
                                        </inertia-link>

                                        <inertia-link :href="route('dashboard.consolidated.finalize.edit', consol.id)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-yellow-500 hover:text-yellow-400 transition duration-500 ease">
                                            <edit-solid class="h-5 w-5 "></edit-solid>
                                        </inertia-link>

                                        <button @click="toggleCancelModal(consol.folio, consol.id)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-red-500 hover:text-red-400 transition duration-500 ease">
                                            <ban-solid class="h-5 w-5"></ban-solid>
                                        </button>
                                    </table-body-buttons>
                                </td>
                            </tr>
                        </tbody>
                    </table-container>

                    <div class="py-4 px-6">
                    <pagination :items="consolidated" @nextPage="currentPage" @prevPage="currentPage" @link="currentPage" />
                </div>

                </div>
            </div>
        </div>

        <dialog-modal :show="showCancelModal">
            <template #title>
                <div class="text-2xl leading-6 font-bold text-gray-900 uppercase">Cancelar {{folio}}</div>
            </template>
            <template #content>
                <div class="pb-3">
                    <div class="grid grid-cols-1 gap-4">
                        <div class="col-span-1">
                            <label
                                for="reason"
                                class="mb-1 block text-sm font-semibold text-gray-700">Razon de cancelación</label>
                            <textarea
                                id="reason"
                                type="text"
                                :disabled="processing"
                                v-model="cancelForm.reason"
                                autocomplete="givenreason"
                                rows="3"
                                class="resize-none my-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25" ></textarea>
                            <div
                                v-if="cancelForm.errorReason"
                                class="text-red-500 text-xs">El campo razon de cancelación es obligatorio.</div>
                        </div>
                    </div>
                </div>
                <div class="pb-3">
                    <div class="grid grid-cols-1">
                        <label class="mb-1 block text-sm font-semibold text-gray-700">Adjuntar manifiesto cancelado</label>
                        <div class="my-1 flex items-center justify-center w-full">
                            <label class="flex flex-col border-4 border-dashed w-full h-32 hover:border-orange-300 group cursor-pointer">
                                <div class="flex flex-col items-center justify-center pt-7">
                                    <image-outline class="w-10 h-10 text-gray-400 group-hover:text-orange-400"></image-outline>

                                    <p class="lowercase text-sm text-gray-400 group-hover:text-orange-400 pt-1 tracking-wider">
                                        <span v-if="cancelForm.file">{{cancelForm.file.name}}</span>
                                        <span v-else>Select a photo</span>
                                    </p>
                                </div>
                                <input type="file" :disabled="processing" @change="fileInputValue" class="hidden" accept="application/pdf" />
                            </label>
                        </div>
                        <div
                            v-if="cancelForm.errorFile"
                            class="text-red-500 text-xs">El campo adjuntar manifiesto cancelado es obligatorio.</div>
                    </div>
                </div>
                <div class="text-sm text-medium text-gray-500">Esta acción se puede deshacer.</div>
            </template>
            <template #footer>
                <div class="space-x-4">
                    <btn-light @click="toggleCancelModal" :disabled="processing">Cancelar</btn-light>
                    <btn-danger @click="cancelManifest" :disabled="processing">Aceptar</btn-danger>
                </div>
            </template>
        </dialog-modal>

        <dialog-modal :show="showInvoiceCarrierModal">
            <template #title>
                <div class="text-2xl leading-6 font-bold text-gray-900 uppercase">Agregar factura del transportista</div>
            </template>
            <template #content>
                <div class="pb-3">
                    <div class="grid grid-cols-1 gap-4">
                        <div class="col-span-1">
                            <label
                                for="reason"
                                class="mb-1 block text-sm font-semibold text-gray-700">Consolidados seleccionados</label>
                            <span v-for="(consolidated, index) in selectedConsolidated" :key="index">
                                {{consolidated.folio}}<span v-if="index != (selectedConsolidated.length-1)">, </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="pb-3">
                    <div class="grid grid-cols-1">
                        <label class="mb-1 block text-sm font-semibold text-gray-700">Adjuntar factura del transportista</label>
                        <div class="my-1 flex items-center justify-center w-full">
                            <label class="flex flex-col border-4 border-dashed w-full h-32 hover:border-orange-300 group cursor-pointer">
                                <div class="flex flex-col items-center justify-center pt-7">
                                    <image-outline class="w-10 h-10 text-gray-400 group-hover:text-orange-400"></image-outline>

                                    <p class="lowercase text-sm text-gray-400 group-hover:text-orange-400 pt-1 tracking-wider">
                                        <span v-if="invoiceForm.file">{{invoiceForm.file.name}}</span>
                                        <span v-else>Select a PDF</span>
                                    </p>
                                </div>
                                <input type="file" :disabled="processingInvoice" @change="fileInputValueInvoice" class="hidden" accept="application/pdf" />
                            </label>
                        </div>
                        <div
                            v-if="invoiceForm.errorFile"
                            class="text-red-500 text-xs">El campo adjuntar factura del transportista es obligatorio.</div>
                    </div>
                </div>
                <div class="text-sm text-medium text-gray-500">Esta acción se puede deshacer.</div>
            </template>
            <template #footer>
                <div class="space-x-4">
                    <btn-light @click="closeInvoiceCarrierModal" :disabled="processingInvoice">Cancelar</btn-light>
                    <btn-success @click="addInvoice" :disabled="processingInvoice">Aceptar</btn-success>
                </div>
            </template>
        </dialog-modal>

        <dialog-modal :show="showInvoiceRecipientModal">
            <template #title>
                <div class="text-2xl leading-6 font-bold text-gray-900 uppercase">Agregar factura del destinatario</div>
            </template>
            <template #content>
                <div class="pb-3">
                    <div class="grid grid-cols-1 gap-4">
                        <div class="col-span-1">
                            <label
                                for="reason"
                                class="mb-1 block text-sm font-semibold text-gray-700">Consolidados seleccionados</label>
                            <span v-for="(consolidated, index) in selectedConsolidated" :key="index">
                                {{consolidated.folio}}<span v-if="index != (selectedConsolidated.length-1)">, </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="pb-3">
                    <div class="grid grid-cols-1">
                        <label class="mb-1 block text-sm font-semibold text-gray-700">Adjuntar factura del destinatario</label>
                        <div class="my-1 flex items-center justify-center w-full">
                            <label class="flex flex-col border-4 border-dashed w-full h-32 hover:border-orange-300 group cursor-pointer">
                                <div class="flex flex-col items-center justify-center pt-7">
                                    <image-outline class="w-10 h-10 text-gray-400 group-hover:text-orange-400"></image-outline>

                                    <p class="lowercase text-sm text-gray-400 group-hover:text-orange-400 pt-1 tracking-wider">
                                        <span v-if="invoiceForm.file">{{invoiceForm.file.name}}</span>
                                        <span v-else>Select a PDF</span>
                                    </p>
                                </div>
                                <input type="file" :disabled="processingInvoice" @change="fileInputValueInvoice" class="hidden" accept="application/pdf" />
                            </label>
                        </div>
                        <div
                            v-if="invoiceForm.errorFile"
                            class="text-red-500 text-xs">El campo adjuntar factura del destinatario es obligatorio.</div>
                    </div>
                </div>
                <div class="text-sm text-medium text-gray-500">Esta acción se puede deshacer.</div>
            </template>
            <template #footer>
                <div class="space-x-4">
                    <btn-light @click="closeInvoiceRecipientModal" :disabled="processingInvoice">Cancelar</btn-light>
                    <btn-success @click="addInvoice" :disabled="processingInvoice">Aceptar</btn-success>
                </div>
            </template>
        </dialog-modal>

    </app-layout>
</template>

<script>
    import { InertiaLink }      from '@inertiajs/inertia-vue3'
    import { Inertia }          from '@inertiajs/inertia'
    import moment               from 'moment'
    import AppLayout            from '@/Layouts/AppLayout.vue'
    import BreadcumbNav         from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem        from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid            from '@/Components/Icons/HeroIcons/Solid/Home'
    import SearchSolid          from '@/Components/Icons/HeroIcons/Solid/Search'
    import DocSolid             from '@/Components/Icons/HeroIcons/Solid/Doc'
    import CloseSolid           from '@/Components/Icons/HeroIcons/Solid/Close'
    import PrintSolid           from '@/Components/Icons/HeroIcons/Solid/Print'
    import EyeSolid             from '@/Components/Icons/HeroIcons/Solid/Eye'
    import EditSolid            from '@/Components/Icons/HeroIcons/Solid/Edit'
    import BanSolid             from '@/Components/Icons/HeroIcons/Solid/Ban'
    import ImageOutline         from '@/Components/Icons/HeroIcons/Outline/Image'
    import TableContainer       from '@/Components/Tables/TableContainer'
    import TableBodyItem        from '@/Components/Tables/TableBodyItem'
    import TableBodyButtons     from '@/Components/Tables/TableBodyButtons'
    import PrimaryLinkExpanded  from '@/Components/Buttons/Expanded/Primary'
    import Pagination           from '@/Components/Pagination/Pagination'
    import Loader               from '@/Components/Loader'
    import DialogModal          from '@/Components/DialogModal'
    import BtnLight             from '@/Components/Buttons/Light'
    import BtnDanger            from '@/Components/Buttons/Danger'
    import BtnSuccess           from '@/Components/Buttons/Success'
    import Date                 from '@/Components/Inputs/Date'

    const heading = ['Seleccionar', 'Fecha de salida', 'Folio', 'Generadora', 'Transportista', 'Destinatario', 'Folios amparados', 'Fact. Tran.', ' Fact. Dest.', 'Opciones']

    export default {
        components: {
            InertiaLink,
            Inertia,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            SearchSolid,
            EyeSolid,
            EditSolid,
            DocSolid,
            PrintSolid,
            CloseSolid,
            BanSolid,
            ImageOutline,
            TableContainer,
            TableBodyItem,
            TableBodyButtons,
            PrimaryLinkExpanded,
            Pagination,
            Loader,
            DialogModal,
            BtnLight,
            BtnDanger,
            Date,
            BtnSuccess
        },
        props: ['subsidiaries'],
        data() {
            return {
                heading,
                form: {
                    search:                 this.getTableVariables().search,
                    perPage:                this.getTableVariables().perPage,
                    pagination:             this.getTableVariables().pagination,
                    perSubsidiary:          this.getTableVariables().perSubsidiary
                },
                consolidated:               [],
                tableVariables:             null,
                loading:                    true,
                filesUrl:                   (process.env.APP_ENV === 'local' ? process.env.APP_URL : process.env.ASSETS_URL) + '/files/',
                processing:                 false,
                showCancelModal:            false,
                cancelForm: {
                    id:                     null,
                    reason:                 '',
                    errorReason:            false,
                    file:                   null,
                    errorFile:              false
                },
                invoiceForm: {
                    file:                   null,
                    errorFile:              false,
                    ids:                    [],
                    invoice:                ''
                },
                folio:                      null,
                showInvoiceCarrierModal:    false,
                showInvoiceRecipientModal:  false,
                selectedConsolidated:       [],
                disabledCheckbox:           false,
                disabledInvoiceCarrier:     true,
                disabledInvoiceRecipient:   true,
                showFileModal:              false,
                processingInvoice:          false
            }
        },
        methods: {
            openFileModal() {
                this.showFileModal = true
            },
            closeFileModal(val) {
                this.showFileModal = false
            },
            getFile(file) {
                this.invoiceForm.file = file
            },
            getTableVariables() {
                return JSON.parse(window.localStorage.getItem(`${this.$page.url}-table`))
            },
            perPageChanged() {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination  = 1
                    this.form.pagination            = 1
                }
                this.tableVariables.perPage         = this.form.perPage
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
               this.index()
            },
            perSubsidiaryChanged() {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination  = 1
                    this.form.pagination            = 1
                }
                this.tableVariables.perSubsidiary   = this.form.perSubsidiary
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))

               this.index()
            },
            async index() {
                this.loading         = true
                await axios.post(route('api.consolidated.finalize.index'), this.form).then(res => {
                    this.consolidated    = res.data
                    this.loading         = false
                })

                this.reselectSelectedConsolidated()
            },
            search: _.debounce(function () {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination  = 1
                    this.form.pagination            = 1
                }
                this.tableVariables.search          = this.form.search
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))

                this.index()
            }, 300),
            async currentPage(page) {
                this.tableVariables.pagination = page.substring(page.indexOf("=") + 1)
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
                this.form.pagination = page.substring(page.indexOf("=") + 1)
                this.index()
            },
            moment(date) {
                return moment(date).format('DD/MM/YYYY')
            },
            toggleCancelModal(folio, id) {
                this.showCancelModal = !this.showCancelModal
                if (this.showCancelModal) {
                    this.folio = folio
                    this.cancelForm.id = id
                } else {
                    this.folio                  = null
                    this.cancelForm.id          = null
                    this.cancelForm.reason      = null
                    this.cancelForm.errorReason = false
                    this.cancelForm.file        = null
                    this.cancelForm.errorFile   = false
                }
            },
            cancelManifest() {
                if (!this.cancelForm.reason) this.cancelForm.errorReason = true
                if (!this.cancelForm.file)   this.cancelForm.errorFile   = true

                if(this.cancelForm.reason && this.cancelForm.file) {
                    this.processing = true
                    this.$inertia.post(route('api.consolidated.cancelled.cancel'), this.cancelForm, {
                        preserveState: true,
                        preserveScroll: true,
                        onSuccess: page => {
                            this.processing             = false
                            this.showCancelModal        = false
                            this.folio                  = null
                            this.cancelForm.id          = null
                            this.cancelForm.reason      = null
                            this.cancelForm.errorReason = false
                            this.cancelForm.file        = null
                            this.cancelForm.errorFile   = false
                            this.consolidated           = []
                            this.index()
                        }
                    })
                }
            },
            fileInputValue(event) {
                this.cancelForm.file = event.target.files[0];
            },
            fileInputValueInvoice(event) {
                this.invoiceForm.file = event.target.files[0];
            },
            openInvoiceCarrierModal() {
                this.showInvoiceCarrierModal    = true
                this.invoiceForm.invoice        = 'carrier'
            },
            closeInvoiceCarrierModal() {
                this.showInvoiceCarrierModal    = false
                this.invoiceForm.invoice        = ''
            },
            openInvoiceRecipientModal() {
                this.showInvoiceRecipientModal      = true
                this.invoiceForm.invoice            = 'recipient'
            },
            closeInvoiceRecipientModal() {
                this.showInvoiceRecipientModal      = false
                this.invoiceForm.invoice            = ''
            },
            addSelectedConsolidated(consolidated) {
                this.selectedConsolidated.push({id: consolidated.id, folio: consolidated.folio, isNullInvoiceCarrier: consolidated.carrier_invoice ? true : false, isNullInvoiceRecipient: consolidated.recipient_invoice ? true : false})

                this.disabledInvoiceCarrier     = consolidated.carrier_invoice      ? true  : false
                this.disabledInvoiceRecipient   = consolidated.recipient_invoice    ? true  : false
            },
            removeSelectedConsolidated(consolidated) {
                const newSelectedConsolidated    = this.selectedConsolidated.filter(e => {
                    return e.id != consolidated.id
                })
                this.selectedConsolidated        = newSelectedConsolidated

                if (this.selectedConsolidated.length) {
                    this.disabledInvoiceCarrier     = consolidated.carrier_invoice      ? true  : false
                    this.disabledInvoiceRecipient   = consolidated.recipient_invoice    ? true  : false
                } else {
                    this.disabledInvoiceCarrier     = true
                    this.disabledInvoiceRecipient   = true
                }
            },
            changeInput(consolidated, ref) {
                if (this.$refs[`${ref}${consolidated.id}`].checked) {
                    this.addSelectedConsolidated(consolidated, ref)
                } else if (!this.$refs[`${ref}${consolidated.id}`].checked) {
                    this.removeSelectedConsolidated(consolidated, ref)
                }
            },
            reselectSelectedConsolidated() {
                if (this.selectedConsolidated.length) {
                    this.selectedConsolidated.forEach(e => {
                        if (this.$refs[`checkbox_${e.id}`] != null) {
                            this.$refs[`checkbox_${e.id}`].checked = true
                        }
                    })
                }
            },
            addInvoice() {
                if (!this.invoiceForm.file) {
                    this.invoiceForm.errorFile   = true
                } else {
                    this.invoiceForm.errorFile   = false
                }

                if(this.invoiceForm.file) {
                    this.processingInvoice = true
                    this.selectedConsolidated.forEach((e,i) => {
                        this.invoiceForm.ids.push(e.id)
                    })

                    this.$inertia.post(route('api.consolidated.finalize.invoice'), this.invoiceForm, {
                        preserveState:  true,
                        preserveScroll: true,
                        forceFormData:  true,
                        onSuccess: page => {
                            this.processingInvoice          = false
                            this.showInvoiceCarrierModal    = false
                            this.showInvoiceRecipientModal  = false
                            this.invoiceForm.file           = null
                            this.invoiceForm.errorFile      = false
                            this.invoiceForm.ids            = []
                            this.invoiceForm.invoice        = ''
                            this.selectedConsolidated       = []
                            this.index()
                        }
                    })
                }
            }
        },
        beforeCreate: function () {
            if (!window.localStorage.getItem(`${this.$page.url}-table`)) {
                const table = {
                    perPage:        25,
                    pagination:     1,
                    search:         '',
                    perSubsidiary:  1
                }
                window.localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(table))
            }
        },
        mounted() {
            this.tableVariables = JSON.parse(localStorage.getItem(`${this.$page.url}-table`))
            this.index()
        },
        watch: {
            selectedConsolidated: {
                deep: true,
                handler(data) {
                }
            },
            cancelForm: {
                deep: true,
                handler(data) {
                    if (data.reason) {
                        this.cancelForm.errorReason=false
                    }

                    if (data.file) {
                        this.cancelForm.errorFile=false
                    }
                }
            }
        }
    }
</script>