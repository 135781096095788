<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.manifests.open.index')">
                        Manifiestos abiertos
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Crear
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="mx-auto p-4">
            <form @submit.prevent="submit" enctype="multipart/form-data">
                <div class="shadow sm:rounded-md bg-white">

                    <div class="px-6 pt-6 pb 3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="subsidiary"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Sucursal</label>
                                <v-select
                                    :disabled="form.processing"
                                    :selectOnTab="true"
                                    :tabindex="1"
                                    placeholder="Seleccionar una sucursal"
                                    label="descriptive_name"
                                    v-model="form.subsidiary"
                                    :reduce="(option) => option.id"
                                    :options="subsidiaries">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.subsidiary"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.subsidiary }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 py-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

                            <div class="col-span-1">
                                <label
                                    for="folio"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Folio</label>
                                <input
                                    id="folio"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.folio"
                                    @keyup="existence"
                                    @paste="existence"
                                    @cut="existence"
                                    autocomplete="givenfolio"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'curson-default'"
                                    :tabindex="2"
                                    :isRequired="true" />
                                <div
                                    v-if="form.errors.folio"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.folio }}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="typeWaste"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Tipo de residuos</label>
                                <v-select
                                    :disabled="form.processing"
                                    :selectOnTab="true"
                                    :tabindex="3"
                                    placeholder="Seleccionar un tipo de residuo"
                                    label="name"
                                    @option:selected="typeWasteSelected"
                                    v-model="form.typeWaste"
                                    :reduce="(option) => option.name"
                                    :options="typeWastes">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.typeWaste"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.typeWaste }}</div>
                            </div>

                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa generadora</label>
                                <v-select
                                    :disabled="form.processing || !form.typeWaste"
                                    :selectOnTab="true"
                                    :tabindex="4"
                                    placeholder="Seleccionar una empresa generadora"
                                    label="name"
                                    @search="onSearchGenerator"
                                    @option:selected="generatorSelected"
                                    v-model="form.generator"
                                    :reduce="(option) => option.id"
                                    :options="generators">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.generator"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.generator }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="carrier"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa transportista</label>
                                <v-select
                                    :disabled="form.processing || !form.typeWaste"
                                    :selectOnTab="true"
                                    :tabindex="5"
                                    placeholder="Seleccionar una empresa transportista"
                                    label="name"
                                    @search="onSearchCarrier"
                                    v-model="form.carrier"
                                    :reduce="(option) => option.id"
                                    :options="carriers">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.carrier"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.carrier }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="recipient"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa destinataria</label>
                                <v-select
                                    :disabled="form.processing || !form.typeWaste"
                                    :selectOnTab="true"
                                    :tabindex="6"
                                    placeholder="Seleccionar una empresa destinataria"
                                    label="name"
                                    @search="onSearchRecipient"
                                    v-model="form.recipient"
                                    :reduce="(option) => option.id"
                                    :options="recipients">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.recipient"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.recipient }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 py-6">
                        <h4 class="font-semibold mb-2">Residuo #1</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteOneId"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.typeWaste"
                                    :selectOnTab="true"
                                    :tabindex="7"
                                    placeholder="Seleccionar un nombre de residuo"
                                    label="name"
                                    @search="onSearchWasteOne"
                                    @option:selected="onWasteOneSelected"
                                    v-model="form.wasteOneId"
                                    :reduce="(option) => option.id"
                                    :options="wastesOne">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.wasteOneId"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.wasteOneId }}</div>
                                <div
                                    v-if="maxLengthWasteOne"
                                    class="text-orange-600 text-xs mt-1">{{ maxLengthWasteLabel }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 py-6">
                        <h4 class="font-semibold mb-2">Residuo #2</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteTwo"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.typeWaste"
                                    :selectOnTab="true"
                                    :tabindex="8"
                                    placeholder="Seleccionar un nombre de residuo"
                                    label="name"
                                    @search="onSearchWasteTwo"
                                    @option:selected="onWasteTwoSelected"
                                    v-model="form.wasteTwoId"
                                    :reduce="(option) => option.id"
                                    :options="wastesTwo">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.wasteTwoId"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.wasteTwoId }}</div>
                                <div
                                    v-if="maxLengthWasteTwo"
                                    class="text-orange-600 text-xs mt-1">{{ maxLengthWasteLabel }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 py-6">
                        <h4 class="font-semibold mb-2">Residuo #3</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteThree"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.typeWaste"
                                    :selectOnTab="true"
                                    :tabindex="9"
                                    placeholder="Seleccionar un nombre de residuo"
                                    label="name"
                                    @search="onSearchWasteThree"
                                    @option:selected="onWasteThreeSelected"
                                    v-model="form.wasteThreeId"
                                    :reduce="(option) => option.id"
                                    :options="wastesThree">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.wasteThreeId"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.wasteThreeId }}</div>
                                <div
                                    v-if="maxLengthWasteThree"
                                    class="text-orange-600 text-xs mt-1">{{ maxLengthWasteLabel }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 py-6">
                        <h4 class="font-semibold mb-2">Residuo #4</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteFour"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <v-select
                                    :disabled="form.processing || !form.generator || !form.typeWaste"
                                    :selectOnTab="true"
                                    :tabindex="10"
                                    placeholder="Seleccionar un nombre de residuo"
                                    label="name"
                                    @search="onSearchWasteFour"
                                    v-model="form.wasteFourId"
                                    :reduce="(option) => option.id"
                                    :options="wastesFour">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                                <div
                                    v-if="form.errors.wasteFourId"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.wasteFourId }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="pt-6 text-right space-x-4">
                    <link-light :href="route('dashboard.manifests.open.index')" :disabled="form.processing">Cancelar</link-light>
                    <btn-success @click="form.onlySave=true" type="submit" :disabled="form.processing">
                        <spin class="text-white" v-show="form.processing && form.onlySave == true"></spin>
                        Agregar
                    </btn-success>
                    <btn-primary @click="form.onlySave=false" type="submit" :disabled="form.processing">
                        <spin class="text-white" v-show="form.processing && form.onlySave == false"></spin>
                        Imprimir
                    </btn-primary>
                </div>

            </form>
        </div>
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnSuccess       from '@/Components/Buttons/Success'
    import BtnPrimary       from '@/Components/Buttons/Primary'
    import Spin             from '@/Components/Spin'
    import vSelect          from 'vue-select'
    import 'vue-select/dist/vue-select.css'


    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnSuccess,
            BtnPrimary,
            Spin,
            vSelect
        },
        props: ['subsidiaries'],
        data() {
            return {
                form: this.$inertia.form({
                    onlySave:                   false,
                    subsidiary:                 null,
                    folio:                      null,
                    typeWaste:                  null,
                    generator:                  null,
                    carrier:                    null,
                    recipient:                  null,
                    wasteOneId:                 null,
                    wasteTwoId:                 null,
                    wasteThreeId:               null,
                    wasteFourId:                null,
                }),
                typeWastes:                     [{id: '1', name: 'Peligrosos'}, {id: '2', name: 'No peligrosos'}],
                carriers:                       [],
                recipients:                     [],
                generators:                     [],
                wastesOne:                      [],
                wastesTwo:                      [],
                wastesThree:                    [],
                wastesFour:                     [],
                maxLengthWasteOne:              false,
                maxLengthWasteOneName:          '',
                maxLengthWasteTwo:              false,
                maxLengthWasteTwoName:          '',
                maxLengthWasteThree:            false,
                maxLengthWasteThreeName:        '',
                maxLengthWasteLabel:            'El nombre de residuo es muy largo, ponlo al final.',
            }
        },
        methods: {
            submit() {
                if (!this.maxLengthWasteOne && !this.maxLengthWasteTwo && !this.maxLengthWasteThree) {
                    this.form.post(route('dashboard.manifests.open.store'), this.form, {preserveState: false, preserveScroll: false, forceFormData: true})
                }
            },
            existence: _.debounce(async function () {
                const response = await axios.post(route('api.manifests.open.existence'), this.form)
                if (response.data.message) {
                    this.form.errors.folio = response.data.message
                } else {
                    this.form.errors.folio = ''
                }
            }, 300),
            typeWasteSelected(value) {
                this.form.generator     = null
                this.generators         = []
                this.form.carrier       = null
                this.carriers           = []
                this.form.recipient     = null
                this.recipients         = []
                this.form.wasteOneId    = null
                this.wastesOne          = []
                this.form.wasteTwoId    = null
                this.wastesTwo          = []
                this.form.wasteThreeId  = null
                this.wastesThree        = []
                this.form.wasteFourId   = null
                this.wastesFour         = []
            },
            onSearchGenerator(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchGenerator(loading, search, this.form.typeWaste, this)
                }
            },
            searchGenerator: _.debounce((loading, search, typeWaste, vm) => {
                axios.post(route('api.clients.search'), {'search': search, 'typeWaste': typeWaste}).then(res => {
                    vm.generators = res.data.length ? res.data[0] : []
                    loading(false)
                })
            }, 350),
            generatorSelected(value) {
                this.form.wasteOneId    = null
                this.wastesOne          = []
                this.form.wasteTwoId    = null
                this.wastesTwo          = []
                this.form.wasteThreeId  = null
                this.wastesThree        = []
                this.form.wasteFourId   = null
                this.wastesFour         = []
            },
            onSearchCarrier(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchCarrier(loading, search, this.form.typeWaste, this)
                }
            },
            searchCarrier: _.debounce((loading, search, typeWaste, vm) => {
                axios.post(route('api.carriers.search'), {'search': search, 'typeWaste': typeWaste}).then(res => {
                    vm.carriers = res.data.length ? res.data[0] : []
                    loading(false)
                })
            }, 350),
            onSearchRecipient(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchRecipient(loading, search, this.form.typeWaste, this)
                }
            },
            searchRecipient: _.debounce((loading, search, typeWaste, vm) => {
                axios.post(route('api.recipients.search'), {'search': search, 'typeWaste': typeWaste}).then(res => {
                    vm.recipients = res.data.length ? res.data[0] : []
                    loading(false)
                })
            }, 350),
            onSearchWasteOne(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchWaste(loading, search, this.form.generator, this.form.typeWaste, this, 1)
                }
            },
            onSearchWasteTwo(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchWaste(loading, search, this.form.generator, this.form.typeWaste, this, 2)
                }
            },
            onSearchWasteThree(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchWaste(loading, search, this.form.generator, this.form.typeWaste, this, 3)
                }
            },
            onSearchWasteFour(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchWaste(loading, search, this.form.generator, this.form.typeWaste, this, 4)
                }
            },
            searchWaste: _.debounce((loading, search, generator, typeWaste, vm, waste) => {
                axios.post(route('api.wastes.search'), {'search': search, 'generator': generator, 'typeWaste': typeWaste}).then(res => {
                    if (waste == 1) {
                        vm.wastesOne = res.data.length ? res.data[0] : []
                    } else if (waste == 2) {
                        vm.wastesTwo = res.data.length ? res.data[0] : []
                    } else if (waste == 3) {
                        vm.wastesThree = res.data.length ? res.data[0] : []
                    } else if (waste == 4) {
                        vm.wastesFour = res.data.length ? res.data[0] : []
                    }
                    loading(false)
                })
            }, 350),
            onWasteOneSelected(value) {
                this.maxLengthWasteOneName = value ? value.name : null
            },
            onWasteTwoSelected(value) {
                this.maxLengthWasteTwoName = value ? value.name : null
            },
            onWasteThreeSelected(value) {
                this.maxLengthWasteThreeName = value ? value.name : null
            }
        },
        watch: {
            form: {
                deep: true,
                handler(data) {
                    if (data.wasteOneId) {
                        if (this.maxLengthWasteOneName.length >= 336 && data.wasteTwoId) {
                            this.maxLengthWasteOne = true
                        } else {
                            this.maxLengthWasteOne = false
                        }
                    } else {
                        this.maxLengthWasteOne = false
                    }

                    if (data.wasteTwoId) {
                        if (this.maxLengthWasteTwoName.length >= 336 && data.wasteThreeId) {
                            this.maxLengthWasteTwo = true
                        } else {
                            this.maxLengthWasteTwo = false
                        }
                    } else {
                        this.maxLengthWasteTwo = false
                    }

                    if (data.wasteThreeId) {
                        if (this.maxLengthWasteThreeName.length >= 336 && data.wasteFourId) {
                            this.maxLengthWasteThree = true
                        } else {
                            this.maxLengthWasteThree = false
                        }
                    } else {
                        this.maxLengthWasteThree = false
                    }
                }
            }
        }
    }
</script>