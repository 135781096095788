<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.clients.index')">
                        Clientes
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    {{client?.data?.name}}
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="mx-auto p-4">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Información del cliente
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Datos generales.
                    </p>
                </div>
                <div class="border-t border-gray-200">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Nombre
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{client.data.name}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Código de cliente
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{client?.client_num ? client.client_num : 'Sin código de cliente'}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Teléfono
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{client.data?.phone ? client.data.phone : 'Sin  teléfono'}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Fax
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{client.data?.fax ? client.data.fax : 'Sin fax'}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                R.F.C.
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{client.data?.rfc ? client.data.rfc : 'Sin rfc'}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Dirección
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{client.data.address}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Nº Interior
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{client.data?.num_int ? client.data.num_int : 'Sin nº interior'}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Nº Exterior
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{client.data?.num_ext ? client.data.num_ext : 'Sin nº exterior'}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Estado
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{state.name}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Ciudad
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{city.name}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Código postal
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{client.data?.postal_code ? client.data.postal_code : 'Sin código postal'}}
                            </dd>
                        </div>

                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Tipo de residuos
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{client.data.type_waste}}
                            </dd>
                        </div>

                        <div v-if="client.data.type_waste == 'Peligrosos' || client.data.type_waste == 'Peligrosos y no peligrosos'">
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    N.R.A.
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{client.data.nra ? client.data.nra : 'Sin n.r.a.'}}
                                </dd>
                            </div>
                        </div>

                        <div v-if="client.data.type_waste == 'No peligrosos' || client.data.type_waste == 'Peligrosos y no peligrosos'">
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    N.A.R.E.
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{client.data.nare ? client.data.nare : 'Sin n.a.r.e.'}}
                                </dd>
                            </div>
                        </div>

                    </dl>
                </div>
            </div>

            <div v-for="(representative, index) in client.representatives" :key="index" class="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Información del representante #{{index+1}}
                    </h3>
                </div>
                <div class="border-t border-gray-200">
                    <dl>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Nombre del representante
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{representative?.name ? representative.name : 'Sin nombre'}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Correo electrónico
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{representative?.email ? representative.email : 'Sin correo electrónico'}}
                            </dd>
                        </div>
                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">
                                Móvil
                            </dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {{representative?.mobile ? representative.mobile : 'Sin  móvil'}}
                            </dd>
                        </div>

                    </dl>
                </div>
            </div>

            <div v-if="client.data.type_waste == 'Peligrosos' || client.data.type_waste == 'Peligrosos y no peligrosos'" class="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Residuos peligrosos
                    </h3>
                </div>
                <div class="border-t border-gray-200">
                    <div v-if="dangerousWastes.length">
                        <div v-for="(waste, index) in dangerousWastes" :key="index" class="bg-gray-50 px-4 py-5 sm:px-6 border-b border-gray-200">
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0">
                                <a target="_blank" class="text-orange-600 hover:text-orange-400" :href="route('dashboard.wastes.show', waste.id)">{{waste.name}}</a>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <div class="bg-gray-50 px-4 py-5 sm:px-6">
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0">
                                Sin residuos
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="client.data.type_waste == 'No peligrosos' || client.data.type_waste == 'Peligrosos y no peligrosos'" class="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Residuos no peligrosos
                    </h3>
                </div>
                <div class="border-t border-gray-200">
                    <div v-if="noDangerousWastes.length">
                        <div v-for="(waste, index) in noDangerousWastes" :key="index" class="bg-gray-50 px-4 py-5 sm:px-6 border-b border-gray-200">
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0">
                                <a target="_blank" class="text-orange-600 hover:text-orange-400" :href="route('dashboard.wastes.show', waste.id)">{{waste.name}}</a>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <div class="bg-gray-50 px-4 py-5 sm:px-6">
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0">
                                Sin residuos
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import moment           from 'moment'

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
        },
        props: ['client', 'state', 'city', 'dangerousWastes', 'noDangerousWastes'],
        methods: {
            moment(date) {
                return moment(date).format('DD/MM/YYYY')
            }
        }
    }
</script>