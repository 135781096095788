<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Clientes
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="grid grid-cols-1 gap-4 p-4">
            <div class="col-span-1">
                <div class="flex flex-col md:flex-row justify-between">
                    <div class="flex flex-row">
                        <div class="flex flex-row w-32 sm:w-24">
                            <select
                                class="h-full w-full sm:rounded-l appearance-none bg-white text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none ring-0 focus:ring-transparent border-gray-200 focus:border-gray-200"
                                v-model="form.perPage"
                                @change="perPageChanged">
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                        <div class="flex md:w-56 w-full">
                            <input placeholder="Buscar" class="appearance-none sm:rounded-r border-gray-200 border-t border-b border-r px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" v-model="form.search" @keyup="search" />
                        </div>
                    </div>

                    <div class="mt-4 md:mt-0">
                        <primary-link-expanded :href="route('dashboard.clients.create')">agregar</primary-link-expanded>
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="bg-white overflow-hidden shadow sm:rounded">
                    <table-container :heading="heading">
                        <tbody>
                            <tr v-show="loading">
                                <td :colspan="heading.length" class="h-96">
                                    <loader width="100" height="100"></loader>
                                </td>
                            </tr>

                            <tr v-if="clients?.data?.length == 0 && !loading" class="text-center">
                                <table-body-item :colspan="heading.length">
                                    No se encontraron resultados.
                                </table-body-item>
                            </tr>

                            <tr v-else-if="clients?.data?.length > 0 && !loading" v-for="(client, index) in clients?.data" :key="index">
                                <table-body-item>
                                    {{client.client_num ? client.client_num : 'S/N.C.'}}
                                </table-body-item>
                                <table-body-item>
                                    {{client.data?.name}}
                                </table-body-item>
                                <table-body-item>
                                    {{client.data?.address}}
                                </table-body-item>
                                <table-body-item>
                                    {{client.data?.nra ? client.data.nra : 'S/N.R.A'}}
                                </table-body-item>
                                <table-body-item>
                                    {{client.data?.nare ? client.data.nare : 'S/N.A.R.E'}}
                                </table-body-item>
                                <table-body-item>
                                    {{client.data?.TypeCompanyLabel}}
                                </table-body-item>
                                <td class="px-6 py-4">
                                    <table-body-buttons>
                                        <button :ref="`enabled${client.id}`" @click="enabled(client.id)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 transition duration-500 ease" 
                                            :class="client.enabled ? 'text-green-500 hover:text-green-400' : 'text-red-500 hover:text-red-400'">
                                            <CircleLoading class="text-gray-800 animate-spin w-4 h-4 mx-0.5 hidden" :ref="`enabling${client.id}`"></CircleLoading>
                                            <lock-open-solid class="h-5 w-5" :class="{'hidden': !client.enabled, 'visible': client.enabled}" :ref="`lockopen${client.id}`"></lock-open-solid>
                                            <lock-close-solid class="h-5 w-5" :class="{'hidden': client.enabled, 'visible': !client.enabled}" :ref="`lockclose${client.id}`"></lock-close-solid>
                                        </button>
                                        <inertia-link :href="route('dashboard.clients.show', client.id)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-cyan-500 hover:text-cyan-400 transition duration-500 ease">
                                            <eye-solid class="h-5 w-5"></eye-solid>
                                        </inertia-link>

                                        <inertia-link :href="route('dashboard.clients.edit', client.id)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-yellow-500 hover:text-yellow-400 transition duration-500 ease">
                                            <edit-solid class="h-5 w-5 "></edit-solid>
                                        </inertia-link>

                                        <button @click="toggleShowDeleteModal(client.id, client.data?.name, client.manifests)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-red-500 hover:text-red-400 transition duration-500 ease">
                                            <trash-solid class="h-5 w-5"></trash-solid>
                                        </button>
                                    </table-body-buttons>
                                </td>
                            </tr>
                        </tbody>
                    </table-container>

                    <div class="py-4 px-6">
                    <pagination :items="clients" @nextPage="currentPage" @prevPage="currentPage" @link="currentPage" />
                </div>

                </div>
            </div>
        </div>

        <dialog-modal :show="showDeleteModal">
            <template #content>
                <span v-if="manifestsLength==0">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">¿Estás seguro de que deseas eliminar "{{client}}"?</h3>
                    <p class="text-sm text-gray-500">Esta acción no se puede deshacer.</p>
                </span>

                <span v-else>
                    <h3 class="text-center text-lg leading-6 font-medium text-gray-900 mb-2">
                        El destinatario "{{client}}" no puede ser eliminado porque esta relacionado con {{manifestsLength}} manifiestos.
                    </h3>
                </span>
            </template>
            <template #footer>
                <div class="space-x-4">
                    <btn-light @click="toggleShowDeleteModal" :disabled="processing">Cancelar</btn-light>
                    <span v-if="manifestsLength==0">
                        <btn-danger @click="destroy" :disabled="processing">Eliminar</btn-danger>
                    </span>
                </div>
            </template>
        </dialog-modal>

    </app-layout>
</template>

<script>
    import { InertiaLink }      from '@inertiajs/inertia-vue3'
    import { Inertia }          from '@inertiajs/inertia'
    import AppLayout            from '@/Layouts/AppLayout.vue'
    import BreadcumbNav         from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem        from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid            from '@/Components/Icons/HeroIcons/Solid/Home'
    import SearchSolid          from '@/Components/Icons/HeroIcons/Solid/Search'
    import TrashSolid           from '@/Components/Icons/HeroIcons/Solid/Trash'
    import EyeSolid             from '@/Components/Icons/HeroIcons/Solid/Eye'
    import EditSolid            from '@/Components/Icons/HeroIcons/Solid/Edit'
    import LockCloseSolid       from '@/Components/Icons/HeroIcons/Solid/LockClose'
    import LockOpenSolid        from '@/Components/Icons/HeroIcons/Solid/LockOpen'
    import TableContainer       from '@/Components/Tables/TableContainer'
    import TableBodyItem        from '@/Components/Tables/TableBodyItem'
    import TableBodyButtons     from '@/Components/Tables/TableBodyButtons'
    import PrimaryLinkExpanded  from '@/Components/Buttons/Expanded/Primary'
    import Pagination           from '@/Components/Pagination/Pagination'
    import Loader               from '@/Components/Loader'
    import DialogModal          from '@/Components/DialogModal'
    import BtnLight             from '@/Components/Buttons/Light'
    import BtnDanger            from '@/Components/Buttons/Danger'
    import CircleLoading        from '@/Components/Icons/CircleLoading'

    export default {
        components: {
            InertiaLink,
            Inertia,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            SearchSolid,
            TrashSolid,
            EyeSolid,
            EditSolid,
            LockCloseSolid,
            LockOpenSolid,
            TableContainer,
            TableBodyItem,
            TableBodyButtons,
            PrimaryLinkExpanded,
            Pagination,
            Loader,
            DialogModal,
            BtnLight,
            BtnDanger,
            CircleLoading
        },
        data() {
            return {
                heading:        ['Código de cliente', 'Nombre', 'Dirección', 'N.R.A', 'N.A.R.E', 'Tipo de empresa', 'Opciones'],
                form: {
                    search:     this.getTableVariables().search,
                    perPage:    this.getTableVariables().perPage,
                    pagination: this.getTableVariables().pagination,
                },
                clients: [],
                tableVariables:     null,
                showDeleteModal:    false,
                id:                 null,
                client:             null,
                manifestsLength:    null,
                loading:            true,
                processing:         false,
            }
        },
        methods: {
            getTableVariables() {
                return JSON.parse(window.localStorage.getItem(`${this.$page.url}-table`))
            },
            perPageChanged() {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination  = 1
                    this.form.pagination            = 1
                }
                this.tableVariables.perPage = this.form.perPage
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
                this.index()
            },
            async index() {
                this.loading     = true
                const clients    = await axios.post(route('api.clients.index'), this.form)
                this.clients     = clients.data
                this.loading     = false
            },
            async enabled(id) {
                this.$refs[`lockopen${id}`].hidden()
                this.$refs[`lockclose${id}`].hidden()
                this.$refs[`enabling${id}`].visible()

                const data = await axios.post(route('api.clients.enabled'), {id: id})

                if (data.data.isEnabled == true) {
                    this.$refs[`enabled${id}`].classList.remove('text-red-500')
                    this.$refs[`enabled${id}`].classList.remove('hover:text-red-400')
                    this.$refs[`enabled${id}`].classList.add('text-green-500')
                    this.$refs[`enabled${id}`].classList.add('hover:text-green-400')

                    this.$refs[`lockopen${id}`].visible()
                } else {
                    this.$refs[`enabled${id}`].classList.remove('text-green-500')
                    this.$refs[`enabled${id}`].classList.remove('hover:text-green-400')
                    this.$refs[`enabled${id}`].classList.add('text-red-500')
                    this.$refs[`enabled${id}`].classList.add('hover:text-red-400')
                    
                    this.$refs[`lockclose${id}`].visible()
                }

                this.$refs[`enabling${id}`].hidden()
            },
            search: _.debounce(function () {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination  = 1
                    this.form.pagination            = 1
                }
                this.tableVariables.search = this.form.search
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
                this.index()
            }, 300),
            async currentPage(page) {
                this.tableVariables.pagination = page.substring(page.indexOf("=") + 1)
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
                this.form.pagination = page.substring(page.indexOf("=") + 1)
                this.index()
            },
            toggleShowDeleteModal(id, client, manifests) {
                this.showDeleteModal = !this.showDeleteModal
                if (this.showDeleteModal) {
                    this.id                 = id
                    this.client             = client
                    this.manifestsLength    = manifests.length
                }
            },
            destroy() {
                this.processing = true
                this.$inertia.delete(route('dashboard.clients.destroy', this.id), {
                    preserveState: true,
                    preserveScroll: true,
                    onSuccess: page => {
                        this.processing         = false
                        this.showDeleteModal    = false
                        this.id                 = null
                        this.client             = null
                        this.manifestsLength    = null
                        this.clients            = []
                        this.index()
                    }
                }, {})
            }
        },
        beforeCreate: function () {
            if (!window.localStorage.getItem(`${this.$page.url}-table`)) {
                const table = {
                    perPage: 25,
                    pagination: 1,
                    search: ''
                }
                window.localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(table))
            }
        },
        mounted() {
            this.tableVariables = JSON.parse(localStorage.getItem(`${this.$page.url}-table`))
            this.index()
        }
    }
</script>