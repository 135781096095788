<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Facturas canceladas
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="grid grid-cols-1 gap-4 p-4">
            <div class="col-span-1">
                <div class="flex flex-col md:flex-row justify-between">
                    <div class="flex flex-row">
                        <div class="flex flex-row w-32 sm:w-24">
                            <select
                                class="h-full w-full sm:rounded-l appearance-none bg-white text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none ring-0 focus:ring-transparent border-gray-200 focus:border-gray-200"
                                v-model="form.perPage"
                                @change="perPageChanged">
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                        <div class="flex md:w-56 w-full">
                            <input placeholder="Buscar" class="appearance-none sm:rounded-r border-gray-200 border-t border-b border-r px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" v-model="form.search" @keyup="search" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="bg-white overflow-hidden shadow sm:rounded">
                    <table-container :heading="heading">
                        <tbody>
                            <tr v-show="loading">
                                <td :colspan="heading.length" class="h-96">
                                    <loader width="100" height="100"></loader>
                                </td>
                            </tr>

                            <tr v-if="invoices?.data?.length == 0 && !loading" class="text-center">
                                <table-body-item :colspan="heading.length">
                                    No se encontraron resultados.
                                </table-body-item>
                            </tr>

                            <tr v-else-if="invoices?.data?.length > 0 && !loading" v-for="(invoice, index) in invoices.data" :key="index">
                                <table-body-item>
                                    {{invoice.uuid}}
                                </table-body-item>
                                <table-body-item>
                                    {{invoice.manifest.folio}}
                                </table-body-item>
                                <table-body-item>
                                    {{invoice.manifest?.client.data.name}}
                                </table-body-item>
                                <table-body-item>
                                    {{invoice.business_name}}
                                </table-body-item>
                                <table-body-item>
                                    {{invoice.TypeAgreementLabel}}
                                </table-body-item>
                                <table-body-item>
                                    {{invoice.reason}}
                                </table-body-item>
                                <td class="px-6 py-4">
                                    <table-body-buttons>
                                        <button @click="generatePDF(invoice)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-green-500 hover:text-green-400 transition duration-500 ease">
                                            <save-outline class="h-5 w-5"></save-outline>
                                        </button>

                                        <a v-if="invoice.file" :href="`${filesUrl}${invoice.file}`" target="_blank"  class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-orange-500 hover:text-orange-400 transition duration-500 ease">
                                            <doc-solid class="h-5 w-5"></doc-solid>
                                        </a>

                                        <button v-else class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-orange-500 hover:text-orange-400 transition duration-500 ease">
                                            <close-solid class="h-5 w-5"></close-solid>
                                        </button>

                                        <inertia-link :href="route('dashboard.invoices.cancelled.show', invoice.id)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-cyan-500 hover:text-cyan-400 transition duration-500 ease">
                                            <eye-solid class="h-5 w-5"></eye-solid>
                                        </inertia-link>

                                        <button @click="toggleRestoreModal(invoice)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-green-500 hover:text-green-400 transition duration-500 ease">
                                            <reply-solid class="h-5 w-5"></reply-solid>
                                        </button>
                                    </table-body-buttons>
                                </td>
                            </tr>

                        </tbody>
                    </table-container>

                    <div class="py-4 px-6">
                    <pagination :items="invoices" @nextPage="currentPage" @prevPage="currentPage" @link="currentPage" />
                </div>

                </div>
            </div>
        </div>

        <dialog-modal :show="showRestoreModal">
            <template #title>
                <div class="text-2xl leading-6 font-bold text-gray-900 uppercase">Restablecer factura {{restoreForm.uuid}}</div>
            </template>
            <template #content>
                <div class="pb-3">
                    <h4>¿Estás seguro de que deseas restablecer esta factura?</h4>
                </div>
                <!-- <div v-else class="pb-3">
                    <div class="grid grid-cols-1">
                        <label class="mb-1 block text-sm font-semibold text-gray-700">Adjuntar manifiesto</label>
                        <div class="my-1 flex items-center justify-center w-full">
                            <label class="flex flex-col border-4 border-dashed w-full h-32 hover:border-orange-300 group cursor-pointer">
                                <div class="flex flex-col items-center justify-center pt-7">
                                    <image-outline class="w-10 h-10 text-gray-400 group-hover:text-orange-400 "></image-outline>

                                    <p class="lowercase text-sm text-gray-400 group-hover:text-orange-400 pt-1 tracking-wider">
                                        <span v-if="restoreForm.file">{{restoreForm.file.name}}</span>
                                        <span v-else>Select a photo</span>
                                    </p>
                                </div>
                                <input type="file" :disabled="restoreForm.processing" @change="fileInputValue" class="hidden" accept="application/pdf" />
                            </label>
                        </div>
                        <div
                            v-if="restoreForm.errorFile"
                            class="text-red-500 text-xs font-semibold mt-1">El campo adjuntar manifiesto es obligatorio.</div>
                    </div>
                </div> -->
                <div class="text-sm text-medium text-gray-500">Esta acción se puede deshacer.</div>
            </template>
            <template #footer>
                <div class="space-x-4">
                    <btn-light @click="toggleRestoreModal" :disabled="restoreForm.processing">Cancelar</btn-light>
                    <btn-danger @click="restoreInvoice" :disabled="restoreForm.processing">Aceptar</btn-danger>
                </div>
            </template>
        </dialog-modal>

    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import { Inertia }      from '@inertiajs/inertia'
    import moment           from 'moment';
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import SearchSolid      from '@/Components/Icons/HeroIcons/Solid/Search'
    import DocSolid         from '@/Components/Icons/HeroIcons/Solid/Doc'
    import CloseSolid       from '@/Components/Icons/HeroIcons/Solid/Close'
    import EyeSolid         from '@/Components/Icons/HeroIcons/Solid/Eye'
    import EditSolid        from '@/Components/Icons/HeroIcons/Solid/Edit'
    import ReplySolid       from '@/Components/Icons/HeroIcons/Solid/Reply'
    import RefreshSolid     from '@/Components/Icons/HeroIcons/Solid/Refresh'
    import SaveOutline      from '@/Components/Icons/HeroIcons/Outline/Save'
    import ImageOutline     from '@/Components/Icons/HeroIcons/Outline/Image'
    import TableContainer   from '@/Components/Tables/TableContainer'
    import TableBodyItem    from '@/Components/Tables/TableBodyItem'
    import TableBodyButtons from '@/Components/Tables/TableBodyButtons'
    import Pagination       from '@/Components/Pagination/Pagination'
    import Loader           from '@/Components/Loader'
    import DialogModal      from '@/Components/DialogModal'
    import BtnLight         from '@/Components/Buttons/Light'
    import BtnDanger        from '@/Components/Buttons/Danger'
    import jsPDF            from 'jspdf'

    const heading = ['UUID', 'Folio', 'Generadora', 'Razon social', 'Tipo de contrato', 'Razón de cancelación', 'Opciones']

    export default {
        components: {
            InertiaLink,
            Inertia,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            SearchSolid,
            EyeSolid,
            EditSolid,
            DocSolid,
            CloseSolid,
            ReplySolid,
            RefreshSolid,
            ImageOutline,
            TableContainer,
            TableBodyItem,
            TableBodyButtons,
            Pagination,
            Loader,
            DialogModal,
            BtnLight,
            BtnDanger,
            SaveOutline
        },
        data() {
            return {
                heading,
                form: {
                    search:     this.getTableVariables().search,
                    perPage:    this.getTableVariables().perPage,
                    pagination: this.getTableVariables().pagination,
                },
                invoices:          [],
                tableVariables:     null,
                loading:            true,
                filesUrl:           (process.env.APP_ENV === 'local' ? process.env.APP_URL : process.env.ASSETS_URL) + '/files/',
                logo:               process.env.APP_URL + '/storage/brand/imagotipo.png',
                showRestoreModal:    false,
                restoreForm: {
                    id:             null,
                    uuid:           null,
                    file:           null,
                    errorFile:      false,
                    hasfile:        false
                }
            }
        },
        methods: {
            getTableVariables() {
                return JSON.parse(window.localStorage.getItem(`${this.$page.url}-table`))
            },
            perPageChanged() {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination  = 1
                    this.form.pagination            = 1
                }
                this.tableVariables.perPage = this.form.perPage
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))

               this.index()
            },
            async index() {
                this.loading      = true
                const invoices    = await axios.post(route('api.invoices.cancelled.index'), this.form)
                this.invoices     = invoices.data
                this.loading      = false
            },
            search: _.debounce(function () {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination  = 1
                    this.form.pagination            = 1
                }
                this.tableVariables.search = this.form.search
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
                this.index()
            }, 300),
            async currentPage(page) {
                this.tableVariables.pagination = page.substring(page.indexOf("=") + 1)
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
                this.form.pagination = page.substring(page.indexOf("=") + 1)
                this.index()
            },
            toggleRestoreModal(invoice) {
                this.showRestoreModal = !this.showRestoreModal
                if (this.showRestoreModal) {
                    this.restoreForm.id             = invoice.id
                    this.restoreForm.uuid           = invoice.uuid
                    //this.restoreForm.hasfile        = invoice.file ? true : false
                } else {
                    this.restoreForm.id             = null
                    this.restoreForm.uuid           = null
                    // this.restoreForm.file           = null
                    // this.restoreForm.errorFile      = false
                    // this.restoreForm.hasfile        = false
                }
            },
            restoreInvoice() {
                // if (this.restoreForm.hasfile) {
                //     if (this.restoreForm.file == null) this.restoreForm.errorFile = true
                //     if (!this.restoreForm.errorFile) {
                //         this.sendRestoreForm()
                //     }
                // } else {
                    this.sendRestoreForm()
                // }
            },
            sendRestoreForm() {
                this.$inertia.post(route('dashboard.invoices.cancelled.restore'), this.restoreForm, {
                    preserveState:  true,
                    preserveScroll: true,
                    onSuccess: page => {
                        this.showRestoreModal           = false
                        this.restoreForm.id             = null
                        this.restoreForm.uuid           = null
                        // this.restoreForm.file           = null
                        // this.restoreForm.errorFile      = false
                        // this.restoreForm.hasfile        = false
                        this.invoices                   = []
                        this.index()
                    }
                })
            },
            // fileInputValue(event) {
            //     this.restoreForm.file = event.target.files[0];
            // },
            generatePDF(invoice) {
                const doc   = new jsPDF()
                var img     = new Image()
                img.src     = this.logo
                doc.addImage(img, 'png', 15, 10, 50, 28)
                doc.setFontSize(14)
                doc.text(`FECHA PREFACTURA: 24/01/2022`, 80, 16)
                doc.text(`PREFACTURA: ${invoice.uuid}`, 80, 23)
                doc.text(`TIPO DE SERVICIO: ${invoice.TypeAgreementLabel}`, 80, 30)

                doc.line(15, 46, 195, 46)

                doc.setFontSize(12)
                doc.text(`MANIFIESTO`, 15, 60)
                doc.setFontSize(14)
                doc.text(`${invoice.manifest.folio}`, 15, 66)
                doc.setFontSize(12)
                doc.text(`CORREO ELECTRÓNICO`, 80, 60)
                doc.setFontSize(14)
                doc.text(`${invoice.email}`, 80, 66)
                doc.setFontSize(12)
                doc.text(`RAZÓN SOCIAL`, 15, 74)
                doc.setFontSize(14)
                doc.text(`${invoice.business_name}`, 15, 80)

                doc.setFontSize(12)
                doc.text(`SERVICIO DE RECOLECCIÓN:`, 15, 90)
                doc.setFontSize(14)
                doc.text(`$${invoice.base_price}`, 120, 90)

                if (invoice.additional_quantity) {
                    doc.setFontSize(12)
                    doc.text(`COSTO POR KILO EXTRA:`, 15, 96)
                    doc.setFontSize(14)
                    doc.text(`$${invoice.additional_price}`, 120, 96)
                    doc.setFontSize(12)
                    doc.text(`KILOS EXTRAS:`, 15, 102)
                    doc.setFontSize(14)
                    doc.text(`${invoice.additional_quantity}`, 120, 102)

                    const totalAdditionalPrice = invoice.additional_price * invoice.additional_quantity

                    doc.setFontSize(12)
                    doc.text(`TOTAL POR KILOS EXTRAS:`, 15, 108)
                    doc.setFontSize(14)
                    doc.text(`$${totalAdditionalPrice}`, 120, 108)
                    doc.setFontSize(12)
                    doc.text(`SUBTOTAL:`, 15, 114)
                    doc.setFontSize(14)
                    doc.text(`$${invoice.subtotal}`, 120, 114)

                    if (invoice.vat_8) {
                        doc.setFontSize(12)
                        doc.text(`I.V.A. 8%:`, 15, 120)
                        doc.setFontSize(14)
                        doc.text(`$${invoice.vat_8_value}`, 120, 120)

                        if (invoice.vat_16) {
                            doc.setFontSize(12)
                            doc.text(`I.V.A. 16%:`, 15, 126)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.vat_16_value}`, 120, 126)
                            doc.setFontSize(12)
                            doc.text(`TOTAL:`, 15, 132)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.total}`, 120, 132)
                        } else {
                            doc.setFontSize(12)
                            doc.text(`TOTAL:`, 15, 126)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.total}`, 120, 126)
                        }

                    } else {

                        if (invoice.vat_16) {
                            doc.setFontSize(12)
                            doc.text(`I.V.A. 16%:`, 15, 120)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.vat_16_value}`, 120, 120)
                            doc.setFontSize(12)
                            doc.text(`TOTAL:`, 15, 126)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.total}`, 120, 126)
                        }
                    }


                } else {
                    doc.setFontSize(12)
                    doc.text(`SUBTOTAL:`, 15, 96)
                    doc.setFontSize(14)
                    doc.text(`$${invoice.subtotal}`, 120, 96)

                    if (invoice.vat_8) {
                        doc.setFontSize(12)
                        doc.text(`I.V.A. 8%:`, 15, 102)
                        doc.setFontSize(14)
                        doc.text(`$${invoice.vat_8_value}`, 120, 102)

                        if (invoice.vat_16) {
                            doc.setFontSize(12)
                            doc.text(`I.V.A. 16%:`, 15, 108)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.vat_16_value}`, 120, 108)
                            doc.setFontSize(12)
                            doc.text(`TOTAL:`, 15, 114)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.total}`, 120, 114)
                        } else {
                            doc.setFontSize(12)
                            doc.text(`TOTAL:`, 15, 108)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.total}`, 120, 108)
                        }

                    } else {

                        if (invoice.vat_16) {
                            doc.setFontSize(12)
                            doc.text(`I.V.A. 16%:`, 15, 102)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.vat_16_value}`, 120, 102)
                            doc.setFontSize(12)
                            doc.text(`TOTAL:`, 15, 108)
                            doc.setFontSize(14)
                            doc.text(`$${invoice.total}`, 120, 108)
                        }
                    }
                }


                doc.save(`${invoice.uuid}_${invoice.manifest.folio}.pdf`)
            }
        },
        beforeCreate: function () {
            if (!window.localStorage.getItem(`${this.$page.url}-table`)) {
                const table = {
                    perPage: 25,
                    pagination: 1,
                    search: ''
                }

                window.localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(table))
            }
        },
        mounted() {
            this.tableVariables = JSON.parse(localStorage.getItem(`${this.$page.url}-table`))
            this.index()
        },
        // watch: {
        //     restoreForm: {
        //         deep: true,
        //         handler(data) {
        //             if (data.file) {
        //                 this.restoreForm.errorFile = false
        //             }
        //         }
        //     }
        // }
    }
</script>