<template>
    <nav v-if="items!==undefined" role="navigation">
        <div class="flex justify-center flex-1 sm:hidden">
            <span class="relative inline-flex rounded">
                <span v-if="onFirstPage" aria-disabled="true"
                    aria-hidden="true"
                    class="relative inline-flex items-center px-2 py-2 text-sm font-medium text-coolGray-400 bg-gray-50 border border-gray-200 cursor-default rounded-l-md leading-5">
                    <svg class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20">
                        <path clip-rule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            fill-rule="evenodd"/>
                    </svg>
                </span>
                <button v-else @click="prevPage"
                    class="relative inline-flex items-center px-2 py-2 text-sm font-medium text-coolGray-500 bg-white border border-gray-200 rounded-l-md leading-5 hover:bg-gray-50 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150"
                    rel="prev">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                        <path clip-rule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            fill-rule="evenodd"/>
                    </svg>
                </button>

                <div v-for="(link, index) in links" :key="index">
                    <button
                        v-if="link.active"
                        :class="link.active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                        @click="singleLink(link.url, link.active)"
                        class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                        {{ link.label }}
                    </button>
                </div>

                <button v-if="hasMorePages" @click="nextPage"
                    class="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium text-coolGray-500 bg-white border border-gray-200 rounded-r-md leading-5 hover:bg-gray-50 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                        <path clip-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            fill-rule="evenodd"/>
                    </svg>
                </button>
                <span v-else
                    aria-disabled="true"
                    aria-hidden="true"
                    class="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium text-coolGray-400 bg-gray-50 border border-gray-200 cursor-default rounded-r-md leading-5">
                    <svg class="w-5 h-5" fill="currentColor"
                        viewBox="0 0 20 20">
                        <path clip-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            fill-rule="evenodd"/>
                    </svg>
                </span>
            </span>
        </div>

        <div class="hidden sm:flex sm:flex-col lg:flex-row sm:items-center sm:justify-between ">
            <div class=" pb-2">
                <p class="text-sm text-coolGray-600 leading-5">
                    Mostrando
                    <span v-if="firstItem" class="font-medium" v-text="firstItem"></span>
                    <span v-else class="font-medium">0</span>
                    a
                    <span v-if="lastItem" class="font-medium" v-text="lastItem"></span>
                    <span v-else class="font-medium">0</span>
                    de
                    <span class="font-medium" v-text="total"></span>
                    registros
                </p>
            </div>

            <div>
                <span class="relative inline-flex rounded">
                    <span v-if="onFirstPage"
                        aria-disabled="true"
                        aria-hidden="true"
                        class="relative inline-flex items-center px-2 py-2 text-sm font-medium text-coolGray-400 bg-gray-50 border border-gray-200 cursor-default rounded-l-md leading-5">
                        <svg class="w-5 h-5" fill="currentColor"
                            viewBox="0 0 20 20">
                            <path clip-rule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                fill-rule="evenodd"/>
                        </svg>
                    </span>
                    <button v-else @click="prevPage"
                            class="relative inline-flex items-center px-2 py-2 text-sm font-medium text-coolGray-500 bg-white border border-gray-200 rounded-l-md leading-5 hover:bg-gray-50 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150"
                            rel="prev">
                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                            <path clip-rule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                fill-rule="evenodd"/>
                        </svg>
                    </button>

                    <div v-if="links.length<=10">
                        <span v-for="(link, index) in links" :key="index">
                            <button
                                :class="link.active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                                @click="singleLink(link.url, link.active)"
                                class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                                {{ link.label }}
                            </button>
                        </span>
                    </div>

                    <div v-else>
                        <button
                            :class="links[0].active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                            @click="singleLink(links[0].url, links[0].active)"
                            class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            {{ links[0].label }}
                        </button>

                        <button v-if="items.current_page < 5"
                            :class="links[1].active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                            @click="singleLink(links[1].url, links[1].active)"
                            class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            {{ links[1].label }}
                        </button>

                        <button v-if="items.current_page < 5"
                            :class="links[2].active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                            @click="singleLink(links[2].url, links[2].active)"
                            class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            {{ links[2].label }}
                        </button>

                        <button v-if="items.current_page < 5"
                            :class="links[3].active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                            @click="singleLink(links[3].url, links[3].active)"
                            class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            {{ links[3].label }}
                        </button>

                        <button v-if="items.current_page < 5"
                            :class="links[4].active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                            @click="singleLink(links[4].url, links[4].active)"
                            class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            {{ links[4].label }}
                        </button>

                        <span v-if="items.current_page < 5"
                            class="text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200 relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            ...
                        </span>

                        <span v-if="items.current_page >= 5"
                            class="text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200 relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            ...
                        </span>

                        <button v-if="items.current_page >= 5 && this.items.current_page <= links[links.length-5].label"
                            :class="links[items.current_page-2].active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                            @click="singleLink(links[items.current_page-2].url, links[items.current_page-2].active)"
                            class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            {{ links[items.current_page-2].label }}
                        </button>

                        <button v-if="items.current_page >= 5 && this.items.current_page <= links[links.length-5].label"
                            :class="links[items.current_page-1].active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                            @click="singleLink(links[items.current_page-1].url, links[items.current_page-1].active)"
                            class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            {{ links[items.current_page-1].label }}
                        </button>

                        <button v-if="items.current_page >= 5 && this.items.current_page <= links[links.length-5].label"
                            :class="links[items.current_page].active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                            @click="singleLink(links[items.current_page].url, links[items.current_page].active)"
                            class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            {{ links[items.current_page].label }}
                        </button>

                        <span v-if="items.current_page >= 5 && this.items.current_page <= links[links.length-5].label"
                            class="text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200 relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            ...
                        </span>

                        <button v-if="this.items.current_page > links[links.length-5].label"
                            :class="links[links.length-5].active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                            @click="singleLink(links[links.length-5].url, links[links.length-5].active)"
                            class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            {{ links[links.length-5].label }}
                        </button>

                        <button v-if="this.items.current_page > links[links.length-5].label"
                            :class="links[links.length-4].active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                            @click="singleLink(links[links.length-4].url, links[links.length-4].active)"
                            class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            {{ links[links.length-4].label }}
                        </button>

                        <button v-if="this.items.current_page > links[links.length-5].label"
                            :class="links[links.length-3].active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                            @click="singleLink(links[links.length-3].url, links[links.length-3].active)"
                            class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            {{ links[links.length-3].label }}
                        </button>

                        <button v-if="this.items.current_page > links[links.length-5].label"
                            :class="links[links.length-2].active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                            @click="singleLink(links[links.length-2].url, links[links.length-2].active)"
                            class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            {{ links[links.length-2].label }}
                        </button>

                        <button
                            :class="links[links.length-1].active===true ? 'bg-blue-500 border-blue-500 text-white hover:bg-blue-400 hover:border-blue-400' : 'text-coolGray-500 bg-white hover:bg-gray-50 border-gray-200'"
                            @click="singleLink(links[links.length-1].url, links[links.length-1].active)"
                            class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border leading-5 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                            {{ links[links.length-1].label }}
                        </button>
                    </div>

                    <button v-if="hasMorePages" @click="nextPage"
                        class="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium text-coolGray-500 bg-white border border-gray-200 rounded-r-md leading-5 hover:bg-gray-50 focus:outline-none focus:ring ring-transparent transition ease-in-out duration-150">
                        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                            <path clip-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                fill-rule="evenodd"/>
                        </svg>
                    </button>
                    <span v-else aria-disabled="true"
                        aria-hidden="true"
                        class="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium text-coolGray-400 bg-gray-50 border border-gray-200 cursor-default rounded-r-md leading-5">
                        <svg class="w-5 h-5" fill="currentColor"
                            viewBox="0 0 20 20">
                            <path clip-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                fill-rule="evenodd"/>
                        </svg>
                    </span>
                </span>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        props: {
            items: {
                current_page:   Number,
                data:           Array,
                first_page_url: String,
                from:           Number,
                last_page:      Number,
                last_page_url:  String,
                links:          Array,
                next_page_url:  String,
                path:           String,
                per_page:       Number,
                prev_page_url:  String,
                to:             Number,
                total:          Number,
            }
        },
        emits: ['nextPage', 'prevPage', 'link'],
        methods: {
            nextPage() {
                this.$emit('nextPage', this.items.next_page_url)
            },
            prevPage() {
                this.$emit('prevPage', this.items.prev_page_url)
            },
            singleLink(page, active) {
                if (!active) {
                    this.$emit('link', page)
                }
            }
        },
        computed: {
            onFirstPage() {
                return this.items.current_page === 1
            },
            hasMorePages() {
                return this.items.current_page < this.items.last_page
            },
            nextPageUrl() {
                return this.items.next_page_url
            },
            previousPageUrl() {
                return this.items.prev_page_url
            },
            firstItem() {
                return this.items.from
            },
            lastItem() {
                return this.items.to
            },
            total() {
                return this.items.total
            },
            links() {
                const links = []
                for (let i = 1; i <= this.items.last_page; i++) {
                    links.push({'label': i, 'url': `${this.items.path}?page=${i}`, 'active': this.items.current_page === i ? true : false})
                }
                return links;
            }
        }
    }
</script>