<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.cities.index')">
                        Ciudades
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.cities.show', city.id)">
                        {{city.name}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Editando
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <form @submit.prevent="submit">

                <div class="shadow sm:rounded-md bg-white">

                    <div class="px-6 pt-6 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label 
                                    for="state"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Estado</label>
                                <selectize
                                    :options="states"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un estado.'"
                                    @input="stateSelected"
                                    :value="setState"></selectize>
                                <div
                                    v-if="form.errors.state"
                                    class="text-red-500 text-xs mt-1 mt-2">{{ form.errors.state }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label 
                                    for="name"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nombre</label>
                                <input 
                                    id="name"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.name"
                                    autocomplete="givenname"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.name"
                                    class="text-red-500 text-xs mt-1 mt-2">{{ form.errors.name }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="pt-6 text-right space-x-4">
                    <link-light :href="route('dashboard.cities.index')" :disabled="form.processing">Cancelar</link-light>
                    <btn-warning type="submit" :disabled="form.processing">
                        <spin v-show="form.processing"></spin>
                        Actualizar
                    </btn-warning>
                </div>

            </form>
        </div>
        
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnWarning       from '@/Components/Buttons/Warning'
    import Selectize        from '@/Components/Inputs/Selectize'
    import Spin             from '@/Components/Spin'

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnWarning,
            BtnWarning,
            BtnWarning,
            Selectize,
            Spin
        },
        props: ['city', 'states'],
        data() {
            return {
                form: this.$inertia.form({
                    state:  null,
                    name:   null
                }),
                setState:   null
            }
        },
        methods: {
            submit() {
                this.form.put(route('dashboard.cities.update', this.city.id), {preserveScroll: true});
            },
            init() {
                this.form.state = this.city.state.id
                this.setState   = this.form.state
                this.form.name  = this.city.name
            },
            stateSelected(value) {
                this.form.state = value ? value.id : null
            }
        },
        updated() {
            this.setState = this.form.state ? this.form.state : -1
        },
        mounted() {
            this.init()
        }
    }
</script>