<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.manifests.cancelled.index')">
                        Manifiestos cancelados
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.manifests.cancelled.show', manifest.id)">
                        {{manifest.folio}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Remanifestando
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <form @submit.prevent="submit" enctype="multipart/form-data">
                <div class="shadow sm:rounded-md bg-white">

                    <div class="px-6 pt-6 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="subsidiary"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Sede</label>
                                <selectize
                                    :data="subsidiaries"
                                    :emptyOptionsMessage="'No se encontraron resultados.'"
                                    :name="'subsidiary'" :disabled="form.processing"
                                    :placeholder="'Seleccione una sucursal.'"
                                    @selectedValue="subsidiarySelected" :field="'descriptive_name'" :setValue="setSubsidiary"></selectize>
                                <div 
                                    v-if="form.errors.subsidiary" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.subsidiary }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 py-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

                            <div class="col-span-1">
                                <label
                                    for="folio"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Folio</label>
                                <input
                                    id="folio"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.folio"
                                    @keyup="existence"
                                    autocomplete="givenfolio"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.folio"
                                    class="text-red-500 text-xs mt-1 mt-1">{{ form.errors.folio }}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="typeWaste"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Tipo de residuos</label>
                                <selectize
                                    :data="typeWastes"
                                    :emptyOptionsMessage="'No se encontraron resultados.'"
                                    :name="'typeWaste'" :disabled="form.processing"
                                    :placeholder="'Seleccione un tipo de residuo.'"
                                    @selectedValue="typeWasteSelected" :setValue="setTypeWaste"></selectize>
                                <div 
                                    v-if="form.errors.typeWaste" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.typeWaste }}</div>
                            </div>

                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa generadora</label>
                                <selectize
                                    :data="generators"
                                    :emptyOptionsMessage="'No se encontraron resultados.'"
                                    :name="'generator'" :disabled="form.processing"
                                    :placeholder="'Seleccione una empresa generadora.'"
                                    @selectedValue="generatorSelected" :setValue="setGenerator"></selectize>
                                <div 
                                    v-if="form.errors.generator" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.generator }}</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="carrier"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa transportista</label>
                                <selectize
                                    :data="carriers"
                                    :emptyOptionsMessage="'No se encontraron resultados.'"
                                    :name="'carrier'" :disabled="form.processing"
                                    :placeholder="'Seleccione una empresa transportista.'"
                                    @selectedValue="carrierSelected" :setValue="setCarrier"></selectize>
                                <div
                                    v-if="form.errors.carrier" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.carrier }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="recipient"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa destinataria</label>
                                <selectize
                                    :data="recipients"
                                    :emptyOptionsMessage="'No se encontraron resultados.'"
                                    :name="'recipient'" :disabled="form.processing"
                                    :placeholder="'Seleccione una empresa destinataria.'"
                                    @selectedValue="recipientSelected" :setValue="setRecipient"></selectize>
                                <div 
                                    v-if="form.errors.recipient" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.recipient }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 py-6">
                        <h4 class="font-semibold mb-2">Residuo #1</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteOne"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <selectize
                                    :data="wastes"
                                    :emptyOptionsMessage="'No se encontraron resultados.'"
                                    :name="'wasteOne'" :disabled="form.processing"
                                    :placeholder="'Seleccione un nombre de residuo'"
                                    @selectedValue="wasteOneSelected" :field="'name'" :setValue="setWasteOne"></selectize>
                                <div 
                                    v-if="form.errors.wasteId" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.wasteId }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 py-6">
                        <h4 class="font-semibold mb-2">Residuo #2</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteTwo"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <selectize
                                    :data="wastes"
                                    :emptyOptionsMessage="'No se encontraron resultados.'"
                                    :name="'wasteTwo'" :disabled="form.processing"
                                    :placeholder="'Seleccione un nombre de residuo'"
                                    @selectedValue="wasteTwoSelected" :field="'name'" :setValue="setWasteTwo"></selectize>
                                <div 
                                    v-if="form.errors.wasteId" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.wasteId }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 py-6">
                        <h4 class="font-semibold mb-2">Residuo #3</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteThree"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <selectize
                                    :data="wastes"
                                    :emptyOptionsMessage="'No se encontraron resultados.'"
                                    :name="'wasteThree'" :disabled="form.processing"
                                    :placeholder="'Seleccione un nombre de residuo'"
                                    @selectedValue="wasteThreeSelected" :field="'name'" :setValue="setWasteThree"></selectize>
                                <div 
                                    v-if="form.errors.wasteId" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.wasteId }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 py-6">
                        <h4 class="font-semibold mb-2">Residuo #4</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteFour"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <selectize
                                    :data="wastes"
                                    :emptyOptionsMessage="'No se encontraron resultados.'"
                                    :name="'wasteFour'" :disabled="form.processing"
                                    :placeholder="'Seleccione un nombre de residuo'"
                                    @selectedValue="wasteFourSelected" :field="'name'" :setValue="setWasteFour"></selectize>
                                <div 
                                    v-if="form.errors.wasteId" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.wasteId }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="pt-6 text-right space-x-4">
                    <link-light :href="route('dashboard.manifests.cancelled.index')" :disabled="form.processing">Cancelar</link-light>
                    <btn-success type="submit" :disabled="form.processing">
                        <spin v-show="form.processing"></spin>
                        Agregar
                    </btn-success>
                </div>
            </form>
        </div>
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnSuccess       from '@/Components/Buttons/Success'
    import Selectize        from '@/Components/Inputs/Selectize'
    import Spin             from '@/Components/Spin'
    
    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnSuccess,
            Selectize,
            Date,
            Spin
        },
        props: ['manifest', 'subsidiaries', 'arrCarriersDangerous', 'arrCarriersNotDangerous', 'arrRecipientsDangerous', 'arrRecipientsNotDangerous', 'arrGeneratorsDangerous', 'arrGeneratorsNotDangerous'],
        data() {
            return {
                form: this.$inertia.form({
                    manifestId:                 null,
                    subsidiary:                 null,
                    folio:                      null,
                    typeWaste:                  null,
                    generator:                  null,
                    generatorName:              null,                    
                    carrier:                    null,
                    recipient:                  null,
                    wasteOne: {
                        wasteId: null,
                    },
                    wasteTwo: {
                        wasteId: null,
                    },
                    wasteThree: {
                        wasteId: null,
                    },
                    wasteFour: {
                        wasteId: null,
                    }
                }),
                typeWastes:                 [{id: '1', name: 'Peligrosos'}, {id: '2', name: 'No peligrosos'}],
                carriers:                   [],
                recipients:                 [],
                generators:                 [],
                wastes:                     [],
                setSubsidiary:              null,
                setGenerator:               null,
                setCarrier:                 null,
                setRecipient:               null,
                setTypeWaste:               null,
                setWasteOne:                null,
                setWasteTwo:                null,
                setWasteThree:              null,
                setWasteFour:               null
            } 
        },
        methods: {
            submit() {
                this.form.post(route('dashboard.manifests.cancelled.remanifest.store'), this.form, {forceFormData: true})
            },
            existence: _.debounce(async function () {
                const response = await axios.post(route('api.manifests.open.existence'), this.form)
                if (response.data.message) {
                    this.form.errors.folio = response.data.message
                } else {
                    this.form.errors.folio = null
                }
            }, 300),
            init() {
                this.form.manifestId        = this.manifest.id
                this.form.subsidiary        = this.manifest.subsidiary.id
                this.setSubsidiary          = this.manifest.subsidiary.id
                if (this.manifest.type_waste === 'Peligrosos') {
                    this.form.typeWaste = 1
                    this.setTypeWaste   = 1

                    this.generators = this.arrGeneratorsDangerous
                    this.carriers   = this.arrCarriersDangerous
                    this.recipients = this.arrRecipientsDangerous
                } else {
                    this.form.typeWaste = 2
                    this.setTypeWaste   = 2

                    this.generators = this.arrGeneratorsNotDangerous
                    this.carriers   = this.arrCarriersNotDangerous
                    this.recipients = this.arrRecipientsNotDangerous
                }
                const generator             = this.manifest?.client ? this.manifest.client      : this.manifest.generator
                this.setGenerator           = generator.id
                this.form.generator         = generator.id
                this.form.generatorName     = generator.data.name
                this.wastes                 = generator.wastes

                this.form.carrier           = this.manifest.carrier.id
                this.setCarrier             = this.manifest.carrier.id
                this.form.recipient         = this.manifest.recipient.id
                this.setRecipient           = this.manifest.recipient.id

                for (let i = 0; i < this.manifest.manifest_wastes.length; i++) {
                    const manifestWaste                     = this.manifest.manifest_wastes[i];
                    if (i == 0) {
                        this.form.wasteOne.wasteId          = manifestWaste.waste.id
                        this.setWasteOne                    = manifestWaste.waste.id
                    }

                    if (i == 1) {
                        this.form.wasteTwo.wasteId          = manifestWaste.waste.id
                        this.setWasteTwo                    = manifestWaste.waste.id
                    }

                    if (i == 2) {
                        this.form.wasteThree.wasteId          = manifestWaste.waste.id
                        this.setWasteThree                    = manifestWaste.waste.id
                    }

                    if (i == 3) {
                        this.form.wasteFour.wasteId          = manifestWaste.waste.id
                        this.setWasteFour                    = manifestWaste.waste.id
                    }
                }
            },
            subsidiarySelected(val) {
                this.form.subsidiary    = val
                this.setSubsidiary      = val
            },
            generatorSelected(val) {
                this.form.generator = val
                this.setGenerator   = val

                if (this.form.typeWaste == 1) {
                    this.arrGeneratorsDangerous.forEach(e => {
                        if(val === e.id) {
                            this.form.generatorName = e.name
                            this.wastes = e.wastes
                        }
                    })
                } else {
                    this.arrGeneratorsNotDangerous.forEach(e => {
                        if(val === e.id) {
                            this.form.generatorName = e.name
                            this.wastes = e.wastes
                        }
                    })
                }
            },
            carrierSelected(val) {
                this.form.carrier   = val
                this.setCarrier     = val
            },
            recipientSelected(val) {
                this.form.recipient     = val
                this.setRecipient       = val
            },
            typeWasteSelected(val) {
                this.form.typeWaste              = val
                this.form.generator              = null
                this.form.generatorName          = null
                this.setGenerator                = null
                this.form.carrier                = null
                this.setCarrier                  = null
                this.form.recipient              = null
                this.setRecipient                = null
                this.wastes                      = []
                this.form.wasteOne.wasteId       = null
                this.setWasteOne                 = null
                this.form.wasteTwo.wasteId       = null
                this.setWasteTwo                 = null
                this.form.wasteThree.wasteId     = null
                this.setWasteThree               = null
                this.form.wasteFour.wasteId      = null
                this.setWasteFour                = null
                this.generators                  = []
                this.carriers                    = []
                this.recipients                  = []

                if (val == 1) {
                    this.generators = this.arrGeneratorsDangerous
                    this.carriers   = this.arrCarriersDangerous
                    this.recipients = this.arrRecipientsDangerous
                } else {
                    this.generators = this.arrGeneratorsNotDangerous
                    this.carriers   = this.arrCarriersNotDangerous
                    this.recipients = this.arrRecipientsNotDangerous
                }
            },
            wasteOneSelected(val) {
                this.form.wasteOne.wasteId = val
            },
            wasteTwoSelected(val) {
                this.form.wasteTwo.wasteId = val
            },
            wasteThreeSelected(val) {
                this.form.wasteThree.wasteId = val
            },
            wasteFourSelected(val) {
                this.form.wasteFour.wasteId = val
            }
        },
        mounted() {
            this.init()
        },
        updated() {
            this.setSubsidiary        = this.form.subsidiary
            this.setTypeWaste         = this.form.typeWaste
            this.setGenerator         = this.form.generator
            this.setCarrier           = this.form.carrier
            this.setRecipient         = this.form.recipient
            this.setWasteOne          = this.form.wasteOne.wasteId
            this.setWasteTwo          = this.form.wasteTwo.wasteId
            this.setWasteThree        = this.form.wasteThree.wasteId
            this.setWasteFour         = this.form.wasteFour.wasteId
        }
    }
</script>

<style>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>