<template>
    <li class="flex items-center">
        <div :class="isSingle ? 'text-gray-500' : 'text-gray-700 cursor-pointer' ">
            <slot />
        </div>
        <arrow-right-outline v-if="isSingle==false" class="w-4 h-4 mx-4" />
    </li>
</template>

<script>
    import ArrowRightOutline from '@/Components/Icons/HeroIcons/Outline/ArrowRight'

    export default {
        props: {
            isSingle: {
                type: Boolean,
                default: false
            },
        },
        components: {
            ArrowRightOutline
        }
    }
</script>