<template>
    <td :colspan="colspan"
        class=""
        :class="{'sticky z-10 bg-white' : fixed, 'z-0' : !fixed, 'left-0' : firstColumn, 'left-32' : !firstColumn, 'right-0' : lastColumn}">
        <div class="px-6 py-4 text-sm font-medium text-coolGray-600 ">
            <slot></slot>
        </div>
    </td>
</template>

<script>
    export default {
        props: {
            colspan: {
                type: Number,
                default: 1
            },
            fixed: {
                type: Boolean,
                default: false
            },
            firstColumn: {
                type: Boolean,
                default: false
            },
            lastColumn: {
                type: Boolean,
                default: false
            }
        }
    }
</script>