<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.permissions.index')">
                        Permisos
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.permissions.show', permission.id)">
                        {{permission.name}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Editando
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4 mt-6 mb-40">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form @submit.prevent="submit">
                    <div class="shadow sm:rounded-md bg-white">
                        <div class="px-6 p-6">
                            <label for="name"
                                class="block text-sm font-semibold text-gray-700">Nombre</label>
                            <input type="text" :disabled="form.processing"
                                v-model="form.name"
                                autocomplete="givenname"
                                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md" />
                                <div v-if="form.errors.name" class="text-red-500 text-xs mt-2">{{ form.errors.name }}</div>
                        </div>
                        
                        <div class="px-6 pb-6">
                            <label for="description"
                                class="block text-sm font-semibold text-gray-700">Descripción</label>
                            <input type="text" :disabled="form.processing"
                                v-model="form.description"
                                autocomplete="givendescription"
                                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md" />
                                <div v-if="form.errors.description" class="text-red-500 text-xs mt-2">{{ form.errors.description }}</div>
                        </div>
                    </div>

                    <div class="pt-6 text-right space-x-4">
                        <link-light :href="route('dashboard.permissions.index')" :disabled="form.processing">Cancelar</link-light>
                        <btn-warning type="submit" :disabled="form.processing">Actualizar</btn-warning>
                    </div>
                </form>
            </div>
        </div>
    </app-layout>
</template>

<script>
    import { InertiaLink } from '@inertiajs/inertia-vue3'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import BreadcumbNav from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight from '@/Components/Links/Light'
    import BtnWarning from '@/Components/Buttons/Warning'
    import LinkFlat from '@/Components/Links/Flat'
    
    export default {
        data() {
            return {
                form: this.$inertia.form({
                    name: this.permission.name,
                    description: this.permission.description
                })
            } 
        },
        props: {permission: Object},
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnWarning,
            LinkFlat
        },
        methods: {
            submit() {
                this.form.put(route('dashboard.permissions.update', this.permission.id), this.form, {preserveScroll: true});
            }
        }
    }
</script>