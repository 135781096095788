<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    Bitácoras
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Salidas
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="grid grid-cols-1 gap-4 p-4">
            <div class="col-span-1">
                <div class="shadow rounded bg-white">

                    <div class="px-6 pt-6 pb-3">
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 md:col-span-4">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Sede</label>
                                <v-select
                                    :disabled="params.processing"
                                    :selectOnTab="true"
                                    :tabindex="1"
                                    placeholder="Seleccione una sede"
                                    label="name"
                                    v-model="params.subsidiary"
                                    :reduce="(option) => option.id"
                                    :options="subsidiaries">Ñ
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                            </div>

                            <div class="col-span-12 sm:col-span-10 md:col-span-6">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Tipo de residuos</label>
                                <v-select
                                    :disabled="params.processing"
                                    :selectOnTab="true"
                                    :tabindex="1"
                                    placeholder="Seleccione una sede"
                                    label="name"
                                    @option:selected="typeWasteSelected"
                                    v-model="params.typeWaste"
                                    :reduce="(option) => option.id"
                                    :options="typeWastes">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                </v-select>
                            </div>

                            <div class="col-span-12 sm:col-span-2">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Mostrar R.P.B.I.</label>

                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input
                                            id="rpbi"
                                            type="checkbox"
                                            :disabled="params.processing"
                                            @click="rpbiChange"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                            :class="params.processing ? 'cursor-not-allowed' : 'cursor-default'"
                                            :checked="params.rpbi" />
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="rpbi" class="font-medium text-gray-700" v-text="rpbiLabel"></label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-6 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa generadora</label>
                                <v-select
                                    :disabled="params.processing || !params.typeWaste"
                                    :selectOnTab="true"
                                    :tabindex="1"
                                    placeholder="Seleccionar una empresa generadora"
                                    label="name"
                                    v-model="params.generator"
                                    :reduce="(option) => option.id"
                                    @search="onSearchGenerator"
                                    :options="generators">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 py-3">
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 md:col-span-6">
                                <label
                                    for="carrier"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa transportista</label>
                                <v-select
                                    :disabled="params.processing || !params.typeWaste"
                                    :selectOnTab="true"
                                    :tabindex="1"
                                    placeholder="Seleccionar una empresa transportista"
                                    label="name"
                                    v-model="params.carrier"
                                    @search="onSearchCarrier"
                                    :reduce="(option) => option.id"
                                    :options="carriers">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                            </div>

                            <div class="col-span-12 md:col-span-6">
                                <label
                                    for="recipient"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa destinataria</label>
                                <v-select
                                    :disabled="params.processing || !params.typeWaste"
                                    :selectOnTab="true"
                                    :tabindex="1"
                                    placeholder="Seleccionar una empresa destinataria"
                                    label="name"
                                    v-model="params.recipient"
                                    @search="onSearchRecipient"
                                    :reduce="(option) => option.id"
                                    :options="recipients">
                                    <template #no-options>
                                        No se encontraron resultados.
                                    </template>
                                    <template #list-footer>
                                        <li style="text-align: center">¡Comienza escribiendo algo para iniciar la búsqueda!</li>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-12 gap-4">
                            <div class="col-span-12 sm:col-span-6">
                                <label
                                    for="since"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Fecha desde</label>

                                <input
                                    id="since"
                                    type="date"
                                    :disabled="params.processing"
                                    v-model="params.since"
                                    autocomplete="givensince"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded disabled:opacity-25"
                                    :class="params.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                            </div>

                            <div class="col-span-12 sm:col-span-6">
                                <label
                                    for="until"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Fecha hasta</label>

                                <input
                                    id="until"
                                    type="date"
                                    :disabled="params.processing"
                                    v-model="params.until"
                                    autocomplete="givenuntil"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded disabled:opacity-25"
                                    :class="params.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-span-1">
                <div class="flex flex-col xl:flex-row justify-between">

                    <div class="flex flex-row">
                        <div class="flex flex-row">
                            <select
                                class="h-full w-max rounded-l appearance-none bg-white text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none ring-0 focus:ring-transparent border-gray-200 focus:border-gray-200"
                                v-model="params.perPage"
                                @change="perPageChanged">
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                        <div class="flex xl:w-56 w-full">
                            <input
                                @paste="search"
                                @cut="search"
                                placeholder="Buscar"
                                class="appearance-none rounded-r border-gray-200 border-t border-b border-r px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                                v-model="params.search" @keyup="search" />
                        </div>
                    </div>

                    <div class="pt-4 xl:pt-0 grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <button
                            type="button"
                            @click="exported"
                            :class="params.processing || disabledExport ? 'cursor-not-allowed' : 'cursor-pointer'"
                            :disabled="params.processing || disabledExport"
                            class="col-span-1 h-full inline-flex items-center justify-center px-4 py-2 border rounded bg-green-400 hover:bg-green-500 font-semibold text-white text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                            Exportar
                        </button>
                        <button
                            type="button"
                            @click="filter"
                            :class="params.processing || disabledFilter ? 'cursor-not-allowed' : 'cursor-pointer'"
                            :disabled="params.processing || disabledFilter"
                            class="col-span-1 h-full inline-flex items-center justify-center px-4 py-2 border rounded bg-blue-400 hover:bg-blue-500 font-semibold text-white text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                            Filtrar
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="bg-white overflow-hidden shadow rounded">
                    <table-container :heading="heading">
                        <tbody>
                            <tr v-show="params.loading">
                                <td :colspan="heading.length" class="h-96">
                                    <loader></loader>
                                </td>
                            </tr>

                            <tr v-if="!manifestWastes?.length && !manifestWastes?.data?.length && !params.loading" class="text-center">
                                <table-body-item :colspan="heading.length">
                                    No se encontraron resultados.
                                </table-body-item>
                            </tr>

                            <tr v-else-if="manifestWastes.data?.length > 0 && !params.loading" v-for="(manifestWaste, index) in manifestWastes.data" :key="index">
                                <table-body-item>
                                    {{manifestWaste.folio}}
                                </table-body-item>
                                <table-body-item>
                                    {{manifestWaste.generator}}
                                </table-body-item>
                                <table-body-item>
                                    <span v-if="manifestWaste.nra">{{manifestWaste.nra}}</span>
                                    <span v-else>Sin n.r.a.</span>
                                </table-body-item>
                                <table-body-item>
                                    <span v-if="manifestWaste.nare">{{manifestWaste.nare}}</span>
                                    <span v-else>Sin n.a.r.e.</span>
                                </table-body-item>
                                <table-body-item>
                                    <span v-if="manifestWaste.admissionAt !== 'N/A'">
                                        {{momentum(manifestWaste.admissionAt)}}
                                    </span>
                                    <span v-else>Sin fecha</span>
                                </table-body-item>
                                <table-body-item>
                                    {{manifestWaste.typeWaste}}
                                </table-body-item>
                                <table-body-item>
                                    {{manifestWaste.waste}}
                                </table-body-item>
                                <table-body-item>
                                    {{manifestWaste.carrier}}
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.authCarrier}}</span>
                                </table-body-item>
                                <table-body-item>
                                    {{manifestWaste.recipient}}
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.authRecipient}}</span>
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.c}}</span>
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.r}}</span>
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.e}}</span>
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.t}}</span>
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.i}}</span>
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.b}}</span>
                                </table-body-item>
                                <table-body-item>
                                    {{manifestWaste.quantity}}
                                </table-body-item>
                                <table-body-item>
                                    <span v-if="manifestWaste.container">{{manifestWaste.container}}</span>
                                    <span v-else>Sin contenedor</span>
                                </table-body-item>
                                <table-body-item>
                                    {{manifestWaste.total_quantity}}
                                </table-body-item>
                                <table-body-item>
                                    <span v-if="manifestWaste.measurement">{{manifestWaste.measurement}}</span>
                                    <span v-else>Sin unidad</span>
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.departuresAt}}</span>
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.consolidatedFolio}}</span>
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.consolidatedCarrier}}</span>
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.consolidatedAuthCarrier}}</span>
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.consolidatedRecipient}}</span>
                                </table-body-item>
                                <table-body-item>
                                    <span>{{manifestWaste.consolidatedAuthRecipient}}</span>
                                </table-body-item>
                            </tr>
                        </tbody>
                    </table-container>

                    <div v-if="manifestWastes.current_page && manifestWastes.data.length">
                        <pagination
                            :totalPages="manifestWastes.total"
                            :perPage="params.perPage"
                            :currentPage="params.currentPage"
                            :to="manifestWastes.to"
                            :from="manifestWastes.from"
                            :lastPage="manifestWastes.last_page"
                            @pagechanged="onCurrentPage" />
                    </div>

                </div>
            </div>
        </div>

    </app-layout>
</template>

<script>
    import { InertiaLink }      from '@inertiajs/inertia-vue3'
    import AppLayout            from '@/Layouts/AppLayout.vue'
    import BreadcumbNav         from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem        from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid            from '@/Components/Icons/HeroIcons/Solid/Home'
    import moment               from 'moment'
    import Spin                 from '@/Components/Spin'
    import BtnSuccess           from '@/Components/Buttons/Success'
    import BtnPrimary           from '@/Components/Buttons/Primary'
    import Pagination           from '@/Components/Pagination/PaginationV4'
    import Loader               from '@/Components/Loader'
    import TableContainer       from '@/Components/Tables/TableContainer'
    import TableBodyItem        from '@/Components/Tables/TableBodyItem'
    import vSelect              from 'vue-select'
    import 'vue-select/dist/vue-select.css'

    import { throttle }         from 'lodash';

    export default {
        components: {
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            Spin,
            BtnSuccess,
            BtnPrimary,
            vSelect,
            Pagination,
            Loader,
            TableContainer,
            TableBodyItem,
            InertiaLink
        },
        props: ['subsidiaries'],
        data() {
            return {
                params: {
                    subsidiary:     null,
                    generator:      null,
                    carrier:        null,
                    recipient:      null,
                    typeWaste:      null,
                    since:          null,
                    until:          null,
                    rpbi:           true,

                    search:         '',
                    perPage:        25,
                    currentPage:    1,
                    processing:     false,
                    loading:        false,
                },
                typeWastes:         [{id: -1, name: 'TODOS'}, {id: 1, name: 'PELIGROSOS'}, {id: 2, name: 'NO PELIGROSOS'}],
                generators:         [],
                carriers:           [],
                recipients:         [],
                manifestWastes:     [],
                rpbiLabel:          'Si',
                heading:            [
                                        'Manifiesto',
                                        'Generadora',
                                        'N.R.A.',
                                        'N.A.R.E.',
                                        'Fecha de entrada',
                                        'Tipo de residuo',
                                        'Residuo',
                                        'Empresa transportista',
                                        'Autorización de transportista',
                                        'Empresa destinataria',
                                        'Autorización de destinatario',
                                        'C',
                                        'R',
                                        'E',
                                        'T',
                                        'I',
                                        'B',
                                        'Bultos',
                                        'Contenedor',
                                        'Cantidad generada',
                                        'Unidad',
                                        'Fecha de salida',
                                        'Manifiesto consolidado',
                                        'Transportista final',
                                        'Aut. transportista final',
                                        'Destinataria final',
                                        'Aut destinatario final'
                                    ],
                tableVariables:     null,

                disabledFilter:     true,
                disabledExport:     true,
            }
        },
        methods: {
            onSearchGenerator(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchGenerator(loading, search, this.params.typeWaste, this)
                }
            },
            searchGenerator: _.debounce((loading, search, typeWaste, vm) => {
                axios.post(route('api.clients.search'), {'search': search, 'typeWaste': typeWaste}).then(({data}) => {
                    vm.generators = data.length ? [{id: -1, name: 'TODAS'}, ...data[0]] : []
                    loading(false)
                })
            }, 350),
            onSearchCarrier(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchCarrier(loading, search, this.params.typeWaste, this)
                }
            },
            searchCarrier: _.debounce((loading, search, typeWaste, vm) => {
                axios.post(route('api.carriers.search'), {'search': search, 'typeWaste': typeWaste}).then(({data}) => {
                    vm.carriers = data.length ? [{id: -1, name: 'TODAS'}, ...data[0]] : []
                    loading(false)
                })
            }, 350),
            onSearchRecipient(search, loading) {
                if(search.length) {
                    loading(true)
                    this.searchRecipient(loading, search, this.params.typeWaste, this)
                }
            },
            searchRecipient: _.debounce((loading, search, typeWaste, vm) => {
                axios.post(route('api.recipients.search'), {'search': search, 'typeWaste': typeWaste}).then(({data}) => {
                    vm.recipients = data.length ? [{id: -1, name: 'TODAS'}, ...data[0]] : []
                    loading(false)
                })
            }, 350),
            filter: throttle(async function() {
                this.params.processing = true
                this.params.loading    = true
                this.manifestWastes    = []
                await axios.post(this.route('api.binnacles.departures.filter'), this.params).then(({data}) => {
                    this.params.processing  = false
                    this.params.loading     = false
                    this.manifestWastes     = data
                    console.log(data)
                })

            }, 150),
            exported() {
                window.open(route('dashboard.binnacles.departures.download', this.params), '_blank')
            },
            typeWasteSelected() {
                this.params.generator   = null
                this.params.carrier     = null
                this.params.recipient   = null
                this.generators         = []
                this.carriers           = []
                this.recipients         = []

                this.manifestWastes    = []
                this.resetPagination()
            },
            perPageChanged() {
                this.resetPagination()
                this.filter()
            },
            search: throttle(function () {
                this.resetPagination()

                this.filter()
            }, 150),
            onCurrentPage(page) {
                this.params.currentPage = page
                this.filter()
            },
            resetPagination() {
                if (this.params.currentPage != 1) {
                    this.params.currentPage = 1
                }
            },
            momentum(date) {
                return moment(date).format('DD-MM-YYYY')
            },
            rpbiChange() {
                this.params.rpbi    = !this.params.rpbi
                this.rpbiLabel      = this.params.rpbi ? 'Si' : 'No'
            },
        },
        watch: {
            params: {
                deep: true,
                handler(param) {
                    console.log(param)
                    if (param.subsidiary == null || param.generator == null || param.carrier == null || param.recipient == null || param.until == null || param.since == null || param.typeWaste == null) {
                        this.disabledFilter = true
                    } else {
                        this.disabledFilter = false
                    }

                    // if (param.subsidiary != this.filters.subsidiary || param.generator  != this.filters.generator || param.carrier    != this.filters.carrier || param.recipient  != this.filters.recipient || param.typeWaste  != this.filters.typeWaste || param.since      != this.filters.since || param.until      != this.filters.until) {
                    //     this.disabledExport = true
                    // } else {
                    //     this.disabledExport = false
                    // }
                }
            },
            manifestWastes: {
                deep: true,
                handler(data) {
                    this.disabledExport = data?.current_page && data?.data?.length ? false : true
                }
            }
        }
    }
</script>