<template>
    <div>
        <button :type="type"
            class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition cursor-pointer" 
            v-if="like == 'button'">
            <slot></slot>
        </button>
        
        <a :href="href" 
            class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition" 
            v-else-if="like =='a'">
            <slot></slot>
        </a>

        <inertia-link :href="href" 
            class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition" 
            v-else>
            <slot></slot>
        </inertia-link>
    </div>
</template>

<script>
    import { createInertiaApp, InertiaLink } from '@inertiajs/inertia-vue3'

    export default {
        props: {
            href: {
                type: String,
                default: '#'
            },
            like: {
                type: String,
                default: 'button'
            },
            type: {
                type: String,
                default: 'button'
            }
        },
        components: {
            InertiaLink
        }
    }
</script>
