<template>
    <inertia-link
        :disabled="disabled"
        :class="disabled===true ? 'disabled' : 'cursor-pointer'"
        :href="href"
        class="inline-flex items-center px-4 py-2 bg-gray-200 border border-gray-200 sm:rounded-md font-semibold text-xs text-gray-800 uppercase tracking-widest select-none hover:bg-gray-300 focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease" >
        <slot></slot>
    </inertia-link>
</template>

<script>
    import { InertiaLink } from '@inertiajs/inertia-vue3'

    export default {
        components: {InertiaLink},
        props: {
            href: {
                type: String,
                default: '#',
            },
            disabled: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style>
    .disabled {
        pointer-events: none;
        cursor: not-allowed;
    }
</style>
