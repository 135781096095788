<template>
    <div class="bg-white">
        <nav class="bg-white border-b border-gray-100">
            <div class="px-4 sm:px-6 lg:px-8">
                <div class="flex justify-between">
                    <div class="flex">
                        <!-- Logo -->
                        <div class="flex-shrink-0 flex items-center">
                            <Link href="/">
                                <img :src="logotipo" alt="nissan logo" class="h-6 w-auto">
                            </Link>
                        </div>
                    </div>

                    <div class="hidden sm:flex sm:items-center sm:ml-6">
                        <BreezeNavLink href="/" :active="route().current('welcome')">
                            Home
                        </BreezeNavLink>

                        <BreezeNavLink href="/" :active="route().current('welcome')">
                            Blog
                        </BreezeNavLink>

                        <BreezeNavLink href="/" :active="route().current('welcome')">
                            Servicios
                        </BreezeNavLink>
                    </div>

                    <!-- Hamburger -->
                    <div class="-mr-2 flex items-center sm:hidden">
                        <button @click="showingNavigationDropdown = ! showingNavigationDropdown" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path :class="{'hidden': showingNavigationDropdown, 'inline-flex': ! showingNavigationDropdown }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                                <path :class="{'hidden': ! showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>

                <!-- Responsive Navigation Menu -->
                <div :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}" class="sm:hidden">
                    <div class="pt-2 pb-3 space-y-1">
                        <BreezeResponsiveNavLink href="/" :active="route().current('welcome')">
                            Dashboard
                        </BreezeResponsiveNavLink>
                    </div>
                </div>
            </div>
        </nav>

        <div class="px-4 sm:px-6 lg:px-8">
            <slot />
        </div>
    </div>
</template>

<script>
    import { Link } from '@inertiajs/inertia-vue3';
    import BreezeNavLink from '@/Components/NavLink.vue'
    import BreezeResponsiveNavLink from '@/Components/ResponsiveNavLink.vue'

    export default {
        components: {
            Link,
            BreezeNavLink,
            BreezeResponsiveNavLink
        },
        data() {
            return {
                logotipo:                   process.env.APP_URL + '/storage/brand/new_logotipo.svg',
                showingNavigationDropdown:  false,
            }
        }
    }
</script>