<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.manifests.open.index')">
                        Manifiestos abiertos
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.manifests.open.show', manifest.id)">
                        {{manifest.folio}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Imprimiendo
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="grid grid-cols-1 gap-4 p-4">
            <div class="col-span-1">
                <div id="printMe" class="bg-white overflow-hidden shadow sm:rounded">

                    <div v-if="manifest.type_waste == 'Peligrosos'">
                        <div v-if="this.$page.props.auth.user.subsidiary.id == 1">
                            <HMOPrintDangerous :manifest="manifest" :state="state" :municipality="municipality" :stateCarrier="stateCarrier" :municipalityCarrier="municipalityCarrier"></HMOPrintDangerous>
                        </div>

                        <div v-else-if="this.$page.props.auth.user.subsidiary.id == 2">
                            <NOGPrintDangerous :manifest="manifest" :state="state" :municipality="municipality" :stateCarrier="stateCarrier" :municipalityCarrier="municipalityCarrier"></NOGPrintDangerous>
                        </div>
                    </div>

                    <div v-else-if="manifest.type_waste == 'No peligrosos'">
                        <div v-if="this.$page.props.auth.user.subsidiary.id == 1">
                            <NOGPrintNoDangerous :manifest="manifest" :state="state" :municipality="municipality" :stateCarrier="stateCarrier" :municipalityCarrier="municipalityCarrier"></NOGPrintNoDangerous>
                        </div>

                        <div v-else-if="this.$page.props.auth.user.subsidiary.id == 2">
                            <NOGPrintNoDangerous :manifest="manifest" :state="state" :municipality="municipality" :stateCarrier="stateCarrier" :municipalityCarrier="municipalityCarrier"></NOGPrintNoDangerous>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-span-1">
                <div class="text-right space-x-4">
                    <link-light :href="route('dashboard.manifests.open.index')">Cancelar</link-light>
                    <btn-success @click="print">
                        Imprimir
                    </btn-success>
                </div>
            </div>
        </div>

    </app-layout>
</template>

<script>
    import { InertiaLink }      from '@inertiajs/inertia-vue3'
    import AppLayout            from '@/Layouts/AppLayout.vue'
    import BreadcumbNav         from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem        from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid            from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight            from '@/Components/Links/Light'
    import BtnSuccess           from '@/Components/Buttons/Success'
    import HMOPrintDangerous    from '@/Components/Prints/HMO/Dangerous'
    import HMOPrintNoDangerous  from '@/Components/Prints/HMO/NoDangerous'
    import NOGPrintDangerous    from '@/Components/Prints/NOG/Dangerous'
    import NOGPrintNoDangerous  from '@/Components/Prints/NOG/NoDangerous'

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnSuccess,
            HMOPrintDangerous,
            HMOPrintNoDangerous,
            NOGPrintDangerous,
            NOGPrintNoDangerous
        },
        props: ['manifest', 'state', 'municipality', 'stateCarrier', 'municipalityCarrier'],
        methods: {
            print() {
                // 1. Set the className of the area div to print
                var newstr = document.getElementById('printMe').innerHTML
                // 2. Copy to body and execute window. print printing
                document.body.innerHTML = newstr
                window.print()
                window.location.reload()
            }
        }
    }
</script>