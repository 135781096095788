<template>
  <div class="flex flex-col flex-shrink">
    <div class="overflow-auto border-b" :style="styles">
        <table class="w-full whitespace-nowrap">
            <thead>
                <tr class="sticky top-0 z-20">
                    <th scope="col"
                        class="px-6 py-4 text-left text-xs font-medium leading-5 bg-warmGray-50 text-warmGray-500 uppercase tracking-wider"
                        :class="{'sticky' : fixedHeader(index), 'left-0' : index == 0, 'left-32' : index > 0 && index < heading.length - 1, 'right-0' : index == heading.length - 1 }"
                        v-for="(head, index) in heading" :key="index">
                        {{head}}
                    </th>
                </tr>
            </thead>
            <slot></slot>
        </table>
    </div>
  </div>
</template>

<script>
    export default {
        props: {
            heading:        {type: Array, default: []},
            fixedHeaders:   {type: Array, default: []},
            styles:         {type: String, default: 'height:55vh;'}
        },
        methods: {
            fixedHeader(value) {
                return this.fixedHeaders.includes(value)
            }
        }
    }
</script>