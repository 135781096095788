<template>
    <div class="h-screen flex flex-col flex-1 bg-gray-100 overflow-hidden">
        <navbar @toggleSidebar="toggleSidebar"></navbar>

        <toast :showToast="showToast"></toast>

        <div class="flex flex-row flex-1 h-screen overflow-hidden">
            <sidebar :show="showSidebar" @toggleSidebar="toggleSidebar"></sidebar>

            <main class="flex flex-col flex-1 h-full">
                <header class="flex-shrink-0 bg-white shadow-sm border-b border-gray-300" v-if="$slots.header">
                    <div class="p-4">
                        <slot name="header"></slot>
                    </div>
                </header>

                <div class="flex-1 max-h-full overflow-y-auto overflow-x-hidden scroll-index">
                    <slot></slot>
                </div>
                
                <div class="relative">
                    <div class="absolute bottom-4 right-4">
                        <div class="bg-teal-500 text-white rounded-lg p-4">
                            {{responsiveLabel}}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
    import Sidebar              from '@/Layouts/Sidebar/Aside'
    import Navbar               from '@/Layouts/Header/Navbar'
    import Toast                from '@/Components/Toast'

    export default {
        components: {
            Sidebar,
            Navbar,
            Toast
        },
        data() {
            return {
                isotipo:                process.env.APP_URL + '/storage/brand/new_isotipo.svg',
                isDark:                 false,
                showToast:              false,
                showSidebar:            this.getSidebar(),
                responsiveLabel:        'N/A'
            }
        },
        methods: {
            toggleToast() {
                this.showToast = this.$page.props.toast?.message!=null
                setTimeout(() => this.showToast = false, 2500)
            },
            getSidebar() {
                if (window.localStorage.getItem('show-sidebar')) return JSON.parse(window.localStorage.getItem('show-sidebar'))
                return false
            },
            toggleSidebar() {
                this.showSidebar = this.getSidebar()
            },
            responsiveStatus() {
                if (window.innerWidth <= 640)       this.responsiveLabel = 'SM'
                else if (window.innerWidth <= 768)  this.responsiveLabel = 'MD'
                else if (window.innerWidth <= 1024) this.responsiveLabel = 'LG'
                else if (window.innerWidth <= 1280) this.responsiveLabel = 'XL'
                else if (window.innerWidth <= 1536) this.responsiveLabel = '2XL'
            }
        },
        mounted() {
            this.toggleToast()
            this.responsiveStatus()
        },
        created() {
            window.addEventListener('resize', this.responsiveStatus)
        },
        unmounted() {
            window.removeEventListener('resize', this.responsiveStatus)
        }
    }
</script>