<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.generics.index')">
                        Genericos
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Crear
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <form @submit.prevent="submit">

                <div class="shadow sm:rounded-md bg-white">
                    
                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-12 gap-4">

                            <div class="col-span-10">
                                <label 
                                    for="name"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nombre</label>
                                <input 
                                    id="name"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.name"
                                    autocomplete="givenname"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.name"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.name }}</div>
                            </div>

                            <div class="col-span-2">
                                <label 
                                    for="rpbi"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Residuo R.P.B.I</label>
                                <div class="flex items-start">
                                    <div class="flex items-center h-5">
                                        <input 
                                            id="rpbi"
                                            type="checkbox"
                                            :value="form.rpbi"
                                            :disabled="form.processing"
                                            v-model="form.rpbi"
                                            @click="rpbiChange"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="rpbi" class="font-medium text-gray-700" v-text="rpbiLabel"></label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div v-show="form.rpbi === false" class="px-6 py-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

                            <div class="col-span-1">
                                <label 
                                    for="transportname" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nombre de transporte S.C.T.</label>
                                <selectize
                                    :options="transportnames"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un nombre de transporte.'"
                                    @input="transportnameSelected"
                                    :value="setTransportname"></selectize>
                                <div
                                    v-if="form.errors.transportname" 
                                    class="text-red-500 text-xs">{{ form.errors.transportname }}</div>
                            </div>

                            <div class="col-span-1">
                                <label 
                                    for="numberun" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Número U.N.</label>
                                <selectize
                                    :options="numbersun"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un número u.n.'"
                                    @input="numberunSelected"
                                    :value="setNumberun"></selectize>
                                <div
                                    v-if="form.errors.numberun"
                                    class="text-red-500 text-xs">{{ form.errors.numberun }}</div>
                            </div>

                        </div>
                    </div>
                    
                    <div v-show="form.rpbi===false" class="px-6 py-3">
                        <div class="grid grid-cols-3 gap-4">
                            <div class="col-span-1">
                                <label 
                                    for="hazard"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Caracteristicas CRETIB</label>

                                <div v-for="(hazard, index) in hazards" :key="index" class="inline-flex items-start space-x-2 mr-3">
                                    <div class="flex items-center h-5">
                                        <input 
                                            :id="`hazard${hazard.id}`"
                                            type="checkbox"
                                            :value="hazard.id"
                                            :disabled="form.processing"
                                            v-model="form.hazards"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                    </div>
                                    <div class="mr-3 text-sm">
                                        <label :for="`hazard${hazard.id}`" class="font-medium text-gray-700" v-text="hazard.name"></label>
                                    </div>
                                </div>

                                <div
                                    v-if="form.errors.hazards"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.hazards }}</div>
                            </div>

                            <div class="col-span-1">
                                <label 
                                    for="generic"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Clase</label>

                                <div v-for="(level, index) in levels" :key="index" class="inline-flex items-start space-x-2 mr-3">
                                    <div class="flex items-center h-5">
                                        <input 
                                            :id="`level${level.id}`"
                                            type="checkbox"
                                            :value="level.id"
                                            :disabled="form.processing"
                                            v-model="form.levels"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                    </div>
                                    <div class="text-sm">
                                        <label :for="`level${level.id}`" class="font-medium text-gray-700" v-text="level.name"></label>
                                    </div>
                                </div>
                                <div
                                    v-if="form.errors.levels"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.levels }}</div>
                            </div>

                            <div class="col-span-1">
                                <label 
                                    for="packingGroup" 
                                    class="block text-sm font-semibold text-gray-700 mb-1">Packing group</label>

                                <div class="inline-flex items-start space-x-2">
                                    <div class="flex items-center">
                                        <input 
                                            id="packingGroupI" 
                                            v-model="form.packingGroup" 
                                            value="I" 
                                            checked 
                                            :disabled="form.processing"
                                            type="radio" 
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                        <label for="push" class="ml-3 block text-sm font-medium text-gray-700">
                                            I
                                        </label>
                                    </div>

                                    <div class="flex items-center">
                                        <input 
                                            id="packingGroupII" 
                                            v-model="form.packingGroup" 
                                            value="II" 
                                            type="radio" 
                                            :disabled="form.processing"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                        <label for="push" class="ml-3 block text-sm font-medium text-gray-700">
                                            II
                                        </label>
                                    </div>

                                    <div class="flex items-center">
                                        <input 
                                            id="packingGroupIII" 
                                            v-model="form.packingGroup" 
                                            value="III" 
                                            type="radio" 
                                            :disabled="form.processing"
                                            class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300"
                                            :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                        <label for="push" class="ml-3 block text-sm font-medium text-gray-700">
                                            III
                                        </label>
                                    </div>
                                </div>

                                <div
                                    v-if="form.errors.packingGroup" 
                                    class="text-red-500 text-xs mt-2">{{ form.errors.packingGroup }}</div>
                            </div>

                        </div>
                    </div>

                    <div v-show="form.rpbi===false" class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="integratedTransportName"
                                    class="block text-sm font-semibold text-gray-700 mb-1">Nombre de transporte integrado</label>
                                <input 
                                    id="integratedTransportName"
                                    type="text"
                                    disabled
                                    v-model="form.integratedTransportName"
                                    autocomplete="givenintegratedTransportName"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25 cursor-not-allowed" />
                                <div
                                    v-if="form.errors.integratedTransportName"
                                    class="text-red-500 text-xs mt-2">{{ form.errors.integratedTransportName }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="pt-6 text-right space-x-4">
                    <link-light :href="route('dashboard.generics.index')" :disabled="form.processing">Cancelar</link-light>
                    <btn-success type="submit" :disabled="form.processing">
                        <spin class="text-white" v-show="form.processing"></spin>
                        Agregar
                    </btn-success>
                </div>

            </form>
        </div>
        
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnSuccess       from '@/Components/Buttons/Success'
    import Selectize        from '@/Components/Inputs/Selectize'
    import Spin             from '@/Components/Spin'

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnSuccess,
            Selectize,
            Spin
        },
        props: ['hazards', 'transportnames', 'numbersun', 'levels'],
        data() {
            return {
                form: this.$inertia.form({
                    name:                       '',
                    rpbi:                       false,
                    transportname:              null,
                    numberun:                   null,
                    hazards:                    [],
                    levels:                     [],
                    packingGroup:               'I',
                    integratedTransportName:    ''
                }),
                rpbiLabel:                      'No',
                setTransportname:               null,
                setNumberun:                    null
            }
        },
        methods: {
            submit() {
                this.form.post(route('dashboard.generics.store'), {preserveScroll: true});          
            },
            rpbiChange() {
                this.form.rpbi  = !this.form.rpbi
                this.rpbiLabel  = this.form.rpbi ? 'Si' : 'No'
            },
            transportnameSelected(val) {
                this.form.transportname = val ? val.id : null
            },
            numberunSelected(val) {
                this.form.numberun  = val ? val.id : null
            }
        },
        updated() {
            this.setTransportname   = this.form.transportname ? this.form.transportname : -1
            this.setNumberun        = this.form.numberun      ? this.form.numberun      : -1
        },
        watch: {
            form: {
                deep: true,
                handler(data) {
                    var hazardsLetter   = []
                    var levelsLetter    = []
                    var numberun        = null
                    var transportname   = null

                    if (data.hazards.length>0) {
                        for (let i = 0; i < this.hazards.length; i++) {
                            data.hazards.forEach(element => {
                                if (this.hazards[i].id === element) {
                                    hazardsLetter.push(this.hazards[i].name.substring(0,1))
                                }
                            });
                        }
                    }

                    if (data.levels.length>0) {
                        for (let i = 0; i < this.levels.length; i++) {
                            data.levels.forEach(element => {
                                if (this.levels[i].id === element) {
                                    levelsLetter.push(this.levels[i].name.substring(0,1))
                                }
                            });
                        }
                    }

                    if (data.numberun!==null) {
                        for (let i = 0; i < this.numbersun.length; i++) {
                            if (this.numbersun[i].id === data.numberun) {
                                numberun = this.numbersun[i].name
                            }
                        }
                    }

                    if (data.transportname!==null) {
                        for (let i = 0; i < this.transportnames.length; i++) {
                            if (this.transportnames[i].id === data.transportname) {
                                transportname = this.transportnames[i].name
                            }
                        }
                    }

                    this.form.integratedTransportName = `${transportname ? transportname : 'Nombre de transporte'} (${hazardsLetter.length>0 ? hazardsLetter : 'Caracteristicas CRETIB'}) ${levelsLetter.length>0 ? levelsLetter : 'Clase'} ${numberun ? numberun : 'Número U.N.'}, ${data.packingGroup ? data.packingGroup : 'Packing group'} (${data.name ? data.name : 'Nombre del residuo'})`

                    if (Object.entries(data.errors).length) {
                        if (data.name!=='')                 this.form.errors.name                       = ''
                        if (data.transportname!==null)      this.form.errors.transportname              = null
                        if (data.numberun!==null)           this.form.errors.numberun                   = null
                        if (data.levels.length > 0)         this.form.errors.levels                     = null
                        if (data.hazards.length > 0)        this.form.errors.hazards                    = null
                        if (data.integratedTransportName)   this.form.errors.integratedTransportName    = null
                    }
                }
            }
        }
    }
</script>