<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.manifests.cancelled.index')">
                        Manifiestos cancelados
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.manifests.cancelled.show', manifest.id)">
                        {{manifest.folio}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Remanifestando
                </breadcumb-item>
            </breadcumb-nav>
        </template>
        
        <div class="mx-auto p-4">
            <form @submit.prevent="submit" enctype="multipart/form-data">

                <div v-show="generatorsLoaded" class="shadow sm:rounded-md bg-white">
                    <div class="h-100 flex justify-content-center items-center">
                        <Loader width="100" height="100"></Loader>
                    </div>
                </div>

                <div v-show="!generatorsLoaded" class="shadow sm:rounded-md bg-white">

                    <div class="px-6 pt-6 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="subsidiary"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Sucursal</label>
                                <selectize
                                    :options="subsidiaries"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione una sucursal.'"
                                    @input="subsidiarySelected"
                                    :value="setSubsidiary" label="descriptive_name" :keys="['descriptive_name', 'id']"></selectize>
                                <div
                                    v-if="form.errors.subsidiary"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.subsidiary }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 py-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

                            <div class="col-span-1">
                                <label
                                    for="folio"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Folio</label>
                                <input
                                    id="folio"
                                    type="text"
                                    :disabled="form.processing"
                                    v-model="form.folio"
                                    @keyup="existence"
                                    @paste="existence"
                                    @cut="existence"
                                    autocomplete="givenfolio"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'curson-default'" />
                                <div
                                    v-if="form.errors.folio"
                                    class="text-red-500 text-xs mt-1 mt-1">{{ form.errors.folio }}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="typeWaste"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Tipo de residuos</label>
                                <selectize
                                    :options="typeWastes"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un tipo de residuo.'"
                                    @input="typeWasteSelected"
                                    :value="setTypeWaste"></selectize>
                                <div 
                                    v-if="form.errors.typeWaste" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.typeWaste }}</div>
                            </div>

                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa generadora</label>
                                <selectize
                                    :options="generators"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione una empresa generadora.'"
                                    @input="generatorSelected"
                                    :value="setGenerator"
                                    :busy="busyGenerators"></selectize>
                                <div 
                                    v-if="form.errors.generator" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.generator }}</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="carrier"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa transportista</label>
                                <selectize
                                    :options="carriers"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione una empresa transportista.'"
                                    @input="carrierSelected"
                                    :value="setCarrier"
                                    :busy="busyCarriers"></selectize>
                                <div
                                    v-if="form.errors.carrier" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.carrier }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="recipient"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa destinataria</label>
                                <selectize
                                    :options="recipients"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione una empresa destinataria.'"
                                    @input="recipientSelected"
                                    :value="setRecipient"
                                    :busy="busyRecipients"></selectize>
                                <div 
                                    v-if="form.errors.recipient" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.recipient }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-show="generatorsLoaded" class="shadow sm:rounded-md bg-white mt-4">
                    <div class="h-56 flex justify-content-center items-center">
                        <Loader width="100" height="100"></Loader>
                    </div>
                </div>

                <div v-show="!generatorsLoaded" class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 py-6">
                        <h4 class="font-semibold mb-2">Residuo #1</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteOne"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <selectize
                                    :options="wastes"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un nombre de residuo'"
                                    @input="wasteOneSelected"
                                    :value="setWasteOne"
                                    :busy="busyWastes"></selectize>
                                <div 
                                    v-if="form.errors.wasteId" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.wasteId }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-show="generatorsLoaded" class="shadow sm:rounded-md bg-white mt-4">
                    <div class="h-56 flex justify-content-center items-center">
                        <Loader width="100" height="100"></Loader>
                    </div>
                </div>

                <div v-show="!generatorsLoaded" class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 py-6">
                        <h4 class="font-semibold mb-2">Residuo #2</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteTwo"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <selectize
                                    :options="wastes"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un nombre de residuo'"
                                    @input="wasteTwoSelected"
                                    :value="setWasteTwo"
                                    :busy="busyWastes"></selectize>
                                <div 
                                    v-if="form.errors.wasteId" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.wasteId }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-show="generatorsLoaded" class="shadow sm:rounded-md bg-white mt-4">
                    <div class="h-56 flex justify-content-center items-center">
                        <Loader width="100" height="100"></Loader>
                    </div>
                </div>

                <div v-show="!generatorsLoaded" class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 py-6">
                        <h4 class="font-semibold mb-2">Residuo #3</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteThree"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <selectize
                                    :options="wastes"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un nombre de residuo'"
                                    @input="wasteThreeSelected"
                                    :value="setWasteThree"
                                    :busy="busyWastes"></selectize>
                                <div 
                                    v-if="form.errors.wasteId" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.wasteId }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-show="generatorsLoaded" class="shadow sm:rounded-md bg-white mt-4">
                    <div class="h-56 flex justify-content-center items-center">
                        <Loader width="100" height="100"></Loader>
                    </div>
                </div>

                <div v-show="!generatorsLoaded" class="shadow sm:rounded-md bg-white mt-4">

                    <div class="px-6 py-6">
                        <h4 class="font-semibold mb-2">Residuo #4</h4>
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="wasteFour"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Nombre del residuo</label>
                                <selectize
                                    :options="wastes"
                                    :disabled="form.processing"
                                    :placeholder="'Seleccione un nombre de residuo'"
                                    @input="wasteFourSelected"
                                    :value="setWasteFour"
                                    :busy="busyWastes"></selectize>
                                <div 
                                    v-if="form.errors.wasteId" 
                                    class="text-red-500 text-xs mt-1">{{ form.errors.wasteId }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="pt-6 text-right space-x-4">
                    <link-light :href="route('dashboard.manifests.cancelled.index')" :disabled="form.processing">Cancelar</link-light>
                    <btn-success @click="form.onlySave=true" type="submit" :disabled="form.processing">
                        <spin class="text-white" v-show="form.processing && form.onlySave == true"></spin>
                        Agregar
                    </btn-success>
                    <btn-primary @click="form.onlySave=false" type="submit" :disabled="form.processing">
                        <spin class="text-white" v-show="form.processing && form.onlySave == false"></spin>
                        Imprimir
                    </btn-primary>
                </div>
            </form>
        </div>
    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight        from '@/Components/Links/Light'
    import BtnSuccess       from '@/Components/Buttons/Success'
    import BtnPrimary       from '@/Components/Buttons/Primary'
    import Selectize        from '@/Components/Inputs/Selectize'
    import Date             from '@/Components/Inputs/Date'
    import Spin             from '@/Components/Spin'
    import Loader           from '@/Components/Loader'
    
    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            BtnSuccess,
            BtnPrimary,
            Selectize,
            Date,
            Spin,
            Loader
        },
        props: ['manifest', 'subsidiaries'],
        data() {
            return {
                form: this.$inertia.form({
                    onlySave:                   false,
                    manifestId:                 null,
                    subsidiary:                 null,
                    folio:                      null,
                    typeWaste:                  null,
                    generator:                  null,
                    generatorName:              null,
                    carrier:                    null,
                    recipient:                  null,
                    wasteOneId:                 null,
                    wasteTwoId:                 null,
                    wasteThreeId:               null,
                    wasteFourId:                null,
                }),
                typeWastes:                 [{id: '1', name: 'Peligrosos'}, {id: '2', name: 'No peligrosos'}],
                carriers:                   [],
                recipients:                 [],
                generators:                 [],
                wastes:                     [],
                setSubsidiary:              null,
                setGenerator:               null,
                setCarrier:                 null,
                setRecipient:               null,
                setTypeWaste:               null,
                setWasteOne:                null,
                setWasteTwo:                null,
                setWasteThree:              null,
                setWasteFour:               null,
                busyGenerators:             false,
                busyCarriers:               false,
                busyRecipients:             false,
                busyWastes:                 false,
                isFirstTime:                true,
                generatorsLoaded:           true
            } 
        },
        methods: {
            submit() {
                this.form.post(route('dashboard.manifests.cancelled.remanifest.store'), this.form, {forceFormData: true})
            },
            existence: _.debounce(async function () {
                const response = await axios.post(route('api.manifests.open.existence'), this.form)
                if (response.data.message) {
                    this.form.errors.folio = response.data.message
                } else {
                    this.form.errors.folio = null
                }
            }, 300),
            init() {
                this.form.manifestId        = this.manifest.id
                this.form.subsidiary        = this.manifest.subsidiary.id
                this.setSubsidiary          = this.form.subsidiary
                this.form.typeWaste         = this.manifest.type_waste == 'Peligrosos'  ? 1 : 2
                this.typeWasteSelected(this.form.typeWaste)
                this.setTypeWaste           = this.form.typeWaste                       ? this.form.typeWaste : -1
            },
            subsidiarySelected(val) {
                this.form.subsidiary    = val ? val.id : null
            },
            carrierSelected(val) {
                this.form.carrier       = val ? val.id : null
            },
            recipientSelected(val) {
                this.form.recipient     = val ? val.id : null
            },
            typeWasteSelected(val) {
                this.form.generator     = null
                this.form.generatorName = null
                this.setGenerator       = -1
                this.form.carrier       = null
                this.setCarrier         = -1
                this.form.recipient     = null
                this.setRecipient       = -1
                this.wastes             = []
                this.cleanWasteInputs()

                if (val) {
                    this.form.typeWaste     = typeof val == 'object' ? val.id : val
                    
                    this.getCarriersByTypeWaste(this.form.typeWaste)
                    this.getRecipientsByTypeWaste(this.form.typeWaste)
                    this.getGeneratorsByTypeWaste(this.form.typeWaste)
                } else {
                    this.form.typeWaste     = null
                }
            },
            async getCarriersByTypeWaste(typeWaste) {
                this.busyCarriers = true
                await axios.post(route('api.carriers.typeWaste'), {typeWaste: typeWaste})
                .then(response => {
                    this.carriers           = response?.data       ? response.data  : []
                    this.busyCarriers       = false
                })
            },
            async getRecipientsByTypeWaste(typeWaste) {
                this.busyRecipients = true
                await axios.post(route('api.recipients.typeWaste'), {typeWaste: typeWaste})
                .then(response => {
                    this.recipients         = response?.data         ? response.data : []
                    this.busyRecipients     = false
                })
            },
            async getGeneratorsByTypeWaste(typeWaste) {
                this.busyGenerators = true
                await axios.post(route('api.clients.typeWaste'), {typeWaste: typeWaste})
                .then(response => {
                    this.generators         = response?.data         ? response.data : []
                    this.generatorsLoaded   = this.generators.length ? false         : true
                    this.busyGenerators     = false
                })
            },
            generatorSelected(val) {
                this.busyWastes             = true
                this.cleanWasteInputs()
                if (val) {
                    this.form.generator     = val.id
                    this.form.generatorName = val.name
                    this.wastes             = val.wastes.length ? val.wastes  : []

                } else {
                    this.form.generator     = null
                    this.form.generatorName = null
                    this.wastes             = []
                }

                this.busyWastes             = false
            },
            wasteOneSelected(val) {
                this.form.wasteOneId      = val ? val.id : null
            },
            wasteTwoSelected(val) {
                this.form.wasteTwoId      = val ? val.id : null
            },
            wasteThreeSelected(val) {
                this.form.wasteThreeId    = val ? val.id : null
            },
            wasteFourSelected(val) {
                this.form.wasteFourId     = val ? val.id : null
            },
            cleanWasteInputs() {
                this.form.wasteOneId    = null
                this.setWasteOne        = -1
                this.form.wasteTwoId    = null
                this.setWasteTwo        = -1
                this.form.wasteThreeId  = null
                this.setWasteThree      = -1
                this.form.wasteFourId   = null
                this.setWasteFour       = -1
            }
        },
        mounted() {
            this.init()
        },
        updated() {
            this.setSubsidiary        = this.form.subsidiary                ? this.form.subsidiary                  : -1
            this.setTypeWaste         = this.form.typeWaste                 ? this.form.typeWaste                   : -1
            this.setGenerator         = this.form.generator                 ? this.form.generator                   : -1
            this.setRecipient         = this.form.recipient                 ? this.form.recipient                   : -1
            this.setCarrier           = this.form.carrier                   ? this.form.carrier                     : -1
            this.setWasteOne          = this.form.wasteOneId                ? this.form.wasteOneId                  : -1
            this.setWasteTwo          = this.form.wasteTwoId                ? this.form.wasteTwoId                  : -1
            this.setWasteThree        = this.form.wasteThreeId              ? this.form.wasteThreeId                : -1
            this.setWasteFour         = this.form.wasteFourId               ? this.form.wasteFourId                 : -1
        },
        watch: {
            generators: {
                deep: true,
                handler(data) {
                    if (data.length && this.isFirstTime) {
                        const generator             = this.manifest?.client       ? this.manifest.client       : this.manifest.generator
                        this.form.generator         = generator.id
                        this.form.generatorName     = generator.data.name
                        this.setGenerator           = generator                   ? generator.id                  : -1
                        this.form.carrier           = this.manifest?.carrier      ? this.manifest.carrier.id      : null
                        this.setCarrier             = this.manifest?.carrier      ? this.manifest.carrier.id      : -1
                        this.form.recipient         = this.manifest?.recipient    ? this.manifest.recipient.id    : null
                        this.setRecipient           = this.manifest?.recipient    ? this.manifest.recipient.id    : -1
                    
                        this.wastes                 = generator.wastes

                        for (let i = 0; i < this.manifest.manifest_wastes.length; i++) {
                            const manifestWaste                     = this.manifest.manifest_wastes[i]
                            
                            if (i == 0) {
                                this.form.wasteOneId               = manifestWaste.waste               != null ? manifestWaste.waste.id : null
                                this.setWasteOne                   = manifestWaste.waste               != null ? manifestWaste.waste.id : -1
                            }
    
                            if (i == 1) {
                                this.form.wasteTwoId               = manifestWaste.waste               != null ? manifestWaste.waste.id : null
                                this.setWasteTwo                   = manifestWaste.waste               != null ? manifestWaste.waste.id : -1
                            }
    
                            if (i == 2) {
                                this.form.wasteThreeId               = manifestWaste.waste             != null ? manifestWaste.waste.id : null
                                this.setWasteThree                   = manifestWaste.waste             != null ? manifestWaste.waste.id : -1
                            }
    
                            if (i == 3) {
                                this.form.wasteFourId               = manifestWaste.waste              != null ? manifestWaste.waste.id : null
                                this.setWasteFour                   = manifestWaste.waste              != null ? manifestWaste.waste.id : -1
                            }
                        }

                        this.isFirstTime        = false
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .h-100 {
        height: 28.425rem;
    }
</style>