<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.consolidated.finalize.index')">
                        Consolidados por finalizar
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item>
                    <inertia-link :href="route('dashboard.consolidated.finalize.show', consolidated.id)">
                        {{consolidated.folio}}
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Editando
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="mx-auto p-4">
            <form @submit.prevent="submit" enctype="multipart/form-data">
                <div class="shadow sm:rounded-md bg-white">

                    <div class="px-6 pb-3 pt-6">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

                            <div class="col-span-1">
                                <label
                                    for="folio"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Folio</label>
                                <input
                                    id="folio"
                                    type="text"
                                    required
                                    :disabled="form.processing"
                                    v-model="form.folio"
                                    @keyup="existence"
                                    @cut="existence"
                                    @paste="existence"
                                    tabindex="1"
                                    autocomplete="givenfolio"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.folio"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.folio }}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="generator"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa generadora</label>

                                <selectize
                                    :isRequired="true"
                                    :options="subsidiaries"
                                    label="descriptive_name"
                                    :keys="['descriptive_name', 'id']"
                                    placeholder="Seleccione una empresa generadora"
                                    @input="generatorSelected"
                                    :value="setGenerator"></selectize>
                                <div
                                    v-if="form.errors.generator"
                                        class="text-red-500 text-xs mt-1">{{ form.errors.generator }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-2 sm:grid-cols-2 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="carrier"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa transportista</label>

                                <selectize
                                    :isRequired="true"
                                    :options="carriers"
                                    label="full_name"
                                    :keys="['full_name', 'id']"
                                    placeholder="Seleccione una empresa transportista"
                                    @input="carrierSelected"
                                    :value="setCarrier"></selectize>
                                <div
                                    v-if="form.errors.carrier"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.carrier }}</div>
                            </div>

                            <div class="col-span-1">
                                <label
                                    for="recipient"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Empresa destinataria</label>

                                <selectize
                                    :isRequired="true"
                                    :options="recipients"
                                    label="full_name"
                                    :keys="['full_name', 'id']"
                                    placeholder="Seleccione una empresa destinataria"
                                    @input="recipientSelected"
                                    :value="setRecipient"></selectize>
                                <div
                                    v-if="form.errors.recipient"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.recipient }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <label
                                    for="departureAt"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Fecha de salida</label>
                                <input
                                    id="departureAt"
                                    type="date"
                                    :disabled="form.processing"
                                    v-model="form.departureAt"
                                    autocomplete="givendepartureAt"
                                    class="mt-1 focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                    :class="form.processing ? 'cursor-not-allowed' : 'cursor-default'" />
                                <div
                                    v-if="form.errors.departureAt"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.departureAt }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <div v-if="consolidated.file" class="pb-6">
                                    <div class="mb-1 block text-sm font-semibold text-gray-700">Consolidado adjuntado</div>

                                    <a target="_blank" :href="`${filesUrl}${consolidated.file}`" class="flex items-center px-3 p-1.5 shadow-sm sm:rounded-md bg-white border border-gray-300 text-orange-400 hover:text-orange-600 transition duration-500 ease">
                                        {{currentFileName}}
                                    </a>
                                </div>

                                <label
                                    for="file"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Adjuntar consolidado</label>

                                <date :open="showConsolidatedFileModal" @click="openConsolidatedFileModal" @closeFileModal="closeConsolidatedFileModal" @returnFile="getConsolidatedFile"></date>

                                <div
                                    v-if="form.errors.consolidatedFile"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.consolidatedFile }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-3">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <div v-if="consolidated.carrier_invoice" class="pb-6">
                                    <div class="mb-1 block text-sm font-semibold text-gray-700">Factura del transportista</div>

                                    <a target="_blank" :href="`${filesUrl}${consolidated.carrier_invoice}`" class="flex items-center px-3 p-1.5 shadow-sm sm:rounded-md bg-white border border-gray-300 text-orange-400 hover:text-orange-600 transition duration-500 ease">
                                        {{carrierInvoiceName}}
                                    </a>
                                </div>

                                <label
                                    for="file"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Adjuntar factura del transportista</label>

                                <date :open="showCarrierInvoiceFileModal" @click="openCarrierInvoiceFileModal" @closeFileModal="closeCarrierInvoiceFileModal" @returnFile="getCarrierInvoiceFile"></date>

                                <div
                                    v-if="form.errors.carrierInvoiceFile"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.carrierInvoiceFile }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="px-6 pt-3 pb-6">
                        <div class="grid grid-cols-1 gap-4">
                            <div class="col-span-1">
                                <div v-if="consolidated.recipient_invoice" class="pb-6">
                                    <div class="mb-1 block text-sm font-semibold text-gray-700">Factura del destinatario</div>

                                    <a target="_blank" :href="`${filesUrl}${consolidated.recipient_invoice}`" class="flex items-center px-3 p-1.5 shadow-sm sm:rounded-md bg-white border border-gray-300 text-orange-400 hover:text-orange-600 transition duration-500 ease">
                                        {{recipientInvoiceName}}
                                    </a>
                                </div>

                                <label
                                    for="file"
                                    class="mb-1 block text-sm font-semibold text-gray-700">Adjuntar factura del destinatario</label>

                                <date :open="showRecipientInvoiceFileModal" @click="openRecipientInvoiceFileModal" @closeFileModal="closeRecipientInvoiceFileModal" @returnFile="getRecipientInvoiceFile"></date>

                                <div
                                    v-if="form.errors.recipientInvoiceFile"
                                    class="text-red-500 text-xs mt-1">{{ form.errors.recipientInvoiceFile }}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="pt-4 space-x-4 text-right">
                    <button
                        @click="toggleAddBaleWasteModal"
                        type="button"
                        :disabled="form.processing || btnConsolidatedBaleWastesDisabled"
                        :class="form.processing || btnConsolidatedBaleWastesDisabled ? 'cursor-not-allowed' : 'cursor-pointer'"
                        class="bg-blue-500 border-blue-500 hover:bg-blue-600 active:bg-blue-600 focus:border-blue-600 text-white inline-flex items-center px-4 py-2 border sm:rounded-md font-semibold text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                        Consolidar pacas
                    </button>
                    <button
                        @click="toggleWasteModal"
                        type="button"
                        :disabled="form.processing || btnConsolidatedWastesDisabled"
                        :class="form.processing || btnConsolidatedWastesDisabled ? 'cursor-not-allowed' : 'cursor-pointer'"
                        class="bg-blue-500 border-blue-500 hover:bg-blue-600 active:bg-blue-600 focus:border-blue-600 text-white inline-flex items-center px-4 py-2 border sm:rounded-md font-semibold text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                        Consolidar residuos
                    </button>
                </div>

                <div v-show="form.wasteOneData.length" class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                    <div class="px-4 py-5 sm:px-6 flex justify-between">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Residuo #1
                        </h3>

                        <Dropdown align="right" width="48">
                            <template #trigger>
                                <button type="button">
                                    <DotsVerticalIcon class="h-5 w-5"></DotsVerticalIcon>
                                </button>
                            </template>

                            <template #content>
                                <div v-if="form.wasteOneBale">
                                    <DropdownLink @click="openEditBaleWasteModal(1)">Editar</DropdownLink>
                                </div>
                                <div v-else>
                                    <DropdownLink @click="openEditWasteModal(1)">Editar</DropdownLink>
                                </div>
                                <DropdownLink @click="toggleDeleteWasteModal(1)">Eliminar</DropdownLink>
                            </template>
                        </Dropdown>
                    </div>
                    <div class="border-t border-gray-200">
                        <dl>
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Nombre del residuo
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                    <span>{{form.wasteOneGenericName}}</span>
                                </dd>
                            </div>
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Contenedor
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                    <span>{{form.wasteOneContainerName}}</span>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div v-show="form.wasteTwoData.length" class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                    <div class="px-4 py-5 sm:px-6 flex justify-between">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Residuo #2
                        </h3>

                        <Dropdown align="right" width="48">
                            <template #trigger>
                                <button type="button">
                                    <DotsVerticalIcon class="h-5 w-5"></DotsVerticalIcon>
                                </button>
                            </template>

                            <template #content>
                                <div v-if="form.wasteTwoBale">
                                    <DropdownLink @click="openEditBaleWasteModal(2)">Editar</DropdownLink>
                                </div>
                                <div v-else>
                                    <DropdownLink @click="openEditWasteModal(2)">Editar</DropdownLink>
                                </div>
                                <DropdownLink @click="toggleDeleteWasteModal(2)">Eliminar</DropdownLink>
                            </template>
                        </Dropdown>
                    </div>
                    <div class="border-t border-gray-200">
                        <dl>
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Nombre del residuo
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                    <span>{{form.wasteTwoGenericName}}</span>
                                </dd>
                            </div>
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Contenedor
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                    <span>{{form.wasteTwoContainerName}}</span>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div v-show="form.wasteThreeData.length" class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                    <div class="px-4 py-5 sm:px-6 flex justify-between">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Residuo #3
                        </h3>

                        <Dropdown align="right" width="48">
                            <template #trigger>
                                <button type="button">
                                    <DotsVerticalIcon class="h-5 w-5"></DotsVerticalIcon>
                                </button>
                            </template>

                            <template #content>
                                <div v-if="form.wasteThreeBale">
                                    <DropdownLink @click="openEditBaleWasteModal(3)">Editar</DropdownLink>
                                </div>
                                <div v-else>
                                    <DropdownLink @click="openEditWasteModal(3)">Editar</DropdownLink>
                                </div>
                                <DropdownLink @click="toggleDeleteWasteModal(3)">Eliminar</DropdownLink>
                            </template>
                        </Dropdown>
                    </div>
                    <div class="border-t border-gray-200">
                        <dl>
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Nombre del residuo
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                    <span>{{form.wasteThreeGenericName}}</span>
                                </dd>
                            </div>
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Contenedor
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                    <span>{{form.wasteThreeContainerName}}</span>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div v-show="form.wasteFourData.length" class="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                    <div class="px-4 py-5 sm:px-6 flex justify-between">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Residuo #4
                        </h3>

                        <Dropdown align="right" width="48">
                            <template #trigger>
                                <button type="button">
                                    <DotsVerticalIcon class="h-5 w-5"></DotsVerticalIcon>
                                </button>
                            </template>

                            <template #content>
                                <div v-if="form.wasteFourBale">
                                    <DropdownLink @click="openEditBaleWasteModal(4)">Editar</DropdownLink>
                                </div>
                                <div v-else>
                                    <DropdownLink @click="openEditWasteModal(4)">Editar</DropdownLink>
                                </div>
                                <DropdownLink @click="toggleDeleteWasteModal(4)">Eliminar</DropdownLink>
                            </template>
                        </Dropdown>
                    </div>
                    <div class="border-t border-gray-200">
                        <dl>
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Nombre del residuo
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                    <span>{{form.wasteFourGenericName}}</span>
                                </dd>
                            </div>
                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt class="text-sm font-medium text-gray-500">
                                    Contenedor
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 uppercase">
                                    <span>{{form.wasteFourContainerName}}</span>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <div class="pt-4 text-right space-x-4">
                    <link-light :href="route('dashboard.consolidated.finalize.index')" :disabled="form.processing">Cancelar</link-light>
                    <button
                        type="submit"
                        @click="form.onlyEdit = false"
                        :disabled="form.processing || btnSaveDisabled"
                        :class="form.processing || btnSaveDisabled ? 'cursor-not-allowed' : 'cursor-pointer'"
                        class="bg-yellow-500 border-yellow-500 hover:bg-yellow-600 active:bg-yellow-600 focus:border-yellow-600 text-white inline-flex items-center px-4 py-2 border sm:rounded-md font-semibold text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                        <spin v-show="form.processing && !form.onlyEdit"></spin>
                        Actualizar
                    </button>
                    <button
                        type="submit"
                        @click="form.onlyEdit = true"
                        :disabled="form.processing || btnSaveDisabled"
                        :class="form.processing || btnSaveDisabled ? 'cursor-not-allowed' : 'cursor-pointer'"
                        class="bg-blue-500 border-blue-500 hover:bg-blue-600 active:bg-blue-600 focus:border-blue-600 text-white inline-flex items-center px-4 py-2 border sm:rounded-md font-semibold text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                        <spin v-show="form.processing && form.onlyEdit"></spin>
                        Solo editar
                    </button>
                </div>

            </form>
        </div>

        <AddWasteModal ref="addWasteModal" :show="showAddWasteModal" maxWidth="6xl">
            <template #title>
                <div class="text-2xl leading-6 font-bold text-gray-900 uppercase">Consolidar residuos</div>
            </template>
            <template #content>
                <div class="grid grid-cols-1 gap-4">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-10">
                            <label
                                for="generic"
                                class="mb-1 block text-sm font-semibold text-gray-700">Grupo generico</label>
                            <selectize
                                :options="generics"
                                placeholder="Seleccione un grupo generico"
                                @input="genericSelected"></selectize>
                        </div>

                        <div class="col-span-2">
                            <label
                                for="generic"
                                class="mb-1 block text-sm font-semibold text-gray-700">Residuos</label>
                            <div class="py-2 text-sm text-gray-700">
                                {{remainingWastes}} restantes
                            </div>
                        </div>
                    </div>

                    <div class="col-span-1">
                        <div class="flex md:w-56 w-full">
                            <input
                                placeholder="Buscar"
                                class="sm:rounded border-gray-200 border px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                                v-model="formGeneric.searchText"
                                @keyup="searchGeneric"
                                @paste="searchGeneric"
                                @cut="searchGeneric"
                                :disabled="formGeneric.id==null"
                                :class="formGeneric.id==null ? 'cursor-not-allowed' : 'cursor-default'" />
                        </div>
                    </div>

                    <div class="col-span-1">
                        <div class="bg-white overflow-hidden shadow sm:rounded">
                            <table-container :heading="heading" :fixedHeaders="loading || wastes?.data?.length == 0 && !loading ? [] : [0, 1, 7]">
                                <tbody ref="list">
                                    <tr v-show="loading">
                                        <td :colspan="heading.length" class="h-96">
                                            <loader width="100" height="100"></loader>
                                        </td>
                                    </tr>

                                    <tr v-if="wastes?.data?.length == 0 && !loading" class="text-center">
                                        <table-body-item :colspan="heading.length">
                                            No se encontraron resultados.
                                        </table-body-item>
                                    </tr>

                                    <tr v-else-if="wastes?.data?.length > 0 && !loading" v-for="(waste, index) in wastes?.data" :key="index" class="hover:bg-gray-50">
                                        <table-body-item :fixed="true" :firstColumn="true">
                                            <div class="flex items-start">
                                                <div class="flex items-center">
                                                    <input
                                                        :ref="'checkbox_'+waste.id"
                                                        type="checkbox"
                                                        :disabled="processingWaste"
                                                        class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                                        :class="processingWaste ? 'cursor-not-allowed' : 'cursor-default'"
                                                        @click="changeInput(waste, 'checkbox_', 'input_')" />
                                                </div>
                                            </div>
                                        </table-body-item>
                                        <table-body-item :fixed="true">
                                            {{ waste.folio }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.generator }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.waste.name }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.quantity }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.container.name }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.total_quantity }}
                                        </table-body-item>
                                        <table-body-item :fixed="true" :lastColumn="true">
                                            <input
                                                :ref="'input_'+waste.id"
                                                type="number"
                                                :disabled="processingWaste || true"
                                                autocomplete="givenname"
                                                @keypress="keypressInput(waste, 'checkbox_', 'input_')"
                                                class="focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                                :class="processingWaste ? 'cursor-default' : 'cursor-not-allowed'" />
                                        </table-body-item>
                                    </tr>
                                </tbody>
                            </table-container>

                            <div v-show="wastes?.data?.length" class="py-4 px-6">
                                <pagination :items="wastes" @changePage="onChangePage" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="space-x-4">
                    <Button
                        :style="'gray'"
                        :mode="'light'"
                        :disabled="processingWaste"
                        @click="closeWasteModal">
                        Cancelar
                    </Button>
                    <button
                        @click="addWaste"
                        type="button"
                        :disabled="processingWaste || btnAddWasteDisabled"
                        :class="processingWaste || btnAddWasteDisabled ? 'cursor-not-allowed' : 'cursor-pointer'"
                        class="bg-green-500 border-green-500 hover:bg-green-600 active:bg-green-600 focus:border-green-600 text-white inline-flex items-center px-4 py-2 border sm:rounded-md font-semibold text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                        <spin v-show="processingWaste"></spin>
                        Agregar
                    </button>
                </div>
            </template>
        </AddWasteModal>

        <DeleteWasteModal :show="showDeleteWasteModal">
            <template #title>
                <div class="text-2xl leading-6 font-bold text-gray-900 uppercase">Eliminar residuo #{{numberWaste}}</div>
            </template>
            <template #content>
                 <p class="text-xl leading-6 font-bold text-gray-800">¿Estás seguro de que deseas eliminar este residuo?</p>
                <p class="text-sm text-gray-400">Esta acción no se puede deshacer.</p>
            </template>
            <template #footer>
                <div class="space-x-4">
                    <Button
                        :style="'gray'"
                        :mode="'light'"
                        :disabled="processingDeleteWaste"
                        @click="toggleDeleteWasteModal(0)">
                        Cancelar
                    </Button>

                    <button
                        @click="deleteWaste"
                        type="button"
                        :disabled="processingDeleteWaste"
                        :class="processingDeleteWaste ? 'cursor-not-allowed' : 'cursor-pointer'"
                        class="bg-green-500 border-green-500 hover:bg-green-600 active:bg-green-600 focus:border-green-600 text-white inline-flex items-center px-4 py-2 border sm:rounded-md font-semibold text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                        <spin v-show="processingDeleteWaste"></spin>
                        Aceptar
                    </button>
                </div>
            </template>
        </DeleteWasteModal>

        <EditWasteModal :show="showEditWasteModal" maxWidth="6xl">
            <template #title>
                <div class="text-2xl leading-6 font-bold text-gray-900 uppercase">Editar residuo #{{numberWaste}}</div>
            </template>
            <template #content>
                <div class="grid grid-cols-1 gap-4">
                    <div class="grid grid-cols-1 gap-4">
                        <div class="col-span-1">
                            <label
                                for="generic"
                                class="mb-1 block text-sm font-semibold text-gray-700">Grupo generico</label>
                            <input
                                class="sm:rounded border-gray-200 border px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none cursor-not-allowed"
                                :value="numberGenericWaste"
                                disabled />
                        </div>
                    </div>

                    <div class="col-span-1">
                        <div class="flex md:w-56 w-full">
                            <input
                                placeholder="Buscar"
                                class="sm:rounded border-gray-200 border px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                                v-model="formGeneric.searchText"
                                @keyup="searchGeneric"
                                @cut="searchGeneric"
                                @paste="searchGeneric"
                                :disabled="formGeneric.id==null"
                                :class="formGeneric.id==null ? 'cursor-not-allowed' : 'cursor-default'" />
                        </div>
                    </div>

                    <div class="col-span-1">
                        <div class="bg-white overflow-hidden shadow sm:rounded">
                            <table-container :heading="headingEdit" :fixedHeaders="loading || wastes?.data?.length == 0 && !loading ? [] : [0, 1, 9]">
                                <tbody ref="list">
                                    <tr v-show="loading">
                                        <td :colspan="headingEdit.length" class="h-96">
                                            <loader width="100" height="100"></loader>
                                        </td>
                                    </tr>

                                    <tr v-if="wastes?.data?.length == 0 && !loading" class="text-center">
                                        <table-body-item :colspan="headingEdit.length">
                                            No se encontraron resultados.
                                        </table-body-item>
                                    </tr>

                                    <tr v-else-if="wastes?.data?.length > 0 && !loading" v-for="(waste, index) in wastes?.data" :key="index" class="hover:bg-gray-50">
                                        <table-body-item :fixed="true" :firstColumn="true">
                                            <div class="flex items-start">
                                                <div class="flex items-center">
                                                    <input
                                                        :ref="'edit_checkbox_'+waste.id"
                                                        type="checkbox"
                                                        :disabled="processingWaste"
                                                        class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                                        :class="processingWaste ? 'cursor-not-allowed' : 'cursor-default'"
                                                        @click="changeEditInput(waste, 'edit_checkbox_', 'edit_input_')" />
                                                </div>
                                            </div>
                                        </table-body-item>
                                        <table-body-item :fixed="true">
                                            {{ waste.folio }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.generator }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.waste.name }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.quantity }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.current_quantity }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.container.name }}
                                        </table-body-item>
                                         <table-body-item>
                                            {{ waste.total_quantity }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.current_total_quantity }}
                                        </table-body-item>
                                        <table-body-item :fixed="true" :lastColumn="true">
                                            <input
                                                :ref="'edit_input_'+waste.id"
                                                type="number"
                                                :disabled="processingWaste || true"
                                                autocomplete="givenname"
                                                @keypress="keypressInput(waste, 'edit_checkbox_', 'edit_input_')"
                                                @cut="keypressInput(waste, 'edit_checkbox_', 'edit_input_')"
                                                @paste="keypressInput(waste, 'edit_checkbox_', 'edit_input_')"
                                                @keydown.delete="keypressInput(waste, 'edit_checkbox_', 'edit_input_')"
                                                class="focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                                :class="processingWaste ? 'cursor-default' : 'cursor-not-allowed'" />
                                        </table-body-item>
                                    </tr>
                                </tbody>
                            </table-container>

                            <div v-show="wastes?.data?.length" class="py-4 px-6">
                                <pagination :items="wastes" @changePage="onChangePage" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="space-x-4">
                    <Button
                        :style="'gray'"
                        :mode="'light'"
                        :disabled="processingEditWaste"
                        @click="closeEditWasteModal">
                        Cancelar
                    </Button>
                    <button
                        type="button"
                        @click="editWaste"
                        :disabled="processingEditWaste || btnEditWasteDisabled"
                        :class="processingEditWaste || btnEditWasteDisabled ? 'cursor-not-allowed' : 'cursor-pointer'"
                        class="bg-yellow-500 border-yellow-500 hover:bg-yellow-600 active:bg-yellow-600 focus:border-yellow-600 text-white inline-flex items-center px-4 py-2 border sm:rounded-md font-semibold text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                        <spin v-show="processingEditWaste"></spin>
                        Actualizar
                    </button>
                </div>
            </template>
        </EditWasteModal>

        <AddBaleWasteModal ref="addWasteModal" :show="showAddBaleWasteModal" maxWidth="6xl">
            <template #title>
                <div class="text-2xl leading-6 font-bold text-gray-900 uppercase">Consolidar pacas</div>
            </template>
            <template #content>
                <div class="grid grid-cols-1 gap-4">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-10">
                            <label
                                for="generic"
                                class="mb-1 block text-sm font-semibold text-gray-700">Grupo generico</label>
                            <selectize
                                :options="generics"
                                placeholder="Seleccione un grupo generico"
                                @input="BaleGenericSelected"></selectize>
                        </div>

                        <div class="col-span-2">
                            <label
                                for="generic"
                                class="mb-1 block text-sm font-semibold text-gray-700">Residuos</label>
                            <div class="py-2 text-sm text-gray-700">
                                {{remainingWastes}} restantes
                            </div>
                        </div>
                    </div>

                    <div class="col-span-1">
                        <div class="flex md:w-56 w-full">
                            <input
                                placeholder="Buscar"
                                class="sm:rounded border-gray-200 border px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                                v-model="formBaleGeneric.searchText"
                                @keyup="searchBaleGeneric"
                                @paste="searchBaleGeneric"
                                @cut="searchBaleGeneric"
                                :disabled="formBaleGeneric.id==null"
                                :class="formBaleGeneric.id==null ? 'cursor-not-allowed' : 'cursor-default'" />
                        </div>
                    </div>

                    <div class="col-span-1">
                        <div class="bg-white overflow-hidden shadow sm:rounded">
                            <table-container :heading="heading" :fixedHeaders="loading || wastes?.data?.length == 0 && !loading ? [] : [0, 1, 7]">
                                <tbody ref="list">
                                    <tr v-show="loading">
                                        <td :colspan="heading.length" class="h-96">
                                            <loader width="100" height="100"></loader>
                                        </td>
                                    </tr>

                                    <tr v-if="wastes?.data?.length == 0 && !loading" class="text-center">
                                        <table-body-item :colspan="heading.length">
                                            No se encontraron resultados.
                                        </table-body-item>
                                    </tr>

                                    <tr v-else-if="wastes?.data?.length > 0 && !loading" v-for="(waste, index) in wastes?.data" :key="index" class="hover:bg-gray-50">
                                        <table-body-item :fixed="true" :firstColumn="true">
                                            <div class="flex items-start">
                                                <div class="flex items-center">
                                                    <input
                                                        :ref="'checkbox_bale_'+waste.id"
                                                        type="checkbox"
                                                        :disabled="processingAddBaleWaste"
                                                        class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                                        :class="processingAddBaleWaste ? 'cursor-not-allowed' : 'cursor-default'"
                                                        @click="changeBaleInput(waste, 'checkbox_bale_', 'input_bale_')" />
                                                </div>
                                            </div>
                                        </table-body-item>
                                        <table-body-item :fixed="true">
                                            {{ waste.folio }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.generator }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.waste.name }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.quantity }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.container.name }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.total_quantity }}
                                        </table-body-item>
                                        <table-body-item :fixed="true" :lastColumn="true">
                                            <input
                                                :ref="'input_bale_'+waste.id"
                                                type="number"
                                                :disabled="processingAddBaleWaste || true"
                                                autocomplete="givenname"
                                                @keypress="keypressBaleInput(waste, 'checkbox_bale_', 'input_bale_')"
                                                class="focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                                :class="processingAddBaleWaste ? 'cursor-default' : 'cursor-not-allowed'" />
                                        </table-body-item>
                                    </tr>
                                </tbody>
                            </table-container>

                            <div v-show="wastes?.data?.length" class="py-4 px-6">
                                <pagination :items="wastes" @changePage="onChangePageBale" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="space-x-4">
                    <Button
                        :style="'gray'"
                        :mode="'light'"
                        :disabled="processingAddBaleWaste"
                        @click="closeAddBaleWasteModal">
                        Cancelar
                    </Button>
                    <button
                        @click="addBaleWaste"
                        type="button"
                        :disabled="processingAddBaleWaste || btnAddBaleWasteDisabled"
                        :class="processingAddBaleWaste || btnAddBaleWasteDisabled ? 'cursor-not-allowed' : 'cursor-pointer'"
                        class="bg-green-500 border-green-500 hover:bg-green-600 active:bg-green-600 focus:border-green-600 text-white inline-flex items-center px-4 py-2 border sm:rounded-md font-semibold text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                        <spin v-show="processingAddBaleWaste"></spin>
                        Agregar
                    </button>
                </div>
            </template>
        </AddBaleWasteModal>

        <EditBaleWasteModal :show="showEditBaleWasteModal" maxWidth="6xl">
            <template #title>
                <div class="text-2xl leading-6 font-bold text-gray-900 uppercase">Editar residuo #{{numberWaste}}</div>
            </template>
            <template #content>
                <div class="grid grid-cols-1 gap-4">
                    <div class="grid grid-cols-1 gap-4">
                        <div class="col-span-1">
                            <label
                                for="generic"
                                class="mb-1 block text-sm font-semibold text-gray-700">Grupo generico</label>
                            <input
                                class="sm:rounded border-gray-200 border px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none cursor-not-allowed"
                                :value="numberGenericWaste"
                                disabled />
                        </div>
                    </div>

                    <div class="col-span-1">
                        <div class="flex md:w-56 w-full">
                            <input
                                placeholder="Buscar"
                                class="sm:rounded border-gray-200 border px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                                v-model="formBaleGeneric.searchText"
                                @keyup="searchBaleGeneric"
                                @cut="searchBaleGeneric"
                                @paste="searchBaleGeneric"
                                :disabled="formBaleGeneric.id==null"
                                :class="formBaleGeneric.id==null ? 'cursor-not-allowed' : 'cursor-default'" />
                        </div>
                    </div>

                    <div class="col-span-1">
                        <div class="bg-white overflow-hidden shadow sm:rounded">
                            <table-container :heading="headingEdit" :fixedHeaders="loading || wastes?.data?.length == 0 && !loading ? [] : [0, 1, 9]">
                                <tbody ref="list">
                                    <tr v-show="loading">
                                        <td :colspan="headingEdit.length" class="h-96">
                                            <loader width="100" height="100"></loader>
                                        </td>
                                    </tr>

                                    <tr v-if="wastes?.data?.length == 0 && !loading" class="text-center">
                                        <table-body-item :colspan="headingEdit.length">
                                            No se encontraron resultados.
                                        </table-body-item>
                                    </tr>

                                    <tr v-else-if="wastes?.data?.length > 0 && !loading" v-for="(waste, index) in wastes?.data" :key="index" class="hover:bg-gray-50">
                                        <table-body-item :fixed="true" :firstColumn="true">
                                            <div class="flex items-start">
                                                <div class="flex items-center">
                                                    <input
                                                        :ref="'edit_checkbox_bale_'+waste.id"
                                                        type="checkbox"
                                                        :disabled="processingWaste"
                                                        class="focus:ring-transparent h-4 w-4 text-orange-600 border-gray-300 rounded"
                                                        :class="processingWaste ? 'cursor-not-allowed' : 'cursor-default'"
                                                        @click="changeEditBaleInput(waste, 'edit_checkbox_bale_', 'edit_input_bale_')" />
                                                </div>
                                            </div>
                                        </table-body-item>
                                        <table-body-item :fixed="true">
                                            {{ waste.folio }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.generator }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.waste.name }}
                                        </table-body-item>
                                         <table-body-item>
                                            {{ waste.quantity }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.current_quantity }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.container.name }}
                                        </table-body-item>
                                         <table-body-item>
                                            {{ waste.total_quantity }}
                                        </table-body-item>
                                        <table-body-item>
                                            {{ waste.current_total_quantity }}
                                        </table-body-item>
                                        <table-body-item :fixed="true" :lastColumn="true">
                                            <input
                                                :ref="'edit_input_bale_'+waste.id"
                                                type="number"
                                                :disabled="processingWaste || true"
                                                autocomplete="givenname"
                                                @keypress="keypressBaleInput(waste, 'edit_checkbox_bale_', 'edit_input_bale_')"
                                                @cut="keypressBaleInput(waste, 'edit_checkbox_bale_', 'edit_input_bale_')"
                                                @paste="keypressBaleInput(waste, 'edit_checkbox_bale_', 'edit_input_bale_')"
                                                @keydown="keypressBaleInput(waste, 'edit_checkbox_bale_', 'edit_input_bale_')"
                                                class="focus:ring-transparent focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 sm:rounded-md disabled:opacity-25"
                                                :class="processingWaste ? 'cursor-default' : 'cursor-not-allowed'" />
                                        </table-body-item>
                                    </tr>
                                </tbody>
                            </table-container>

                            <div v-show="wastes?.data?.length" class="py-4 px-6">
                                <pagination :items="wastes" @changePage="onChangePageBale" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="space-x-4">
                    <Button
                        :style="'gray'"
                        :mode="'light'"
                        :disabled="processingEditBaleWaste"
                        @click="closeEditBaleWasteModal">
                        Cancelar
                    </Button>
                    <button
                        type="button"
                        @click="editBaleWaste"
                        :disabled="processingEditBaleWaste || btnEditBaleWasteDisabled"
                        :class="processingEditBaleWaste || btnEditBaleWasteDisabled ? 'cursor-not-allowed' : 'cursor-pointer'"
                        class="bg-yellow-500 border-yellow-500 hover:bg-yellow-600 active:bg-yellow-600 focus:border-yellow-600 text-white inline-flex items-center px-4 py-2 border sm:rounded-md font-semibold text-xs uppercase tracking-widest select-none  focus:outline-none focus:shadow-outline focus:ring-0 focus:ring-transparent disabled:opacity-25 transition duration-500 ease">
                        <spin v-show="processingEditBaleWaste"></spin>
                        Actualizar
                    </button>

                </div>
            </template>
        </EditBaleWasteModal>

    </app-layout>
</template>

<script>
    import { InertiaLink }      from '@inertiajs/inertia-vue3'
    import AppLayout            from '@/Layouts/AppLayout.vue'
    import BreadcumbNav         from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem        from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid            from '@/Components/Icons/HeroIcons/Solid/Home'
    import LinkLight            from '@/Components/Links/Light'
    import Button               from '@/Components/Buttons/Button'
    import Selectize            from '@/Components/Inputs/Selectize'
    import Spin                 from '@/Components/Spin'
    import AddWasteModal        from '@/Components/DialogModal'
    import AddBaleWasteModal    from '@/Components/DialogModal'
    import DeleteWasteModal     from '@/Components/DialogModal'
    import EditWasteModal       from '@/Components/DialogModal'
    import EditBaleWasteModal   from '@/Components/DialogModal'
    import TableContainer       from '@/Components/Tables/TableContainer'
    import TableBodyItem        from '@/Components/Tables/TableBodyItem'
    import Loader               from '@/Components/Loader'
    import Dropdown             from '@/Components/Dropdown'
    import DropdownLink         from '@/Components/DropdownLink'
    import DotsVerticalIcon     from '@/Components/Icons/HeroIcons/Solid/DotsVertical'
    import Date                 from '@/Components/Inputs/Date'
    import moment               from 'moment'
    import Pagination           from '@/Components/Pagination/PaginationV2'

    const heading       = ['Seleccionar', 'Folio', 'Generadora', 'Residuo', 'Cantidad', 'Contenedor', 'Cantidad total', 'Indique cantidad']
    const headingEdit   = ['Seleccionar', 'Folio', 'Generadora', 'Residuo', 'Cantidad', 'Cantidad en almacen', 'Contenedor', 'Cantidad total', 'Cantidad total en almacen', 'Indique cantidad']

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            LinkLight,
            Selectize,
            Spin,
            AddWasteModal,
            DeleteWasteModal,
            TableContainer,
            TableBodyItem,
            Loader,
            Button,
            Dropdown,
            DropdownLink,
            DotsVerticalIcon,
            EditWasteModal,
            AddBaleWasteModal,
            EditBaleWasteModal,
            Date,
            Pagination
        },
        props: [
            'consolidated',
            'subsidiaries',
            'recipients',
            'carriers',
            'generics',
            'oneWasteGenericId',
            'oneWasteGenericSimpleName',
            'oneWasteGenericName',
            'oneWasteContainerName',
            'oneWasteData',
            'oneWasteDataEdit',
            'oneWasteBale',
            'twoWasteGenericId',
            'twoWasteGenericSimpleName',
            'twoWasteGenericName',
            'twoWasteContainerName',
            'twoWasteData',
            'twoWasteDataEdit',
            'twoWasteBale',
            'threeWasteGenericId',
            'threeWasteGenericSimpleName',
            'threeWasteGenericName',
            'threeWasteContainerName',
            'threeWasteData',
            'threeWasteDataEdit',
            'threeWasteBale',
            'fourWasteGenericId',
            'fourWasteGenericSimpleName',
            'fourWasteGenericName',
            'fourWasteContainerName',
            'fourWasteData',
            'fourWasteDataEdit',
            'fourWasteBale'
        ],
        data() {
            return {
                form: this.$inertia.form({
                    _method:                        'PUT',
                    onlyEdit:                       false,
                    folio:                          null,
                    generator:                      null,
                    carrier:                        null,
                    recipient:                      null,
                    wasteOneGenericId:              null,
                    wasteOneGenericSimpleName:      '',
                    wasteOneGenericName:            '',
                    wasteOneContainerName:          '',
                    wasteOneData:                   [],
                    wasteOneBale:                   false,
                    wasteTwoGenericId:              null,
                    wasteTwoGenericSimpleName:      '',
                    wasteTwoGenericName:            '',
                    wasteTwoContainerName:          '',
                    wasteTwoData:                   [],
                    wasteTwoBale:                   false,
                    wasteThreeGenericId:            null,
                    wasteThreeGenericSimpleName:    '',
                    wasteThreeGenericName:          '',
                    wasteThreeContainerName:        '',
                    wasteThreeData:                 [],
                    wasteThreeBale:                 false,
                    wasteFourGenericId:             null,
                    wasteFourGenericSimpleName:     '',
                    wasteFourGenericName:           '',
                    wasteFourContainerName:         '',
                    wasteFourData:                  [],
                    wasteFourBale:                  false,
                    generic:                        null,
                    genericObject:                  null,

                    consolidatedFile:               null,
                    carrierInvoiceFile:             null,
                    recipientInvoiceFile:           null,
                    departureAt:                    null,
                }),
                setGenerator:                       null,
                setCarrier:                         null,
                setRecipient:                       null,
                heading,
                headingEdit,
                filesUrl:                           (process.env.APP_ENV === 'local' ? process.env.APP_URL : process.env.ASSETS_URL) + '/files/',
                currentFileName:                    null,
                hasFile:                            false,
                carrierInvoiceName:                 null,
                recipientInvoiceName:               null,
                showConsolidatedFileModal:          false,
                showCarrierInvoiceFileModal:        false,
                showRecipientInvoiceFileModal:      false,

                wastes:                             [],
                showAddWasteModal:                  false,
                processingWaste:                    false,
                loading:                            false,
                formGeneric: {
                    id:                             null,
                    searchText:                     '',
                    containerName:                  '',
                    generatorId:                    null,
                    isBale:                         false,
                    isEditable:                     false,
                    consolidatedId:                 null,
                    page:                           1
                },
                formBaleGeneric: {
                    id:                             null,
                    searchText:                     '',
                    containerName:                  '',
                    generatorId:                    null,
                    isBale:                         true,
                    isEditable:                     false,
                    consolidatedId:                 null,
                    page:                           1
                },
                selectedWastes:                     [],

                remainingWastes:                    4,
                containers:                         [],
                countWastes:                        0,

                showDeleteWasteModal:               false,
                processingDeleteWaste:              false,
                numberWaste:                        0,
                numberGenericWaste:                 null,
                numberContainerWaste:               null,

                editSelectedWastes:                [],
                showEditWasteModal:                 false,
                processingEditWaste:                false,

                btnConsolidatedWastesDisabled:      true,
                btnConsolidatedBaleWastesDisabled:  true,
                btnSaveDisabled:                    true,
                btnAddWasteDisabled:                true,
                btnEditWasteDisabled:               true,

                showAddBaleWasteModal:              false,
                processingAddBaleWaste:             false,
                btnAddBaleWasteDisabled:            false,
                selectedBaleWastes:                 [],

                showEditBaleWasteModal:             false,
                processingEditBaleWaste:            false,
                btnEditBaleWasteDisabled:           false,
                editBaleSelectedWastes:             [],
            }
        },
        methods: {
            init() {
                this.form.wasteOneGenericId            = this.oneWasteGenericId
                this.form.wasteOneGenericSimpleName    = this.oneWasteGenericSimpleName
                this.form.wasteOneGenericName          = this.oneWasteGenericName
                this.form.wasteOneContainerName        = this.oneWasteContainerName
                this.form.wasteOneData                 = this.oneWasteData
                this.form.wasteOneBale                 = this.oneWasteBale
                this.form.wasteTwoGenericId            = this.twoWasteGenericId
                this.form.wasteTwoGenericSimpleName    = this.twoWasteGenericSimpleName
                this.form.wasteTwoGenericName          = this.twoWasteGenericName
                this.form.wasteTwoContainerName        = this.twoWasteContainerName
                this.form.wasteTwoData                 = this.twoWasteData
                this.form.wasteTwoBale                 = this.twoWasteBale
                this.form.wasteThreeGenericId          = this.threeWasteGenericId
                this.form.wasteThreeGenericSimpleName  = this.threeWasteGenericSimpleName
                this.form.wasteThreeGenericName        = this.threeWasteGenericName
                this.form.wasteThreeContainerName      = this.threeWasteContainerName
                this.form.wasteThreeData               = this.threeWasteData
                this.form.wasteThreeBale               = this.threeWasteBale
                this.form.wasteFourGenericId           = this.fourWasteGenericId
                this.form.wasteFourGenericSimpleName   = this.fourWasteGenericSimpleName
                this.form.wasteFourGenericName         = this.fourWasteGenericName
                this.form.wasteFourContainerName       = this.fourWasteContainerName
                this.form.wasteFourData                = this.fourWasteData
                this.form.wasteFourBale                = this.fourWasteBale

                if (this.form.wasteOneData.length)     this.countWastes        = 1
                if (this.form.wasteTwoData.length)     this.countWastes        = 2
                if (this.form.wasteThreeData.length)   this.countWastes        = 3
                if (this.form.wasteFourData.length)    this.countWastes        = 4

                this.form.folio                        = this.consolidated.folio
                this.form.generator                    = this.consolidated.subsidiary.id
                this.setGenerator                      = this.consolidated.subsidiary.id
                this.generatorSelected(this.consolidated.subsidiary.id)
                this.form.carrier                      = this.consolidated.carrier.id
                this.setCarrier                        = this.consolidated.carrier.id
                this.form.recipient                    = this.consolidated.recipient.id
                this.setRecipient                      = this.consolidated.recipient.id

                this.formGeneric.consolidatedId        = this.consolidated.id
                this.formBaleGeneric.consolidatedId    = this.consolidated.id

                this.form.departureAt                  = this.consolidated.departure_at                ? moment(this.consolidated?.departure_at.substring(0, 10)).format('YYYY-MM-DD')  : null
                this.currentFileName                   = this.consolidated.file != null                ? this.consolidated.file.substring(13)                                           : null
                this.carrierInvoiceName                = this.consolidated.carrier_invoice != null     ? this.consolidated.carrier_invoice.substring(9)                                 : null
                this.recipientInvoiceName              = this.consolidated.recipient_invoice != null   ? this.consolidated.file.substring(9)                                            : null

                this.hasFile                           = this.consolidated.file != null                ? true : false
            },
            submit() {
                this.form.post(route('dashboard.consolidated.finalize.update', this.consolidated.id), this.form, {preserveState: (page) => Object.keys(this.$page.props.errors).length, preserveScroll: (page) => Object.keys(this.$page.props.errors).length})
            },
            existence: _.debounce(async function () {
                if (this.form.folio != this.consolidated.folio) {
                    const response = await axios.post(route('api.consolidated.finalize.existence'), {folio: this.form.folio})
                    response.data.message ? this.form.errors.folio = response.data.message : this.form.errors.folio = null
                }
            }, 300),
            searchGeneric: _.debounce(async function () {
                this.formGeneric.page = 1
                this.indexGeneric()
            }, 300),
            generatorSelected(value) {
                if (value) {
                    this.form.generator                     = typeof value == 'object' ? value.id : value
                    this.btnConsolidatedWastesDisabled      = false
                    this.btnConsolidatedBaleWastesDisabled  = false
                    this.formGeneric.generatorId            = typeof value == 'object' ? value.id : value
                    this.formBaleGeneric.generatorId        = typeof value == 'object' ? value.id : value
                } else {
                    this.form.generator                     = null
                    this.btnConsolidatedWastesDisabled      = true
                    this.btnConsolidatedBaleWastesDisabled  = true

                    this.formGeneric.id                     = null
                    this.formGeneric.searchText             = null
                    this.formGeneric.containerName          = null
                    this.formGeneric.generatorId            = null

                    this.formBaleGeneric.id                 = null
                    this.formBaleGeneric.searchText         = null
                    this.formBaleGeneric.containerName      = null
                    this.formBaleGeneric.generatorId        = null

                    this.form.wasteOneGenericId             = null
                    this.form.wasteOneGenericSimpleName     = ''
                    this.form.wasteOneGenericName           = ''
                    this.form.wasteOneContainerName         = ''
                    this.form.wasteOneData                  = []
                    this.form.wasteTwoGenericId             = null
                    this.form.wasteTwoGenericSimpleName     = ''
                    this.form.wasteTwoGenericName           = ''
                    this.form.wasteTwoContainerName         = ''
                    this.form.wasteTwoData                  = []
                    this.form.wasteThreeGenericId           = null
                    this.form.wasteThreeGenericSimpleName   = ''
                    this.form.wasteThreeGenericName         = ''
                    this.form.wasteThreeContainerName       = ''
                    this.form.wasteThreeData                = []
                    this.form.wasteFourGenericId            = null
                    this.form.wasteFourGenericSimpleName    = ''
                    this.form.wasteFourGenericName          = ''
                    this.form.wasteFourContainerName        = ''
                    this.form.wasteFourData                 = []

                    this.containers                         = []
                    this.countWastes                        = 0
                }
            },
            carrierSelected(value) {
                this.form.carrier   = value ? value.id : null
            },
            recipientSelected(value) {
                this.form.recipient = value ? value.id : null
            },
            toggleWasteModal() {
                this.showAddWasteModal      = !this.showAddWasteModal
                this.containers             = []
                this.selectedWastes         = []
                this.wastes                 = []
                this.form.generic           = null
                this.form.genericObject     = null
                this.formGeneric.page       = 1
                this.formGeneric.searchText = ''
            },
            closeWasteModal() {
                this.showAddWasteModal          = false

                this.formGeneric.id             = null
                this.formGeneric.containerName  = ''
                this.formGeneric.searchText     = ''
                this.formGeneric.page           = 1
                if (this.containers.length) {
                    this.countWastes -= this.containers.length
                    this.containers                 = []
                }
            },
            genericSelected(value) {
                if (value) {
                    this.form.genericObject         = value
                    this.form.generic               = value.id
                    this.formGeneric.id             = value.id
                } else {
                    this.form.genericObject         = null
                    this.form.generic               = null
                    this.formGeneric.id             = null
                }
                this.formGeneric.searchText         = ''
                this.formGeneric.containerName      = null
                this.selectedWastes                 = []
                this.indexGeneric()
            },
            async indexGeneric() {
                this.loading     = true
                this.wastes      = []
                await axios.post(route('api.consolidated.open.getWastesByGeneric'), this.formGeneric).then(res => {
                    this.wastes = res.data
                    if (!this.formGeneric.searchText && this.formGeneric.page == 1) {
                        if (this.numberWaste == 1) {
                            this.wastes.data = this.uniqueByKey([...this.oneWasteDataEdit, ...res.data.data], 'id')

                        } else if (this.numberWaste == 2) {
                            this.wastes.data = this.uniqueByKey([...this.twoWasteDataEdit, ...res.data.data], 'id')

                        } else if (this.numberWaste == 3) {
                            this.wastes.data = this.uniqueByKey([...this.threeWasteDataEdit, ...res.data.data], 'id')

                        } else if (this.numberWaste == 4) {
                            this.wastes.data = this.uniqueByKey([...this.fourWasteDataEdit, ...res.data.data], 'id')
                        }
                    }
                    this.loading = false
                })
                this.reselectedGeneric()
            },
            uniqueByKey(array, prop){
                var unique      = [];
                var distinct    = [];
                for( let i = 0; i < array.length; i++ ){
                    if( !unique[array[i][prop]]){
                        distinct.push(array[i]);
                        unique[array[i][prop]] = 1;
                    }
                }
                return distinct
            },
            reselectedGeneric() {
                if(this.formGeneric.containerName != null) {
                    if (this.numberWaste == 1) {
                        if (this.form.wasteOneData.length) {
                            this.form.wasteOneData.forEach((e) => {
                                if (this.inArray(this.editSelectedWastes, e.manifestWasteId) == false) {
                                    this.editSelectedWastes.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                                }
                            })
                        }

                        if (this.editSelectedWastes.length) {
                            this.editSelectedWastes.forEach((e) => {
                                if (this.$refs[`edit_checkbox_${e.manifestWasteId}`] != null) {
                                    this.$refs[`edit_checkbox_${e.manifestWasteId}`].checked    = true
                                    this.inputEnabled(`edit_input_${e.manifestWasteId}`)
                                    this.inputSuccess(`edit_input_${e.manifestWasteId}`)
                                    this.$refs[`edit_input_${e.manifestWasteId}`].value         = e.quantity
                                }
                            })
                        }
                    }

                    if (this.numberWaste == 2) {
                        if (this.form.wasteTwoData.length) {
                            this.form.wasteTwoData.forEach((e) => {
                                if (this.inArray(this.editSelectedWastes, e.manifestWasteId) == false) {
                                    this.editSelectedWastes.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                                }
                            })
                        }

                        if (this.editSelectedWastes.length) {
                            this.editSelectedWastes.forEach((e) => {
                                if (this.$refs[`edit_checkbox_${e.manifestWasteId}`] != null) {
                                    this.$refs[`edit_checkbox_${e.manifestWasteId}`].checked    = true
                                    this.inputEnabled(`edit_input_${e.manifestWasteId}`)
                                    this.inputSuccess(`edit_input_${e.manifestWasteId}`)
                                    this.$refs[`edit_input_${e.manifestWasteId}`].value         = e.quantity
                                }
                            })
                        }
                    }

                    if (this.numberWaste == 3) {
                        if (this.form.wasteThreeData.length) {
                            this.form.wasteThreeData.forEach((e) => {
                                if (this.inArray(this.editSelectedWastes, e.manifestWasteId) == false) {
                                    this.editSelectedWastes.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                                }
                            })
                        }

                        if (this.editSelectedWastes.length) {
                            this.editSelectedWastes.forEach((e) => {
                                if (this.$refs[`edit_checkbox_${e.manifestWasteId}`] != null) {
                                    this.$refs[`edit_checkbox_${e.manifestWasteId}`].checked    = true
                                    this.inputEnabled(`edit_input_${e.manifestWasteId}`)
                                    this.inputSuccess(`edit_input_${e.manifestWasteId}`)
                                    this.$refs[`edit_input_${e.manifestWasteId}`].value         = e.quantity
                                }
                            })
                        }
                    }

                    if (this.numberWaste == 4) {
                        if (this.form.wasteFourData.length) {
                            this.form.wasteFourData.forEach((e) => {
                                if (this.inArray(this.editSelectedWastes, e.manifestWasteId) == false) {
                                    this.editSelectedWastes.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                                }
                            })
                        }

                        if (this.editSelectedWastes.length) {
                            this.editSelectedWastes.forEach((e) => {
                                if (this.$refs[`edit_checkbox_${e.manifestWasteId}`] != null) {
                                    this.$refs[`edit_checkbox_${e.manifestWasteId}`].checked    = true
                                    this.inputEnabled(`edit_input_${e.manifestWasteId}`)
                                    this.inputSuccess(`edit_input_${e.manifestWasteId}`)
                                    this.$refs[`edit_input_${e.manifestWasteId}`].value         = e.quantity
                                }
                            })
                        }
                    }

                } else {
                    if (this.selectedWastes.length) {
                        this.selectedWastes.forEach(e => {
                            if (this.$refs[`checkbox_${e.manifestWasteId}`]!=null) {
                                this.$refs[`checkbox_${e.manifestWasteId}`].checked    = true
                                this.inputEnabled(`input_${e.manifestWasteId}`)
                                this.inputSuccess(`input_${e.manifestWasteId}`)
                                this.$refs[`input_${e.manifestWasteId}`].value         = e.quantity
                            }
                        })
                    }
                }
            },
            changeInput(waste, refCheck, refInput) {
                if (this.$refs[`${refCheck}${waste.id}`].checked) {
                    this.inputEnabled(`${refInput}${waste.id}`)
                    this.addSelectedWastes(waste, refCheck, refInput)
                } else if (!this.$refs[`${refCheck}${waste.id}`].checked) {
                    this.inputDisabled(`${refInput}${waste.id}`)
                    this.removeSelectedWastes(waste, refCheck, refInput)
                    this.$refs[`${refInput}${waste.id}`].value = ''
                }
            },
            addSelectedWastes(waste, refCheck, refInput) {
                this.selectedWastes.push({manifestWasteId: waste.id, container: waste.container.name, currentQuantity: waste.quantity, quantity: null, genericName: this.form.genericObject.name})

                if (this.containers.includes(waste.container.name) == false) {
                    if (this.countWastes <= 4) {
                        this.containers.push(waste.container.name)
                        this.countWastes++
                    }
                }

                if (this.countWastes == 4) {
                    this.wastes.data.forEach(e => {
                        if (this.containers.includes(e.container.name) == false) {
                            this.elementError(`${refCheck}${e.id}`)
                        }
                    })
                }
            },
            removeSelectedWastes(waste, refCheck, refInput) {
                let containersChecked       = []
                const newSelectedGeneric    = this.selectedWastes.filter(e => {
                    return e.manifestWasteId != waste.id
                })
                this.selectedWastes         = newSelectedGeneric

                this.wastes.data.forEach(e => {
                    if (this.$refs[`${refCheck}${e.id}`].checked) {
                        containersChecked.push(e.container.name)
                    }
                })

                if (!containersChecked.includes(waste.container.name)) {
                    const newContainers = this.containers.filter(e => {
                        return e != waste['container'].name
                    })
                    this.containers = newContainers
                    this.countWastes--
                }
            },
            inputEnabled(ref) {
                this.$refs[ref].classList.remove('cursor-not-allowed')
                this.$refs[ref].classList.add('cursor-default')
                this.inputError(ref)
                this.$refs[ref].disabled = false
            },
            inputDisabled(ref) {
                this.$refs[ref].classList.remove('cursor-default')
                this.$refs[ref].classList.add('cursor-not-allowed')
                this.inputSuccess(ref)
                this.$refs[ref].disabled = true
            },
            inputError(ref) {
                this.$refs[ref].classList.remove('focus:border-orange-500')
                this.$refs[ref].classList.remove('border-gray-300')
                this.$refs[ref].classList.add('border-red-500')
                this.$refs[ref].classList.add('focus:border-red-500')
            },
            inputSuccess(ref) {
                this.$refs[ref].classList.remove('focus:border-red-500')
                this.$refs[ref].classList.remove('border-red-500')
                this.$refs[ref].classList.add('border-gray-300')
                this.$refs[ref].classList.add('focus:border-orange-500')
            },
            elementError(ref) {
                this.$refs[ref].disabled = true
                this.$refs[ref].classList.remove('cursor-pointer')
                this.$refs[ref].classList.add('cursor-not-allowed')
            },
            elementSuccess(ref) {
                this.$refs[ref].disabled = false
                this.$refs[ref].classList.remove('cursor-not-allowed')
                this.$refs[ref].classList.add('cursor-pointer')
            },
            keypressInput: _.debounce(async function (waste, refCheck, refInput) {
                if (this.selectedWastes.length) {
                    this.selectedWastes.forEach(e => {
                        if (`${refInput}${waste.id}`.includes(e.manifestWasteId)) {
                            if (parseFloat(this.$refs[`${refInput}${waste.id}`].value) > e.currentQuantity) {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }

                            if(parseFloat(this.$refs[`${refInput}${waste.id}`].value) > 0 && parseFloat(this.$refs[`${refInput}${waste.id}`].value) <= e.currentQuantity) {
                                this.inputSuccess(`${refInput}${waste.id}`)
                                e.quantity = this.$refs[`${refInput}${waste.id}`].value
                            }

                            if (this.$refs[`${refInput}${waste.id}`].value == '0') {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }

                            if (this.$refs[`${refInput}${waste.id}`].value == '') {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }

                            if (this.$refs[`${refInput}${waste.id}`].value.includes('-') || this.$refs[`${refInput}${waste.id}`].value.includes('+')) {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }
                        }
                    })
                }

                if (this.editSelectedWastes.length) {
                    this.editSelectedWastes.forEach(e => {
                        if (`${refInput}${waste.id}`.includes(e.manifestWasteId)) {
                            if (parseFloat(this.$refs[`${refInput}${waste.id}`].value) > e.currentQuantity) {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }

                            if(parseFloat(this.$refs[`${refInput}${waste.id}`].value) > 0 && parseFloat(this.$refs[`${refInput}${waste.id}`].value) <= e.currentQuantity) {
                                this.inputSuccess(`${refInput}${waste.id}`)
                                e.quantity = this.$refs[`${refInput}${waste.id}`].value
                            }

                            if (this.$refs[`${refInput}${waste.id}`].value == '0') {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null

                            }

                            if (this.$refs[`${refInput}${waste.id}`].value == '') {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }

                            if (this.$refs[`${refInput}${waste.id}`].value === null) {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }

                            if (this.$refs[`${refInput}${waste.id}`].value.includes('-') || this.$refs[`${refInput}${waste.id}`].value.includes('+')) {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null

                            }
                        }
                    })
                }
            }, 100),
            addWaste() {
                this.containers.forEach((container) => {
                    this.selectedWastes.forEach((selectedWaste) => {
                        if (container == selectedWaste.container) {
                            if (!this.form.wasteOneData.length) {
                                this.form.wasteOneGenericId             = this.form.genericObject.id
                                this.form.wasteOneGenericSimpleName     = this.form.genericObject.name
                                this.form.wasteOneGenericName           = this.form.genericObject?.integrated_transport_name ? this.form.genericObject.integrated_transport_name : this.form.genericObject.name
                                this.form.wasteOneContainerName         = container
                                this.form.wasteOneBale                  = false
                                this.form.wasteOneData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                            } else if (selectedWaste.container == this.form.wasteOneContainerName && selectedWaste.genericName == this.form.wasteOneGenericSimpleName) {
                                this.form.wasteOneData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                            } else if (!this.form.wasteTwoData.length) {
                                this.form.wasteTwoGenericId             = this.form.genericObject.id
                                this.form.wasteTwoGenericSimpleName     = this.form.genericObject.name
                                this.form.wasteTwoGenericName           = this.form.genericObject?.integrated_transport_name ? this.form.genericObject.integrated_transport_name : this.form.genericObject.name
                                this.form.wasteTwoContainerName         = container
                                this.form.wasteTwoBale                  = false
                                this.form.wasteTwoData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                            } else if (selectedWaste.container == this.form.wasteTwoContainerName && selectedWaste.genericName == this.form.wasteTwoGenericSimpleName) {
                                this.form.wasteTwoData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                            } else if (!this.form.wasteThreeData.length) {
                                this.form.wasteThreeGenericId             = this.form.genericObject.id
                                this.form.wasteThreeGenericSimpleName     = this.form.genericObject.name
                                this.form.wasteThreeGenericName           = this.form.genericObject?.integrated_transport_name ? this.form.genericObject.integrated_transport_name : this.form.genericObject.name
                                this.form.wasteThreeContainerName         = container
                                this.form.wasteThreeBale                  = false
                                this.form.wasteThreeData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                            } else if (selectedWaste.container == this.form.wasteThreeContainerName && selectedWaste.genericName == this.form.wasteThreeGenericSimpleName) {
                                this.form.wasteThreeData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                            } else if (!this.form.wasteFourData.length) {
                                this.form.wasteFourGenericId             = this.form.genericObject.id
                                this.form.wasteFourGenericSimpleName     = this.form.genericObject.name
                                this.form.wasteFourGenericName           = this.form.genericObject?.integrated_transport_name ? this.form.genericObject.integrated_transport_name : this.form.genericObject.name
                                this.form.wasteFourContainerName         = container
                                this.form.wasteFourBale                  = false
                                this.form.wasteFourData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                            } else if (selectedWaste.container == this.form.wasteFourContainerName && selectedWaste.genericName == this.form.wasteFourGenericSimpleName) {
                                this.form.wasteFourData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                            }
                        }
                    })

                })

                this.toggleWasteModal()
            },

            // Delete functions
            toggleDeleteWasteModal(waste) {
                this.showDeleteWasteModal   = !this.showDeleteWasteModal
                this.numberWaste            = waste != null ? waste : 0
            },
            deleteWaste() {
                this.processingDeleteWaste = true
                this.countWastes--

                if (this.numberWaste == 1) {
                    this.form.wasteOneGenericId                 = null
                    this.form.wasteOneGenericSimpleName         = ''
                    this.form.wasteOneGenericName               = ''
                    this.form.wasteOneContainerName             = ''
                    this.form.wasteOneData                      = []
                    this.form.wasteOneBale                      = false

                    if (this.form.wasteTwoData.length) {
                        this.form.wasteOneGenericID             = this.form.wasteTwoGenericID
                        this.form.wasteOneGenericSimpleName     = this.form.wasteTwoGenericSimpleName
                        this.form.wasteOneGenericName           = this.form.wasteTwoGenericName
                        this.form.wasteOneContainerName         = this.form.wasteTwoContainerName
                        this.form.wasteOneData                  = this.form.wasteTwoData
                        this.form.wasteOneBale                  = this.form.wasteTwoBale

                        this.form.wasteTwoGenericId             = null
                        this.form.wasteTwoGenericSimpleName     = ''
                        this.form.wasteTwoGenericName           = ''
                        this.form.wasteTwoContainerName         = ''
                        this.form.wasteTwoData                  = []
                        this.form.wasteTwoBale                  = false
                    }

                    if (this.form.wasteThreeData.length) {
                        this.form.wasteTwoGenericId             = this.form.wasteThreeGenericId
                        this.form.wasteTwoGenericSimpleName     = this.form.wasteThreeGenericSimpleName
                        this.form.wasteTwoGenericName           = this.form.wasteThreeGenericName
                        this.form.wasteTwoContainerName         = this.form.wasteThreeContainerName
                        this.form.wasteTwoData                  = this.form.wasteThreeData
                        this.form.wasteTwoBale                  = this.form.wasteThreeBale

                        this.form.wasteThreeGenericId           = null
                        this.form.wasteThreeGenericSimpleName   = ''
                        this.form.wasteThreeGenericName         = ''
                        this.form.wasteThreeContainerName       = ''
                        this.form.wasteThreeData                = []
                        this.form.wasteThreeBale                = false
                    }

                    if (this.form.wasteFourData.length) {
                        this.form.wasteThreeGenericId           = this.form.wasteFourGenericId
                        this.form.wasteThreeGenericSimpleName   = this.form.wasteFourGenericSimpleName
                        this.form.wasteThreeGenericName         = this.form.wasteFourGenericName
                        this.form.wasteThreeContainerName       = this.form.wasteFourContainerName
                        this.form.wasteThreeData                = this.form.wasteFourData
                        this.form.wasteThreeBale                = this.form.wasteFourBale

                        this.form.wasteFourGenericId            = null
                        this.form.wasteFourGenericSimpleName    = ''
                        this.form.wasteFourGenericName          = ''
                        this.form.wasteFourContainerName        = ''
                        this.form.wasteFourData                 = []
                        this.form.wasteFourBale                 = false
                    }
                }

                if (this.numberWaste == 2) {
                    this.form.wasteTwoGenericId                 = null
                    this.form.wasteTwoGenericSimpleName         = ''
                    this.form.wasteTwoGenericName               = ''
                    this.form.wasteTwoContainerName             = ''
                    this.form.wasteTwoData                      = []
                    this.form.wasteTwoBale                      = false

                    if (this.form.wasteThreeData.length) {
                        this.form.wasteTwoGenericId             = this.form.wasteThreeGenericId
                        this.form.wasteTwoGenericSimpleName     = this.form.wasteThreeGenericSimpleName
                        this.form.wasteTwoGenericName           = this.form.wasteThreeGenericName
                        this.form.wasteTwoContainerName         = this.form.wasteThreeContainerName
                        this.form.wasteTwoData                  = this.form.wasteThreeData
                        this.form.wasteTwoBale                  = this.form.wasteThreeBale

                        this.form.wasteThreeGenericId           = null
                        this.form.wasteThreeGenericSimpleName   = ''
                        this.form.wasteThreeGenericName         = ''
                        this.form.wasteThreeContainerName       = ''
                        this.form.wasteThreeData                = []
                        this.form.wasteThreeBale                = false
                    }

                    if (this.form.wasteFourData.length) {
                        this.form.wasteThreeGenericId           = this.form.wasteFourGenericId
                        this.form.wasteThreeGenericSimpleName   = this.form.wasteFourGenericSimpleName
                        this.form.wasteThreeGenericName         = this.form.wasteFourGenericName
                        this.form.wasteThreeContainerName       = this.form.wasteFourContainerName
                        this.form.wasteThreeData                = this.form.wasteFourData
                        this.form.wasteThreeBale                = this.form.wasteFourBale

                        this.form.wasteFourGenericId            = null
                        this.form.wasteFourGenericSimpleName    = ''
                        this.form.wasteFourGenericName          = ''
                        this.form.wasteFourContainerName        = ''
                        this.form.wasteFourData                 = []
                        this.form.wasteFourBale                 = false
                    }
                }

                if (this.numberWaste == 3) {
                    this.form.wasteThreeGenericId               = null
                    this.form.wasteThreeGenericSimpleName       = ''
                    this.form.wasteThreeGenericName             = ''
                    this.form.wasteThreeContainerName           = ''
                    this.form.wasteThreeData                    = []
                    this.form.wasteThreeBale                    = false

                    if (this.form.wasteFourData.length) {
                        this.form.wasteThreeGenericId           = this.form.wasteFourGenericId
                        this.form.wasteThreeGenericSimpleName   = this.form.wasteFourGenericSimpleName
                        this.form.wasteThreeGenericName         = this.form.wasteFourGenericName
                        this.form.wasteThreeContainerName       = this.form.wasteFourContainerName
                        this.form.wasteThreeData                = this.form.wasteFourData
                        this.form.wasteThreeBale                = this.form.wasteFourBale

                        this.form.wasteFourGenericId            = null
                        this.form.wasteFourGenericSimpleName    = ''
                        this.form.wasteFourGenericName          = ''
                        this.form.wasteFourContainerName        = ''
                        this.form.wasteFourData                 = []
                        this.form.wasteFourBale                 = false
                    }
                }

                if (this.numberWaste == 4) {
                    this.form.wasteFourGenericId            = null
                    this.form.wasteFourGenericSimpleName    = ''
                    this.form.wasteFourGenericName          = ''
                    this.form.wasteFourContainerName        = ''
                    this.form.wasteFourData                 = []
                    this.form.wasteFourBale                 = false
                }

                this.processingDeleteWaste = false
                this.toggleDeleteWasteModal(0)
            },

            // Edit functions
            openEditWasteModal(waste) {
                this.showEditWasteModal             = true
                this.numberWaste                    = waste != null ? waste : 0

                if (waste==1) {
                    this.numberGenericWaste         = this.form.wasteOneGenericSimpleName
                    this.numberContainerWaste       = this.form.wasteOneContainerName
                    this.formGeneric.id             = this.form.wasteOneGenericId
                    this.formGeneric.searchText     = ''
                    this.formGeneric.containerName  = this.form.wasteOneContainerName
                }

                if (waste==2) {
                    this.numberGenericWaste         = this.form.wasteTwoGenericSimpleName
                    this.numberContainerWaste       = this.form.wasteTwoContainerName
                    this.formGeneric.id             = this.form.wasteTwoGenericId
                    this.formGeneric.searchText     = ''
                    this.formGeneric.containerName  = this.form.wasteTwoContainerName
                }

                if (waste==3) {
                    this.numberGenericWaste         = this.form.wasteThreeGenericSimpleName
                    this.numberContainerWaste       = this.form.wasteThreeContainerName
                    this.formGeneric.id             = this.form.wasteThreeGenericId
                    this.formGeneric.searchText     = ''
                    this.formGeneric.containerName  = this.form.wasteThreeContainerName
                }

                if (waste==4) {
                    this.numberGenericWaste         = this.form.wasteFourGenericSimpleName
                    this.numberContainerWaste       = this.form.wasteFourContainerName
                    this.formGeneric.id             = this.form.wasteFourGenericId
                    this.formGeneric.searchText     = ''
                    this.formGeneric.containerName  = this.form.wasteFourContainerName
                }

                this.indexGeneric()
            },
            closeEditWasteModal() {
                this.showEditWasteModal         = false
                this.numberWaste                = 0
                this.formGeneric.id             = null
                this.formGeneric.containerName  = ''
                this.formGeneric.searchText     = ''
                this.formGeneric.page           = 1
                this.editSelectedWaste          = []
                this.wastes                     = []
            },
            editWaste() {
                if (this.numberWaste == 1) {
                    this.form.wasteOneData.forEach(e => {
                        this.editSelectedWastes.forEach((editSelectedWaste) => {
                            if (e.manifestWasteId == editSelectedWaste.manifestWasteId) {
                                e.quantity          = editSelectedWaste.quantity
                                e.currentQuantity   = editSelectedWaste.currentQuantity
                                e.container         = editSelectedWaste.container
                                e.genericName       = editSelectedWaste.genericName
                            }
                        })
                    })

                    const pushArray = this.getDifference(this.editSelectedWastes, this.form.wasteOneData)
                    if (pushArray.length) {
                        pushArray.forEach((e) => {
                            this.form.wasteOneData.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                        })
                    }

                    const deleteArray = this.getDifference(this.form.wasteOneData, this.editSelectedWastes)
                    this.form.wasteOneData = this.getDifference(this.form.wasteOneData, deleteArray)
                }

                if (this.numberWaste == 2) {
                    this.form.wasteTwoData.forEach(e => {
                        this.editSelectedWastes.forEach((editSelectedWaste) => {
                            if (e.manifestWasteId == editSelectedWaste.manifestWasteId) {
                                e.quantity          = editSelectedWaste.quantity
                                e.currentQuantity   = editSelectedWaste.currentQuantity
                                e.container         = editSelectedWaste.container
                                e.genericName       = editSelectedWaste.genericName
                            }
                        })
                    })

                    const pushArray = this.getDifference(this.editSelectedWastes, this.form.wasteTwoData)
                    if (pushArray.length) {
                        pushArray.forEach((e) => {
                            this.form.wasteTwoData.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                        })
                    }

                    const deleteArray = this.getDifference(this.form.wasteTwoData, this.editSelectedWastes)
                    this.form.wasteTwoData = this.getDifference(this.form.wasteTwoData, deleteArray)
                }

                if (this.numberWaste == 3) {
                    this.form.wasteThreeData.forEach(e => {
                        this.editSelectedWastes.forEach((editSelectedWaste) => {
                            if (e.manifestWasteId == editSelectedWaste.manifestWasteId) {
                                e.quantity          = editSelectedWaste.quantity
                                e.currentQuantity   = editSelectedWaste.currentQuantity
                                e.container         = editSelectedWaste.container
                                e.genericName       = editSelectedWaste.genericName
                            }
                        })
                    })

                    const pushArray = this.getDifference(this.editSelectedWastes, this.form.wasteThreeData)
                    if (pushArray.length) {
                        pushArray.forEach((e) => {
                            this.form.wasteThreeData.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                        })
                    }

                    const deleteArray = this.getDifference(this.form.wasteThreeData, this.editSelectedWastes)
                    this.form.wasteThreeData = this.getDifference(this.form.wasteThreeData, deleteArray)
                }

                if (this.numberWaste == 4) {
                    this.form.wasteFourData.forEach(e => {
                        this.editSelectedWastes.forEach((editSelectedWaste) => {
                            if (e.manifestWasteId == editSelectedWaste.manifestWasteId) {
                                e.quantity          = editSelectedWaste.quantity
                                e.currentQuantity   = editSelectedWaste.currentQuantity
                                e.container         = editSelectedWaste.container
                                e.genericName       = editSelectedWaste.genericName
                            }
                        })
                    })

                    const pushArray = this.getDifference(this.editSelectedWastes, this.form.wasteFourData)
                    if (pushArray.length) {
                        pushArray.forEach((e) => {
                            this.form.wasteFourData.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                        })
                    }

                    const deleteArray = this.getDifference(this.form.wasteFourData, this.editSelectedWastes)
                    this.form.wasteFourData = this.getDifference(this.form.wasteFourData, deleteArray)
                }

                this.closeEditWasteModal()
            },
            getDifference(array1, array2) {
                return array1.filter(object1 => {
                    return !array2.some(object2 => {
                        return object1.manifestWasteId === object2.manifestWasteId;
                    });
                });
            },
            inArray(array, id) {
                let value = false
                array.forEach((e) => {
                    if (e.manifestWasteId == id) {
                        value = true
                    }
                })
                return value
            },
            changeEditInput(waste, refCheck, refInput) {
                if (this.$refs[`${refCheck}${waste.id}`].checked) {
                    this.inputEnabled(`${refInput}${waste.id}`)
                    this.addEditSelectedWastes(waste, refCheck, refInput)
                } else if (!this.$refs[`${refCheck}${waste.id}`].checked) {
                    this.inputDisabled(`${refInput}${waste.id}`)
                    this.removeEditSelectedWastes(waste, refCheck, refInput)
                    this.$refs[`${refInput}${waste.id}`].value = ''
                }
            },
            addEditSelectedWastes(waste, refCheck, refInput) {
                //TODO: genericName para el edit no esta definido
                this.editSelectedWastes.push({manifestWasteId: waste.id, container: waste.container.name, currentQuantity: waste.quantity, quantity: null, genericName: null})
            },
            removeEditSelectedWastes(waste, refCheck, refInput) {
                const newEditSelectedGeneric    = this.editSelectedWastes.filter(e => {
                    return e.manifestWasteId != waste.id
                })
                this.editSelectedWastes         = newEditSelectedGeneric
            },

            // Add bale functions
            toggleAddBaleWasteModal() {
                this.showAddBaleWasteModal      = !this.showAddBaleWasteModal
                this.selectedBaleWastes         = []
                this.wastes                     = []
                this.form.generic               = null
                this.form.genericObject         = null
                this.containers                 = []
                this.formBaleGeneric.page       = 1
                this.formBaleGeneric.searchText = ''
            },
            closeAddBaleWasteModal() {
                this.showAddBaleWasteModal      = false
                this.selectedBaleWastes         = []
                this.wastes                     = []
                this.form.generic               = null
                this.form.genericObject         = null
                this.formBaleGeneric.page       = 1
                this.formBaleGeneric.searchText = ''
                if (this.containers.length) {
                    this.countWastes    -= this.containers.length
                    this.containers     = []
                }
            },
            BaleGenericSelected(value) {
                if (value) {
                    this.form.genericObject             = value
                    this.form.generic                   = value.id
                    this.formBaleGeneric.id             = value.id
                    this.formBaleGeneric.containerName  = 'Paca'
                } else {
                    this.form.genericObject             = null
                    this.form.generic                   = null
                    this.formBaleGeneric.id             = null
                    this.formBaleGeneric.containerName  = null
                }

                this.formBaleGeneric.searchText         = ''
                this.selectedBaleWastes                 = []
                this.indexBaleGeneric()
            },
            async indexBaleGeneric() {
                this.loading     = true
                this.wastes      = []
                await axios.post(route('api.consolidated.open.getWastesByGeneric'), this.formBaleGeneric).then(res => {
                    this.wastes  = res.data
                    if (!this.formBaleGeneric.searchText && this.formBaleGeneric.page == 1) {
                        if (this.numberWaste == 1) {
                            this.wastes.data = this.uniqueByKey([...this.oneWasteDataEdit, ...res.data.data], 'id')

                        } else if (this.numberWaste == 2) {
                            this.wastes.data = this.uniqueByKey([...this.twoWasteDataEdit, ...res.data.data], 'id')

                        } else if (this.numberWaste == 3) {
                            this.wastes.data = this.uniqueByKey([...this.threeWasteDataEdit, ...res.data.data], 'id')

                        } else if (this.numberWaste == 4) {
                            this.wastes.data = this.uniqueByKey([...this.fourWasteDataEdit, ...res.data.data], 'id')
                        }
                    }
                    this.loading = false
                })
                this.reselectedBaleWastes()
            },
            reselectedBaleWastes() {
                if (this.selectedBaleWastes.length) {
                    this.selectedBaleWastes.forEach(e => {
                        if (this.$refs[`checkbox_bale_${e.manifestWasteId}`]!=null) {
                            this.$refs[`checkbox_bale_${e.manifestWasteId}`].checked    = true
                            this.inputEnabled(`input_bale_${e.manifestWasteId}`)
                            this.inputSuccess(`input_bale_${e.manifestWasteId}`)
                            this.$refs[`input_bale_${e.manifestWasteId}`].value         = e.quantity
                        }
                    })
                } else {

                    if (this.numberWaste == 1) {
                        if (this.form.wasteOneData.length) {
                            this.form.wasteOneData.forEach((e) => {
                                if (this.inArray(this.editBaleSelectedWastes, e.manifestWasteId) == false) {
                                    this.editBaleSelectedWastes.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                                }
                            })
                        }

                        if (this.editBaleSelectedWastes.length) {
                            this.editBaleSelectedWastes.forEach((e) => {
                                if (this.$refs[`edit_checkbox_bale_${e.manifestWasteId}`] != null) {
                                    this.$refs[`edit_checkbox_bale_${e.manifestWasteId}`].checked    = true
                                    this.inputEnabled(`edit_input_bale_${e.manifestWasteId}`)
                                    this.inputSuccess(`edit_input_bale_${e.manifestWasteId}`)
                                    this.$refs[`edit_input_bale_${e.manifestWasteId}`].value         = e.quantity
                                }
                            })
                        }
                    }

                    if (this.numberWaste == 2) {
                        if (this.form.wasteTwoData.length) {
                            this.form.wasteTwoData.forEach((e) => {
                                if (this.inArray(this.editBaleSelectedWastes, e.manifestWasteId) == false) {
                                    this.editBaleSelectedWastes.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                                }
                            })
                        }

                        if (this.editBaleSelectedWastes.length) {
                            this.editBaleSelectedWastes.forEach((e) => {
                                if (this.$refs[`edit_checkbox_bale_${e.manifestWasteId}`] != null) {
                                    this.$refs[`edit_checkbox_bale_${e.manifestWasteId}`].checked    = true
                                    this.inputEnabled(`edit_input_bale_${e.manifestWasteId}`)
                                    this.inputSuccess(`edit_input_bale_${e.manifestWasteId}`)
                                    this.$refs[`edit_input_bale_${e.manifestWasteId}`].value         = e.quantity
                                }
                            })
                        }
                    }

                    if (this.numberWaste == 3) {
                        if (this.form.wasteThreeData.length) {
                            this.form.wasteThreeData.forEach((e) => {
                                if (this.inArray(this.editBaleSelectedWastes, e.manifestWasteId) == false) {
                                    this.editBaleSelectedWastes.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                                }
                            })
                        }

                        if (this.editBaleSelectedWastes.length) {
                            this.editBaleSelectedWastes.forEach((e) => {
                                if (this.$refs[`edit_checkbox_bale_${e.manifestWasteId}`] != null) {
                                    this.$refs[`edit_checkbox_bale_${e.manifestWasteId}`].checked    = true
                                    this.inputEnabled(`edit_input_bale_${e.manifestWasteId}`)
                                    this.inputSuccess(`edit_input_bale_${e.manifestWasteId}`)
                                    this.$refs[`edit_input_bale_${e.manifestWasteId}`].value         = e.quantity
                                }
                            })
                        }
                    }

                    if (this.numberWaste == 4) {
                        if (this.form.wasteFourData.length) {
                            this.form.wasteFourData.forEach((e) => {
                                if (this.inArray(this.editBaleSelectedWastes, e.manifestWasteId) == false) {
                                    this.editBaleSelectedWastes.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                                }
                            })
                        }

                        if (this.editBaleSelectedWastes.length) {
                            this.editBaleSelectedWastes.forEach((e) => {
                                if (this.$refs[`edit_checkbox_bale_${e.manifestWasteId}`] != null) {
                                    this.$refs[`edit_checkbox_bale_${e.manifestWasteId}`].checked    = true
                                    this.inputEnabled(`edit_input_bale_${e.manifestWasteId}`)
                                    this.inputSuccess(`edit_input_bale_${e.manifestWasteId}`)
                                    this.$refs[`edit_input_bale_${e.manifestWasteId}`].value         = e.quantity
                                }
                            })
                        }
                    }


                }
            },
            searchBaleGeneric: _.debounce(async function () {
                this.formBaleGeneric.page = 1
                this.indexBaleGeneric()
            }, 300),
            changeBaleInput(waste, refCheck, refInput) {
                if (this.$refs[`${refCheck}${waste.id}`].checked) {
                    this.inputEnabled(`${refInput}${waste.id}`)
                    this.addBaleSelectedWastes(waste, refCheck, refInput)
                } else if (!this.$refs[`${refCheck}${waste.id}`].checked) {
                    this.inputDisabled(`${refInput}${waste.id}`)
                    this.removeBaleSelectedWastes(waste, refCheck, refInput)
                    this.$refs[`${refInput}${waste.id}`].value = ''
                }
            },
            keypressBaleInput: _.debounce(async function (waste, refCheck, refInput) {
                if (this.selectedBaleWastes.length) {
                    this.selectedBaleWastes.forEach(e => {
                        if (`${refInput}${waste.id}`.includes(e.manifestWasteId)) {
                            if (parseFloat(this.$refs[`${refInput}${waste.id}`].value) > e.currentQuantity) {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }

                            if(parseFloat(this.$refs[`${refInput}${waste.id}`].value) > 0 && parseFloat(this.$refs[`${refInput}${waste.id}`].value) <= e.currentQuantity) {
                                this.inputSuccess(`${refInput}${waste.id}`)
                                e.quantity = this.$refs[`${refInput}${waste.id}`].value
                            }

                            if (this.$refs[`${refInput}${waste.id}`].value == '0') {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }

                            if (this.$refs[`${refInput}${waste.id}`].value == '') {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }

                            if (this.$refs[`${refInput}${waste.id}`].value.includes('-') || this.$refs[`${refInput}${waste.id}`].value.includes('+')) {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }
                        }
                    })
                }

                if (this.editBaleSelectedWastes.length) {
                    this.editBaleSelectedWastes.forEach(e => {
                        if (`${refInput}${waste.id}`.includes(e.manifestWasteId)) {
                            if (parseFloat(this.$refs[`${refInput}${waste.id}`].value) > e.currentQuantity) {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }

                            if(parseFloat(this.$refs[`${refInput}${waste.id}`].value) > 0 && parseFloat(this.$refs[`${refInput}${waste.id}`].value) <= e.currentQuantity) {
                                this.inputSuccess(`${refInput}${waste.id}`)
                                e.quantity = this.$refs[`${refInput}${waste.id}`].value
                            }

                            if (this.$refs[`${refInput}${waste.id}`].value == '0') {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }

                            if (this.$refs[`${refInput}${waste.id}`].value == '') {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }

                            if (this.$refs[`${refInput}${waste.id}`].value == null) {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }

                            if (this.$refs[`${refInput}${waste.id}`].value.includes('-') || this.$refs[`${refInput}${waste.id}`].value.includes('+')) {
                                this.inputError(`${refInput}${waste.id}`)
                                e.quantity = null
                            }
                        }
                    })
                }
            }, 100),
            addBaleSelectedWastes(waste) {
                this.selectedBaleWastes.push({manifestWasteId: waste.id, container: waste.container.name, currentQuantity: waste.quantity, quantity: null, genericName: this.form.genericObject.name})

                if (this.containers.includes('Paca') == false) {
                    if (this.countWastes <= 4) {
                        this.containers.push('Paca')
                        this.countWastes++
                    }
                }
            },
            removeBaleSelectedWastes(waste, refCheck) {
                let containersChecked           = []
                const newSelectedBaleGeneric    = this.selectedBaleWastes.filter(e => {
                    return e.manifestWasteId != waste.id
                })
                this.selectedBaleWastes         = newSelectedBaleGeneric

                this.wastes.data.forEach(e => {
                    if (this.$refs[`${refCheck}${e.id}`].checked) {
                        containersChecked.push('Paca')
                    }
                })

                if (!containersChecked.includes('Paca')) {
                    const newContainers = this.containers.filter(e => {
                        return e != 'Paca'
                    })
                    this.containers = newContainers
                    this.countWastes--
                }

            },
            addBaleWaste() {
                this.selectedBaleWastes.forEach((selectedWaste) => {
                    if (!this.form.wasteOneData.length) {
                        this.form.wasteOneGenericId             = this.form.genericObject.id
                        this.form.wasteOneGenericSimpleName     = this.form.genericObject.name
                        this.form.wasteOneGenericName           = this.form.genericObject?.integrated_transport_name ? this.form.genericObject.integrated_transport_name : this.form.genericObject.name
                        this.form.wasteOneContainerName         = 'Paca'
                        this.form.wasteOneBale                  = true
                        this.form.wasteOneData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                    } else if ('Paca' == this.form.wasteOneContainerName && selectedWaste.genericName == this.form.wasteOneGenericSimpleName) {
                        this.form.wasteOneData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                    } else if (!this.form.wasteTwoData.length) {
                        this.form.wasteTwoGenericId             = this.form.genericObject.id
                        this.form.wasteTwoGenericSimpleName     = this.form.genericObject.name
                        this.form.wasteTwoGenericName           = this.form.genericObject?.integrated_transport_name ? this.form.genericObject.integrated_transport_name : this.form.genericObject.name
                        this.form.wasteTwoContainerName         = 'Paca'
                        this.form.wasteTwoBale                  = true
                        this.form.wasteTwoData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                    } else if ('Paca' == this.form.wasteTwoContainerName && selectedWaste.genericName == this.form.wasteTwoGenericSimpleName) {
                        this.form.wasteTwoData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                    } else if (!this.form.wasteThreeData.length) {
                        this.form.wasteThreeGenericId             = this.form.genericObject.id
                        this.form.wasteThreeGenericSimpleName     = this.form.genericObject.name
                        this.form.wasteThreeGenericName           = this.form.genericObject?.integrated_transport_name ? this.form.genericObject.integrated_transport_name : this.form.genericObject.name
                        this.form.wasteThreeContainerName         = 'Paca'
                        this.form.wasteThreeBale                  = true
                        this.form.wasteThreeData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                    } else if ('Paca' == this.form.wasteThreeContainerName && selectedWaste.genericName == this.form.wasteThreeGenericSimpleName) {
                        this.form.wasteThreeData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                    } else if (!this.form.wasteFourData.length) {
                        this.form.wasteFourGenericId             = this.form.genericObject.id
                        this.form.wasteFourGenericSimpleName     = this.form.genericObject.name
                        this.form.wasteFourGenericName           = this.form.genericObject?.integrated_transport_name ? this.form.genericObject.integrated_transport_name : this.form.genericObject.name
                        this.form.wasteFourContainerName         = 'Paca'
                        this.form.wasteFourBale                  = true
                        this.form.wasteFourData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                    } else if ('Paca' == this.form.wasteFourContainerName && selectedWaste.genericName == this.form.wasteFourGenericSimpleName) {
                        this.form.wasteFourData.push({manifestWasteId: selectedWaste.manifestWasteId, currentQuantity: selectedWaste.currentQuantity, quantity: selectedWaste.quantity, container: selectedWaste.container, genericName: selectedWaste.genericName})
                    }
                })

                this.toggleAddBaleWasteModal()
            },

            // Edit bale functions
            openEditBaleWasteModal(waste) {
                this.showEditBaleWasteModal         = true
                this.numberWaste                    = waste != null ? waste : 0
                this.formBaleGeneric.containerName  = 'Paca'

                if (waste==1) {
                    this.numberGenericWaste         = this.form.wasteOneGenericSimpleName
                    this.numberContainerWaste       = this.form.wasteOneContainerName
                    this.formBaleGeneric.id         = this.form.wasteOneGenericId
                    this.formBaleGeneric.searchText = ''
                }

                if (waste==2) {
                    this.numberGenericWaste         = this.form.wasteTwoGenericSimpleName
                    this.numberContainerWaste       = this.form.wasteTwoContainerName
                    this.formBaleGeneric.id         = this.form.wasteTwoGenericId
                    this.formBaleGeneric.searchText = ''
                }

                if (waste==3) {
                    this.numberGenericWaste         = this.form.wasteThreeGenericSimpleName
                    this.numberContainerWaste       = this.form.wasteThreeContainerName
                    this.formBaleGeneric.id         = this.form.wasteThreeGenericId
                    this.formBaleGeneric.searchText = ''
                }

                if (waste==4) {
                    this.numberGenericWaste         = this.form.wasteFourGenericSimpleName
                    this.numberContainerWaste       = this.form.wasteFourContainerName
                    this.formBaleGeneric.id         = this.form.wasteFourGenericId
                    this.formBaleGeneric.searchText = ''
                }

                this.indexBaleGeneric()
            },
            closeEditBaleWasteModal() {
                this.showEditBaleWasteModal         = false
                this.formBaleGeneric.id             = null
                this.formBaleGeneric.containerName  = null
                this.formBaleGeneric.searchText     = ''
                this.formBaleGeneric.page           = 1
                this.editBaleSelectedWastes         = []
                this.wastes                         = []
                this.numberWaste                    = 0
            },
            changeEditBaleInput(waste, refCheck, refInput) {
                if (this.$refs[`${refCheck}${waste.id}`].checked) {
                    this.inputEnabled(`${refInput}${waste.id}`)
                    this.addEditBaleSelectedWastes(waste, refCheck, refInput)
                } else if (!this.$refs[`${refCheck}${waste.id}`].checked) {
                    this.inputDisabled(`${refInput}${waste.id}`)
                    this.removeEditBaleSelectedWastes(waste, refCheck, refInput)
                    this.$refs[`${refInput}${waste.id}`].value = ''
                }
            },
            addEditBaleSelectedWastes(waste, refCheck, refInput) {
                this.editBaleSelectedWastes.push({manifestWasteId: waste.id, container: waste.container.name, currentQuantity: waste.quantity, quantity: null, genericName: null})
            },
            removeEditBaleSelectedWastes(waste, refCheck, refInput) {
                const newEditBaleSelectedGeneric    = this.editBaleSelectedWastes.filter(e => {
                    return e.manifestWasteId != waste.id
                })
                this.editBaleSelectedWastes         = newEditBaleSelectedGeneric
            },
            editBaleWaste() {
                this.btnEditBaleWasteDisabled   = true

                if (this.numberWaste == 1) {
                    this.form.wasteOneData.forEach(e => {
                        this.editBaleSelectedWastes.forEach((editSelectedWaste) => {
                            if (e.manifestWasteId == editSelectedWaste.manifestWasteId) {
                                e.quantity          = editSelectedWaste.quantity
                                e.currentQuantity   = editSelectedWaste.currentQuantity
                                e.container         = editSelectedWaste.container
                                e.genericName       = editSelectedWaste.genericName
                            }
                        })
                    })

                    const pushArray = this.getDifference(this.editBaleSelectedWastes, this.form.wasteOneData)
                    if (pushArray.length) {
                        pushArray.forEach((e) => {
                            this.form.wasteOneData.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                        })
                    }

                    const deleteArray = this.getDifference(this.form.wasteOneData, this.editBaleSelectedWastes)
                    this.form.wasteOneData = this.getDifference(this.form.wasteOneData, deleteArray)
                }

                if (this.numberWaste == 2) {
                    this.form.wasteTwoData.forEach(e => {
                        this.editBaleSelectedWastes.forEach((editSelectedWaste) => {
                            if (e.manifestWasteId == editSelectedWaste.manifestWasteId) {
                                e.quantity          = editSelectedWaste.quantity
                                e.currentQuantity   = editSelectedWaste.currentQuantity
                                e.container         = editSelectedWaste.container
                                e.genericName       = editSelectedWaste.genericName
                            }
                        })
                    })

                    const pushArray = this.getDifference(this.editBaleSelectedWastes, this.form.wasteTwoData)
                    if (pushArray.length) {
                        pushArray.forEach((e) => {
                            this.form.wasteTwoData.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                        })
                    }

                    const deleteArray = this.getDifference(this.form.wasteTwoData, this.editBaleSelectedWastes)
                    this.form.wasteTwoData = this.getDifference(this.form.wasteTwoData, deleteArray)
                }

                if (this.numberWaste == 3) {
                    this.form.wasteThreeData.forEach(e => {
                        this.editBaleSelectedWastes.forEach((editSelectedWaste) => {
                            if (e.manifestWasteId == editSelectedWaste.manifestWasteId) {
                                e.quantity          = editSelectedWaste.quantity
                                e.currentQuantity   = editSelectedWaste.currentQuantity
                                e.container         = editSelectedWaste.container
                                e.genericName       = editSelectedWaste.genericName
                            }
                        })
                    })

                    const pushArray = this.getDifference(this.editBaleSelectedWastes, this.form.wasteThreeData)
                    if (pushArray.length) {
                        pushArray.forEach((e) => {
                            this.form.wasteThreeData.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                        })
                    }

                    const deleteArray = this.getDifference(this.form.wasteThreeData, this.editBaleSelectedWastes)
                    this.form.wasteThreeData = this.getDifference(this.form.wasteThreeData, deleteArray)
                }

                if (this.numberWaste == 4) {
                    this.form.wasteFourData.forEach(e => {
                        this.editBaleSelectedWastes.forEach((editSelectedWaste) => {
                            if (e.manifestWasteId == editSelectedWaste.manifestWasteId) {
                                e.quantity          = editSelectedWaste.quantity
                                e.currentQuantity   = editSelectedWaste.currentQuantity
                                e.container         = editSelectedWaste.container
                                e.genericName       = editSelectedWaste.genericName
                            }
                        })
                    })

                    const pushArray = this.getDifference(this.editBaleSelectedWastes, this.form.wasteFourData)
                    if (pushArray.length) {
                        pushArray.forEach((e) => {
                            this.form.wasteFourData.push({manifestWasteId: e.manifestWasteId, container: e.container, currentQuantity: e.currentQuantity, quantity: e.quantity, genericName: e.genericName})
                        })
                    }

                    const deleteArray = this.getDifference(this.form.wasteFourData, this.editBaleSelectedWastes)
                    this.form.wasteFourData = this.getDifference(this.form.wasteFourData, deleteArray)
                }

                this.closeEditBaleWasteModal()
            },
            openConsolidatedFileModal() {
                this.showConsolidatedFileModal      = true
            },
            openCarrierInvoiceFileModal() {
                this.showCarrierInvoiceFileModal    = true
            },
            openRecipientInvoiceFileModal() {
                this.showRecipientInvoiceFileModal  = true
            },
            closeConsolidatedFileModal() {
                this.showConsolidatedFileModal      = false
            },
            closeCarrierInvoiceFileModal() {
                this.showCarrierInvoiceFileModal    = false
            },
            closeRecipientInvoiceFileModal() {
                this.showRecipientInvoiceFileModal  = false
            },
            getConsolidatedFile(file) {
                this.form.consolidatedFile          = file
            },
            getCarrierInvoiceFile(file) {
                this.form.carrierInvoiceFile        = file
            },
            getRecipientInvoiceFile(file) {
                this.form.recipientInvoiceFile      = file
            },
            onChangePage(value) {
                this.formGeneric.page = value
                this.indexGeneric()
            },
            onChangePageBale(value) {
                this.formBaleGeneric.page = value
                this.indexBaleGeneric()
            },
        },
        mounted() {
            this.init()
        },
        watch: {
            selectedBaleWastes: {
                deep: true,
                immediate: true,
                handler(data) {
                    if (data.length) {
                        var checkNull = []
                        this.selectedBaleWastes.forEach(e => {
                            if (e.quantity == null) {
                                checkNull.push(e)
                            } else {
                                const checkNullFilter = checkNull.filter(element => {
                                    return e.manifestWasteId != element.manifestWasteId

                                })
                                checkNull = checkNullFilter
                            }
                        })

                        if (checkNull.length) {
                            this.btnAddBaleWasteDisabled    = true
                        } else {
                            this.btnAddBaleWasteDisabled    = false
                        }
                    } else {
                        this.btnAddBaleWasteDisabled = true
                    }
                }
            },
            editBaleSelectedWastes: {
                deep: true,
                handler(data) {
                    if (data.length) {
                        var checkNull = []
                        this.editBaleSelectedWastes.forEach(e => {
                            if (e.quantity == null) {
                                checkNull.push(e)
                            } else {
                                const checkNullFilter = checkNull.filter(element => {
                                    return e.manifestWasteId != element.manifestWasteId

                                })
                                checkNull = checkNullFilter
                            }
                        })

                        if (checkNull.length) {
                            this.btnEditBaleWasteDisabled    = true
                        } else {
                            this.btnEditBaleWasteDisabled    = false
                        }
                    } else {
                        this.btnEditBaleWasteDisabled = true
                    }
                }
            },
            editSelectedWastes: {
                deep: true,
                immediate: true,
                handler(data) {
                    if (data.length) {
                        var checkNull = []
                        this.editSelectedWastes.forEach(e => {
                            if (e.quantity == null) {
                                checkNull.push(e)
                            } else {
                                const checkNullFilter = checkNull.filter(element => {
                                    return e.manifestWasteId != element.manifestWasteId

                                })
                                checkNull = checkNullFilter
                            }
                        })

                        if (checkNull.length) {
                            this.btnEditWasteDisabled    = true
                        } else {
                            this.btnEditWasteDisabled    = false
                        }
                    } else {
                        this.btnEditWasteDisabled = true
                    }
                }
            },
            selectedWastes: {
                deep: true,
                immediate: true,
                handler(data) {
                    if (data.length) {
                        var checkNull = []
                        this.selectedWastes.forEach(e => {
                            if (e.quantity == null) {
                                checkNull.push(e)
                            } else {
                                const checkNullFilter = checkNull.filter(element => {
                                    return e.manifestWasteId != element.manifestWasteId

                                })
                                checkNull = checkNullFilter
                            }
                        })

                        if (checkNull.length) {
                            this.btnAddWasteDisabled    = true
                        } else {
                            this.btnAddWasteDisabled    = false
                        }
                    } else {
                        this.btnAddWasteDisabled        = true
                    }
                }
            },
            countWastes: {
                deep: true,
                handler(data) {
                    if (data == 0 && !this.form.generator) {
                        this.remainingWastes = 4
                        this.btnConsolidatedBaleWastesDisabled  = true
                        this.btnConsolidatedWastesDisabled      = true
                        this.btnSaveDisabled                    = true
                    }

                    if (data == 1) {
                        this.remainingWastes = 3
                        this.btnConsolidatedBaleWastesDisabled  = false
                        this.btnConsolidatedWastesDisabled      = false
                        this.btnSaveDisabled                    = false

                    }

                    if (data == 2) {
                        this.remainingWastes = 2
                        this.btnConsolidatedBaleWastesDisabled  = false
                        this.btnConsolidatedWastesDisabled      = false
                        this.btnSaveDisabled                    = false
                    }

                    if (data == 3) {
                        this.remainingWastes = 1
                        this.btnConsolidatedBaleWastesDisabled  = false
                        this.btnConsolidatedWastesDisabled      = false
                        this.btnSaveDisabled                    = false

                    }

                    if (data == 4) {
                        this.remainingWastes = 0
                        this.btnConsolidatedBaleWastesDisabled  = true
                        this.btnConsolidatedWastesDisabled      = true
                        this.btnSaveDisabled                    = false
                    }
                }
            }
        }
    }
</script>