<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item>
                    <inertia-link :href="route('dash')">
                        <home-solid class="h-6 w-6"></home-solid>
                    </inertia-link>
                </breadcumb-item>

                <breadcumb-item :isSingle="true">
                    Manifiestos
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        <div class="grid grid-cols-1 gap-4 p-4">
            <div class="col-span-1">
                <div class="flex flex-col md:flex-row justify-between">
                    <div class="flex flex-row">
                        <div class="flex flex-row w-20">
                            <select
                                class="h-full w-max	 sm:rounded-l appearance-none bg-white text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none ring-0 focus:ring-transparent border-gray-200 focus:border-gray-200"
                                v-model="form.perPage"
                                @change="perPageChanged">
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                        <div class="flex md:w-56 w-full">
                            <input
                                @paste="search"
                                @cut="search"
                                placeholder="Buscar"
                                class="appearance-none border-gray-200 border-t border-b px-4 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                                v-model="form.search" @keyup="search" />
                        </div>
                        <div class="flex flex-row w-96 sm:w-56">
                            <select
                                class="h-full w-full sm:rounded-r appearance-none bg-white text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none ring-0 focus:ring-transparent border-gray-200 focus:border-gray-200"
                                v-model="form.perSubsidiary"
                                @change="perSubsidiaryChanged">
                                <option v-for="(subsidiary, index) in subsidiaries" :key="index" :value="subsidiary.id">{{subsidiary.descriptive_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-1">
                <div class="bg-white overflow-hidden shadow sm:rounded">
                    <table-container :heading="heading">
                        <tbody>
                            <tr v-show="loading">
                                <td :colspan="heading.length" class="h-96">
                                    <loader width="100" height="100"></loader>
                                </td>
                            </tr>

                            <tr v-if="manifests?.data?.length == 0 && !loading" class="text-center">
                                <table-body-item :colspan="heading.length">
                                    No se encontraron resultados.
                                </table-body-item>
                            </tr>

                            <tr v-else-if="manifests?.data?.length > 0 && !loading" v-for="(manifest, index) in manifests.data" :key="index">
                                <table-body-item>
                                    <span v-if="manifest.admission_at!=null">
                                        {{moment(manifest.admission_at)}}
                                    </span>
                                    <span v-else>Sin fecha</span>
                                </table-body-item>
                                <table-body-item>
                                    <span v-if="manifest.status == 'open'">Abierto</span>
                                    <span v-else-if="manifest.status == 'cancelled'">Cancelado</span>
                                    <span v-else-if="manifest.status == 'consolidated'">Consolidado</span>
                                    <span v-else-if="manifest.status == 'to-consolidate'">Por consolidar</span>
                                </table-body-item>
                                <table-body-item>
                                    {{manifest.folio}}
                                </table-body-item>
                                <table-body-item>
                                    <div v-if="manifest?.client">
                                        {{manifest.client.data.name}}
                                    </div>
                                    <div v-else-if="manifest?.generators?.data">
                                        {{manifest.generator.data.name}}
                                    </div>
                                </table-body-item>
                                <table-body-item>
                                    {{manifest.carrier.data.full_name}}
                                </table-body-item>
                                <table-body-item>
                                    {{manifest.recipient.data.full_name}}
                                </table-body-item>
                                <td class="px-6 py-4">
                                    <table-body-buttons>
                                        <a v-if="manifest.file" :href="`${filesUrl}${manifest.file}`" target="_blank"  class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-orange-500 hover:text-orange-400 transition duration-500 ease">
                                            <doc-solid class="h-5 w-5"></doc-solid>
                                        </a>

                                        <button v-else class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-orange-500 hover:text-orange-400 transition duration-500 ease">
                                            <close-solid class="h-5 w-5"></close-solid>
                                        </button>

                                        <inertia-link :href="route('dashboard.manifests.all.show', manifest.id)" class="flex items-center justify-center p-2 rounded-lg bg-white border border-gray-500 text-cyan-500 hover:text-cyan-400 transition duration-500 ease">
                                            <eye-solid class="h-5 w-5"></eye-solid>
                                        </inertia-link>
                                    </table-body-buttons>
                                </td>
                            </tr>
                        </tbody>
                    </table-container>

                    <div class="py-4 px-6">
                    <pagination :items="manifests" @nextPage="currentPage" @prevPage="currentPage" @link="currentPage" />
                </div>

                </div>
            </div>
        </div>

    </app-layout>
</template>

<script>
    import { InertiaLink }  from '@inertiajs/inertia-vue3'
    import moment           from 'moment';
    import AppLayout        from '@/Layouts/AppLayout.vue'
    import BreadcumbNav     from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem    from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid        from '@/Components/Icons/HeroIcons/Solid/Home'
    import SearchSolid      from '@/Components/Icons/HeroIcons/Solid/Search'
    import DocSolid         from '@/Components/Icons/HeroIcons/Solid/Doc'
    import CloseSolid       from '@/Components/Icons/HeroIcons/Solid/Close'
    import EyeSolid         from '@/Components/Icons/HeroIcons/Solid/Eye'
    import TableContainer   from '@/Components/Tables/TableContainer'
    import TableBodyItem    from '@/Components/Tables/TableBodyItem'
    import TableBodyButtons from '@/Components/Tables/TableBodyButtons'
    import Pagination       from '@/Components/Pagination/Pagination'
    import Loader           from '@/Components/Loader'

    const heading = ['Fecha de ingreso', 'Estado', 'Folio', 'Generadora', 'Transportista', 'Destinatario', 'Opciones']

    export default {
        components: {
            InertiaLink,
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
            SearchSolid,
            DocSolid,
            CloseSolid,
            EyeSolid,
            TableContainer,
            TableBodyItem,
            TableBodyButtons,
            Pagination,
            Loader,
        },
        props: ['subsidiaries'],
        data() {
            return {
                heading,
                form: {
                    search:         this.getTableVariables().search,
                    perPage:        this.getTableVariables().perPage,
                    pagination:     this.getTableVariables().pagination,
                    perSubsidiary:  this.getTableVariables().perSubsidiary
                },
                manifests:          [],
                tableVariables:     null,
                loading:            true,
                filesUrl:           (process.env.APP_ENV === 'local' ? process.env.APP_URL : process.env.ASSETS_URL) + '/files/',
                processing:         false,
                showCancelModal:    false,
                cancelForm: {
                    id:             null,
                    reazon:         '',
                    errorReazon:    false,
                    file:           null,
                    errorFile:      false
                },
                folio:              null,
            }
        },
        methods: {
            getTableVariables() {
                return JSON.parse(window.localStorage.getItem(`${this.$page.url}-table`))
            },
            perPageChanged() {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination  = 1
                    this.form.pagination            = 1
                }
                this.tableVariables.perPage         = this.form.perPage
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))

               this.index()
            },
            perSubsidiaryChanged() {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination  = 1
                    this.form.pagination            = 1
                }
                this.tableVariables.perSubsidiary   = this.form.perSubsidiary
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
               this.index()
            },
            async index() {
                this.loading      = true
                const manifests   = await axios.post(route('api.manifests.all.index'), this.form)
                this.manifests    = manifests.data
                this.loading      = false
            },
            search: _.debounce(function () {
                if (this.tableVariables.pagination!=1) {
                    this.tableVariables.pagination  = 1
                    this.form.pagination            = 1
                }
                this.tableVariables.search          = this.form.search
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))

                this.index()
            }, 300),
            async currentPage(page) {
                this.tableVariables.pagination = page.substring(page.indexOf("=") + 1)
                localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(this.tableVariables))
                this.form.pagination = page.substring(page.indexOf("=") + 1)

                this.index()
            },
            moment(date) {
                return moment(date.substring(0, 10)).format('DD/MM/YYYY')
            }
        },
        beforeCreate: function () {
            if (!window.localStorage.getItem(`${this.$page.url}-table`)) {
                const table = {
                    perPage:        25,
                    pagination:     1,
                    search:         '',
                    perSubsidiary:  1
                }
                window.localStorage.setItem(`${this.$page.url}-table`, JSON.stringify(table))
            }
        },
        mounted() {
            this.tableVariables = JSON.parse(localStorage.getItem(`${this.$page.url}-table`))
            this.index()
        },
        watch: {
            cancelForm: {
                deep: true,
                handler(data) {
                    if (data.reazon) {
                        this.cancelForm.errorReazon=false
                    }
                    if (data.file) {
                        this.cancelForm.errorFile=false
                    }
                }
            }
        }
    }
</script>