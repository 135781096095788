<template>
    <svg ref="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
    </svg>
</template>

<script>
    export default {
        methods: {
            visible() {
                this.$refs.svg.classList.remove('hidden')
                this.$refs.svg.classList.add('visible')
            },
            hidden() {
                this.$refs.svg.classList.remove('visible')
                this.$refs.svg.classList.add('hidden')
            }
        }
    }
</script>