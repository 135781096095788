<template>
    <div style="padding-top: 162px; padding-left: 400px; font-size: 12px; color: black;">
        <div style="height: 24px;" v-if="consolidated.subsidiary!=null">
            {{consolidated.subsidiary.data['nra']}}
        </div>
    </div>

    <div style="padding-top: 2px; padding-left: 170px; font-size: 11px; color: black;">
        <div v-if="consolidated.subsidiary!=null">
            {{consolidated.subsidiary.data['name']}}
        </div>
    </div>

    <div style="position: relative; padding-top: 2px; padding-left: 160px; font-size: 12px; color: black; height: 30px;">
        <span style="display: inline-block;  width: 320px; line-height: 90%;">
            {{consolidated.subsidiary.data.FullAddress}}
        </span>

        <span style="position: absolute; left: 518px;">
            {{municipality.name}}
        </span>

        <span style="position: absolute; left: 670px;">
            {{state.name}}
        </span>
    </div>

    <div style="padding-left: 120px; height:24px; font-size: 12px; color: black;">
        <div v-if="consolidated.subsidiary!=null">
            {{consolidated.subsidiary.data['phone']}}
        </div>
    </div>

    <div style="line-height: 95%; width: 525px; padding-top: 44px; padding-left: 35px; font-size: 11px; color: black;">
        <div style="height: 40px;">
            <div v-if="wastes.length > 0">
                {{wastes[0].integrated_transport_name}}
            </div>
        </div>
    </div>

    <div style="line-height: 95%; width: 525px; padding-top: 10px; padding-left: 35px; font-size: 11px; color: black;">
        <div style="height: 40px;">
            <div v-if="wastes.length > 1">
                {{wastes[1].integrated_transport_name}}
            </div>
        </div>
    </div>

    <div style="line-height: 95%; width: 525px; padding-top: 8px; padding-left: 35px; font-size: 11px; color: black;">
        <div style="height: 40px;">
            <div v-if="wastes.length > 2">
                {{wastes[2].integrated_transport_name}}
            </div>
        </div>
    </div>

    <div style="line-height: 95%; width: 525px; padding-top: 8px; padding-left: 35px; font-size: 11px; color: black;">
        <div style="height: 40px;">
            <div v-if="wastes.length > 3">
                {{wastes[3].integrated_transport_name}}
            </div>
        </div>
    </div>

    <div style="padding-top: 5px; padding-left: 35px; font-size: 11px; color: black; height: 70px;">
        <div style="padding-left: 355px;">
            ESTE MANIFIESTO AMPARA LOS SIGUIENTES MANIFIESTOS:
        </div>
        <div>
            {{folios}}
        </div>
    </div>

    <div style="padding-top: 20px; padding-left: 230px; font-size: 12px; color: black;">
        <div style="height: 24px">
            <div v-if="consolidated.subsidiary!=null">
                <div v-if="consolidated.subsidiary.representatives.length > 0">
                    {{consolidated.subsidiary.representatives[0].name}}
                </div>
            </div>
        </div>
    </div>

    <div style="line-height: 95%; width: 530px; padding-top: 15px; padding-left: 280px; font-size: 12px; color: black;">
        <div style="height: 200px;">
            {{consolidated.carrier.data['name']}} <br>
            {{consolidated.carrier.data.FullAddress}} <br>

            {{municipalityCarrier.name}},
            {{stateCarrier.name}} <br>
            TEL: {{consolidated.carrier.data['phone']}} <br>
            SEMARNAT: {{consolidated.carrier.data['semarnat_permit']}} <br>
            S.C.T.: {{consolidated.carrier.data['sct_permit']}} <br>
        </div>
    </div>

    <div style="line-height: 95%; padding-left: 538px; padding-top: 25px; font-size:12px; color: black;">
        <div style="width: 230px;">
            {{consolidated.recipient.data['name']}} <br>
            {{consolidated.recipient.data.FullAddress}} <br>
            AUTORIZACIÓN: {{consolidated.recipient.data['semarnat_permit']}}
        </div>
    </div>

</template>

<script>
    export default {
        props: ['consolidated', 'state', 'municipality', 'stateCarrier', 'municipalityCarrier', 'folios', 'wastes'],
    }
</script>
