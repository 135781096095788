<template>
    <div style="padding-top: 162px; padding-left: 370px; font-size:12px; color: black;">
        <div style="height: 24px;" v-if="manifest.client!=null">
            {{manifest.client.data['nare']}}
        </div>
    </div>

    <div style="padding-top: 2px; padding-left: 160px; font-size:11px; color: black;">
        <div v-if="manifest.client!=null">
            {{manifest.client.data['name']}}
        </div>
    </div>

    <div style="position: relative; padding-top: 2px; padding-left: 160px; font-size:12px; color: black; height: 38px;">
        <span style="display: inline-block;  width: 320px; line-height: 90%;" v-if="manifest.client!=null">
            {{manifest.client.data.FullAddress}}
        </span>

        <span style="position: absolute; left: 518px;" v-if="manifest.client!=null">
            {{municipality.name}}
        </span>

        <span style="position: absolute; left: 670px;" v-if="manifest.client!=null">
            {{state.name}}
        </span>
    </div>

    <div style="padding-left: 120px; height:24px; font-size:12px; color: black;">
        <div v-if="manifest.client!=null">
            {{manifest.client.data['phone']}}
        </div>
    </div>


    <div style="line-height: 95%; width: 530px; padding-top: 45px; padding-left: 45px; font-size: 12px; color: black;">
        <div style="height: 40px;">
            <div v-if="manifest.manifest_wastes.length > 0">
                {{manifest.manifest_wastes[0].waste.name}}
            </div>
        </div>
    </div>

    <div style="line-height: 95%; width: 530px; padding-top: 10px; padding-left: 45px; font-size: 12px; color: black;">
        <div style="height: 40px;">
            <div v-if="manifest.manifest_wastes.length > 1">
                {{manifest.manifest_wastes[1].waste.name}}
            </div>
        </div>
    </div>


    <div style="line-height: 95%; width: 530px; padding-top: 8px; padding-left: 45px; font-size: 12px; color: black;">
        <div style="height: 40px;">
            <div v-if="manifest.manifest_wastes.length > 2">
                {{manifest.manifest_wastes[2].waste.name}}
            </div>
        </div>
    </div>

    <div style="line-height: 95%; width: 530px; padding-top: 8px; padding-left: 45px; font-size: 12px; color: black;">
        <div style="height: 40px;">
            <div v-if="manifest.manifest_wastes.length > 3">
                {{manifest.manifest_wastes[3].waste.name}}
            </div>
        </div>
    </div>

    <div style="padding-top: 90px; padding-left: 230px; font-size: 12px; color: black;">
        <div style="height: 24px">
            <div v-if="manifest.client!=null">
                <div v-if="manifest.client.representatives.length > 0">
                    {{manifest.client.representatives[0].name}}
                </div>
            </div>
        </div>
    </div>

    <div style="line-height: 95%; width: 530px; padding-top: 15px; padding-left: 280px; font-size: 12px; color: black;">
        <div style="height: 200px;">
            {{manifest.carrier.data['name']}} <br>
            {{manifest.carrier.data.FullAddress}} <br>

            {{municipalityCarrier.name}},
            {{stateCarrier.name}} <br>
            TEL: {{manifest.carrier.data['phone']}} <br>
            L.A.I.: {{manifest.carrier.data['lai_permit']}} <br>
        </div>
    </div>

    <div style="line-height: 95%; padding-left: 538px; padding-top: 25px; font-size:12px; color: black;">
        <div style="width: 230px;">
            {{manifest.recipient.data['name']}} <br>
            {{manifest.recipient.data.FullAddress}} <br>
            AUTORIZACIÓN: {{manifest.recipient.data['lai_permit']}}
        </div>
    </div>
</template>

<script>
    export default {
        props: ['manifest', 'state', 'municipality', 'stateCarrier', 'municipalityCarrier'],
    }
</script>