<template>
    <app-layout>
        <template #header>
            <breadcumb-nav>
                <breadcumb-item :isSingle="true">
                    <home-solid class="h-6 w-6"></home-solid>
                </breadcumb-item>
            </breadcumb-nav>
        </template>

        
    </app-layout>
</template>

<script>
    import AppLayout from '@/Layouts/AppLayout.vue'
    import BreadcumbNav from '@/Components/Breadcumb/BreadcumbNav'
    import BreadcumbItem from '@/Components/Breadcumb/BreadcumbItem'
    import HomeSolid from '@/Components/Icons/HeroIcons/Solid/Home'

    export default {
        components: {
            AppLayout,
            BreadcumbNav,
            BreadcumbItem,
            HomeSolid,
        }
    }
</script>