<template>
    <inertia-link :href="href" class="h-full w-full inline-flex items-center justify-center sm:rounded px-4 py-2 bg-blue-500 hover:bg-blue-400 transition duration-500 ease font-semibold text-xs sm:text-sm text-white uppercase tracking-widest select-none">
        <slot></slot>
    </inertia-link>
</template>

<script>
    import { InertiaLink } from '@inertiajs/inertia-vue3'

    export default {
        components: {InertiaLink},
        props: ['href']
    }
</script>