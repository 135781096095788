<template>

    <div class="bg-white px-6 py-4 flex items-center justify-between">
        <div class="flex-1 flex justify-between sm:hidden">
            <button type="button" @click="onClickPreviousPage" :disabled="isInFirstPage" :class="{'cursor-not-allowed': isInFirstPage, 'cursor-pointer': !isInFirstPage}"
                class="relative inline-flex items-center px-2 py-2 rounded border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span class="sr-only">Previous</span>
                <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <button type="button" @click="onClickNextPage" :disabled="isInLastPage" :class="{'cursor-pointer': isInLastPage, 'cursor-not-allowed': !isInLastPage}"
                class="relative inline-flex items-center px-2 py-2 rounded border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span class="sr-only">Next</span>
                <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
        </div>
        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                <p class="text-sm text-gray-700">
                    Mostrando
                    {{ ' ' }}
                    <span class="font-medium">{{from}}</span>
                    {{ ' ' }}
                    a
                    {{ ' ' }}
                    <span class="font-medium">{{to}}</span>
                    {{ ' ' }}
                    de
                    {{ ' ' }}
                    <span class="font-medium">{{totalPages}}</span>
                    {{ ' ' }}
                    resultados
                </p>
            </div>
            <div>
                <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">

                    <button type="button"
                        @click="onClickPreviousPage"
                        :disabled="isInFirstPage"
                        class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        :class="{'cursor-not-allowed':isInFirstPage, 'cursor-pointer':!isInFirstPage}">
                        <span class="sr-only">Previous</span>
                        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                    </button>

                    <div class="pagination-item" v-for="page in pages" :key="page.name">
                        <button
                            type="button"
                            :disabled="page.isDisabled"
                            class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                            :class="{
                                'z-10 bg-orange-50 border-orange-500 text-orange-600': isPageActive(page.name),
                                'bg-white border-gray-300 text-gray-500 hover:bg-gray-50': !isPageActive(page.name),
                                'cursor-not-allowed': page.isDisabled,
                                'cursor-pointer': !page.isDisabled
                            }"
                            @click="onClickPage(page.name)">
                            {{ page.name }}
                        </button>
                    </div>

                    <button type="button" @click="onClickNextPage" :disabled="isInLastPage"
                        class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Next</span>
                        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                    </button>

                </nav>
            </div>
        </div>
    </div>


</template>

<script>
    import {
        ChevronLeftIcon,
        ChevronRightIcon,
        ChevronDoubleLeftIcon,
        ChevronDoubleRightIcon
    } from '@heroicons/vue/solid'

    export default {
        components: {
            ChevronLeftIcon,
            ChevronRightIcon,
            ChevronDoubleLeftIcon,
            ChevronDoubleRightIcon
        },
        props: {
            maxVisibleButtons: {
                type: Number,
                required: false,
                default: 3
            },
            totalPages: {
                type: Number,
                required: true
            },
            lastPage: {
                type: Number,
                required: true
            },
            perPage: {
                type: Number,
                required: true
            },
            currentPage: {
                type: Number,
                required: true
            },
            to: {
                type: Number,
                required: true
            },
            from: {
                type: Number,
                required: true
            },
        },
        computed: {
            startPage() {
                // When on the first page
                if (this.currentPage === 1) {
                    return 1;
                }

                // When on the last page
                if (this.currentPage === this.lastPage) {
                    return (this.lastPage - this.maxVisibleButtons) + 1;
                }

                // When inbetween
                return this.currentPage - 1;
            },
            pages() {
                const range = [];
                for (let i = this.startPage; i <= (this.startPage + this.maxVisibleButtons - 1); i++) {
                    range.push({
                        name: i,
                        isDisabled: i === this.currentPage
                    });
                }
                return range;
            },
            isInFirstPage() {
                return this.currentPage === 1;
            },
            isInLastPage() {
                return this.currentPage === this.lastPage;
            },
        },
        methods: {
            onClickPreviousPage() {
                this.$emit('pagechanged', this.currentPage - 1);
            },
            onClickPage(page) {
                this.$emit('pagechanged', page);
            },
            onClickNextPage() {
                this.$emit('pagechanged', this.currentPage + 1);
            },
            isPageActive(page) {
                return this.currentPage === page;
            }
        }
    };
</script>