<template>
    <div style="padding-top: 151px; padding-left: 400px; font-size: 12px; color: black;">
        <div style="height: 24px;" v-if="manifest.client!=null">
            {{manifest.client.data['nra']}}
        </div>
    </div>

    <div style="padding-top: 2px; padding-left: 160px; font-size: 11px; color: black;">
        <div v-if="manifest.client!=null">
            {{manifest.client.data['name']}}
        </div>
    </div>

    <div style="position: relative; padding-top: 2px; padding-left: 160px; font-size: 12px; color: black; height: 38px;">
        <span style="display: inline-block;  width: 320px; line-height: 90%;" v-if="manifest.client!=null">
            {{manifest.client.data.FullAddress}}
        </span>

        <span style="position: absolute; left: 518px;" v-if="manifest.client!=null">
            {{municipality.name}}
        </span>

        <span style="position: absolute; left: 670px;" v-if="manifest.client!=null">
            {{state.name}}
        </span>
    </div>

    <div style="padding-left: 120px; height:24px; font-size: 12px; color: black;">
        <div v-if="manifest.client!=null">
            {{manifest.client.data['phone']}}
        </div>
    </div>

    <div style="line-height: 95%; width: 530px; padding-top: 44px; padding-left: 35px; font-size: 11px; color: black;">
        <div style="height: 40px;">
            <div v-if="manifest.manifest_wastes.length > 0">
                <div v-if="manifest.manifest_wastes[0].waste.status_rpbi == false">
                    {{manifest.manifest_wastes[0].waste.integrated_transport_name}}
                </div>
                <div v-else>
                    {{manifest.manifest_wastes[0].waste.name}}
                </div>
            </div>
        </div>
    </div>

    <div style="line-height: 95%; width: 530px; padding-top: 10px; padding-left: 35px; font-size: 11px; color: black;">
        <div style="height: 40px;">
            <div v-if="manifest.manifest_wastes.length > 1">
                <div v-if="manifest.manifest_wastes[1].waste.status_rpbi == false">
                    {{manifest.manifest_wastes[1].waste.integrated_transport_name}}
                </div>
                <div v-else>
                    {{manifest.manifest_wastes[1].waste.name}}
                </div>
            </div>
        </div>
    </div>

    <div style="line-height: 95%; width: 530px; padding-top: 8px; padding-left: 35px; font-size: 11px; color: black;">
        <div style="height: 40px;">
            <div v-if="manifest.manifest_wastes.length > 2">
                <div v-if="manifest.manifest_wastes[2].waste.status_rpbi == false">
                    {{manifest.manifest_wastes[2].waste.integrated_transport_name}}
                </div>
                <div v-else>
                    {{manifest.manifest_wastes[2].waste.name}}
                </div>
            </div>
        </div>
    </div>

    <div style="line-height: 95%; width: 530px; padding-top: 8px; padding-left: 35px; font-size: 11px; color: black;">
        <div style="height: 40px;">
            <div v-if="manifest.manifest_wastes.length > 3">
                <div v-if="manifest.manifest_wastes[3].waste.status_rpbi == false">
                    {{manifest.manifest_wastes[3].waste.integrated_transport_name}}
                </div>
                <div v-else>
                    {{manifest.manifest_wastes[3].waste.name}}
                </div>
            </div>
        </div>
    </div>

    <div style="padding-top: 90px; padding-left: 230px; font-size: 12px; color: black;">
        <div style="height: 24px">
            <div v-if="manifest.client!=null">
                <div v-if="manifest.client.representatives.length > 0">
                    {{manifest.client.representatives[0].name}}
                </div>
            </div>
        </div>
    </div>

    <div style="line-height: 95%; width: 530px; padding-top: 15px; padding-left: 280px; font-size: 12px; color: black;">
        <div style="height: 200px;">
            {{manifest.carrier.data['name']}} <br>
            {{manifest.carrier.data.FullAddress}} <br>

            {{municipalityCarrier.name}},
            {{stateCarrier.name}} <br>
            TEL: {{manifest.carrier.data['phone']}} <br>
            SEMARNAT: {{manifest.carrier.data['semarnat_permit']}} <br>
            S.C.T.: {{manifest.carrier.data['sct_permit']}} <br>
        </div>
    </div>
LABORATORIO BIO CLINICO MARTINEZ SUC. NAVOJOA (Q.B. RHENZO ULISES DEVORA MARTINEZ)
MAQUILAS TETAKAWI S.A DE C.V DPTO COOPER STANDAR LATIN AMERICA GUAYMAS FUEL AND BRAKE
CLINICAS DENTALES DF (UM PLAZA SENDERO OBREGON) / DR. EDSON LOPEZ QUIÑONEZ)
MAQUILAS TETA KAWI S.A. DE C.V. DEPTO. TE CONNECTIVITY AUTOMOTRIZ PLANTA 2

    <div style="line-height: 95%; padding-left: 538px; padding-top: 25px; font-size:12px; color: black;">
        <div style="width: 230px;">
            {{manifest.recipient.data['name']}} <br>
            {{manifest.recipient.data.FullAddress}} <br>
            AUTORIZACIÓN: {{manifest.recipient.data['semarnat_permit']}}
        </div>
    </div>

</template>

<script>
    export default {
        props: ['manifest', 'state', 'municipality', 'stateCarrier', 'municipalityCarrier'],
    }
</script>
