<template>
    <div class="relative">
        <div class="block" :class="disabled ? 'cursor-not-allowed' : 'cursor-default'">
            <div
                class="flex flex-row items-center pl-3 z-0 w-full text-left transition duration-150 ease-in-out bg-white sm:rounded-md focus:outline-none sm:text-sm sm:leading-5 shadow-sm border hover:cursor-pointer"
                :class="hasFocus ? 'border-orange-500' : 'border-gray-300'">
                <input
                    @focus="onFocus"
                    @blur="onBlur"
                    @keyup.esc="onBlur"
                    @keyup.up="onPrevious"
                    @keyup.down="onNext"
                    @keyup.enter="selectActiveOption"
                    @keyup.delete="deleteSeclectOption"
                    @keypress="preventKeypress"
                    ref="input"
                    :required="isRequired"
                    autocomplete="algoquenoseaautocompletadoporqueaunasilopongaseactocompletaporquegugel"
                    tabindex="-1"
                    v-model="searchText"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    :class="disabled ? 'cursor-not-allowed' : 'cursor-default'"
                    class="w-full py-2 bg-white text-sm appearance-none focus:ring-transparent focus:outline-none sm:rounded-md" />

                <span v-show="busying">
                    <Spin class="text-gray-400"></Spin>
                </span>

                <span class="pr-2">
                    <SelectorIcon @click="onFocus" class="w-5 h-5 text-gray-300"></SelectorIcon>
                </span>
            </div>
        </div>

        <div v-show="hasFocus" class="absolute z-50 min-w-44 w-auto mt-1 bg-white sm:rounded-md shadow">
            <ul ref="listbox"
                tabindex="-1"
                class="py- overflow-auto text-base leading-6 sm:rounded-md shadow-xs max-h-44 focus:outline-none sm:text-sm sm:leading-5">
                <li v-show="hasOptions" :key="option[key]" v-for="option in filterOptions">
                    <div
                        :class="{'bg-gray-100': getActiveOption(option)}"
                        @mouseover="activateOption(option)"
                        @mousedown="selectOption(option)"
                        class="flex flex-col py-2 px-3 text-gray-900 cursor-default select-none hover:bg-gray-100">
                        <span class="flex flex-row items-center justify-between">
                            <span>{{option[label]}}</span>

                            <span v-show="getSelectedActive(option)"
                                class="flex items-center text-orange-500">
                                <CheckIcon class="w-5 h-5"></CheckIcon>
                            </span>
                        </span>
                    </div>
                </li>

                <li
                    v-show="!hasOptions && !searchText.length"
                    v-text="emptyOptionsMessage"
                    class="px-3 py-2 text-gray-900 cursor-default select-none"></li>

                <li
                    v-show="!hasOptions && searchText.length"
                    v-text="emptyOptionsSearchMessage"
                    class="px-3 py-2 text-gray-900 cursor-default select-none"></li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Fuse             from 'fuse.js'
    import { XCircleIcon,
            SelectorIcon,
            CheckIcon }     from '@heroicons/vue/solid'
    import Spin             from '@/Components/Spin'

    export default {
        components: {
            SelectorIcon,
            XCircleIcon,
            CheckIcon,
            Spin
        },
        props: {
            options:                    {type: [Array],   default: []},
            placeholder:                {type: String,  default: ''},
            name:                       {type: String,  default: ''},
            emptyOptionsMessage:        {type: String,  default: 'Ningún registro encontrado.'},
            emptyOptionsSearchMessage:  {type: String,  default: 'Ningún resultado coincide con su búsqueda.'},
            label:                      {type: String,  default: 'name'},
            key:                        {type: String,  default: 'id'},
            multiple:                   {type: Boolean, default: false},
            keys:                       {type: [Array, Object, String, Number], default: ['name', 'id']},
            value:                      {type: [Array, Object, String, Number], default: null},
            disabled:                   {type: Boolean, default: false},
            busy:                       {type: Boolean, default: false},
            isRequired:                 {type: Boolean, default: false}
        },
        data() {
            return {
                searchText:         '',
                activeOptionKey:    '',
                selected:           [],
                hasFocus:           false,
                filterOptions:      [],
                hasOptions:         false,
                busying:            false
            }
        },
        emits: ['input'],
        methods: {
            onFocus() {
                if (!this.disabled) {
                    this.hasFocus = !this.disabled
                    this.$refs.input.focus()
                }
            },
            onBlur() {
                if (!this.selected.length) {
                    this.searchText        = ''
                    this.activeOptionKey   = ''
                }
                this.hasFocus = false
                this.$refs.input.blur()
            },
            onPrevious() {
                if (this.activeOptionIndex > 0 && this.filterOptions.length) {
                    this.activateOption(this.filterOptions[this.activeOptionIndex - 1])
                }

                this.$refs.listbox.children[this.activeOptionIndex].scrollIntoView({
                    block: "center",
                })
            },
            onNext() {
                if (this.filterOptions.length - 1 > this.activeOptionIndex) {
                    this.activateOption(this.filterOptions[this.activeOptionIndex + 1])
                }

                this.$refs.listbox.children[this.activeOptionIndex].scrollIntoView({
                    block: "center"
                })
            },
            activateOption(option) {
                this.activeOptionKey = option[this.key]
            },
            getActiveOption(option) {
                let active = false
                if (this.activeOption!=null) {
                    active = option[this.key] === this.activeOption[this.key]
                }
                return active
            },
            getSelectedActive(option) {
                return option == this.selected[0]
            },
            setSelectedValue(value) {
                if (Array.isArray(value)) {
                    this.selected = value.slice()
                } else if (value) {
                    const option      = this.filterOptions.find((option) => option[this.key] == value)
                    if (option) {
                        this.selected   = [option]
                        this.searchText = this.selected[0][this.label]
                    }

                } else {
                    this.deleteSeclectOption()
                }
            },
            selectOption(option) {
                if (!option) return
                if (this.multiple) {
                    this.onFocus()
                } else {
                    this.onBlur()
                }
                const value = option['formatted'] ? option[this.label] : option
                if (this.multiple) {
                    this.selected.push(value)
                } else {
                    this.selected   = [value]
                    this.searchText = this.selected[0][this.label]
                }
                this.emitInput()
            },
            emitInput() {
                if (this.multiple) {
                    this.$emit('input', this.selected)
                } else {
                    this.$emit('input', this.selected.length ? this.selected[0] : null)
                }
            },
            selectActiveOption() {
                if (this.activeOption) this.selectOption(this.activeOption)
            },
            init() {
                this.filterOptions = this.options.length ? this.options : []
            },
            deleteSeclectOption() {
                this.selected           = []
                this.searchText         = ''
                this.activeOptionKey    = ''
                this.emitInput()
            },
            preventKeypress(e) {
                if (this.selected.length) {
                    e.preventDefault()
                }
            }
        },
        mounted() {
            this.init()
        },
        computed: {
            activeOption() {
                let option = this.filterOptions.find(
                    (option) => option[this.key] === this.activeOptionKey
                )
                if (!option)
                    return this.filterOptions.length ? this.filterOptions[0] : null
                return option
            },
            activeOptionIndex() {
                return this.filterOptions.findIndex(
                    (option) => option[this.key] === this.activeOptionKey
                )
            },
        },
        watch: {
            searchText: {
                deep: true,
                handler(data) {
                    if (data) {
                        let fuse = new Fuse(this.options, {
                            threshold: 0.5,
                            keys: Array.from(this.keys),
                        })

                        this.filterOptions = data && !this.selected.length ? fuse.search(data).map(r => r.item) : this.options

                    } else {

                        this.filterOptions = this.options
                    }
                }
            },
            filterOptions: {
                deep: true,
                handler(data) {
                    this.hasOptions = data.length ? true : false
                }
            },
            options: {
                deep: true,
                handler(data) {
                    this.filterOptions = data.length ? data : []
                }
            },
            $props: {
                handler(data) {
                    if (!data.disabled) {
                        if (data.value == null || data.value == '') {
                            this.deleteSeclectOption()
                        } else {
                            this.setSelectedValue(data.value)
                        }
                    }

                    this.busying = data.busy
                },
                deep: true,
                immediate: true,
            },
        }
    }
</script>
